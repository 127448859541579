import { makeAutoObservable, runInAction } from "mobx";
import { get, put } from "../utils/api";

const PRO_URL = `pros/`;
const SERVICE_URL = `services/`;
const ZONE_URL = `zones/`;
export class ServiceProStore {
  servicePros = [];
  services = [];
  serviceZones = [];
  proServices = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadPros() {
    try {
      const res = await get(`${PRO_URL}`);
      runInAction(() => (this.servicePros = res));
      console.log(res);
    } catch (err) {
      // noop
    }
  }

  async loadServices() {
    try {
      const res = await get(`${SERVICE_URL}`);
      runInAction(() => (this.services = res));
      console.log(res);
    } catch (err) {
      // noop
    }
  }

  async updateService(data, pro) {
    const body = { id: pro._id };
    try {
      const res = await put(`${SERVICE_URL}${data?.sku}/${PRO_URL}`, body);
    } catch (err) {
      // noop
    }
  }

  async getServices(user) {
    try {
      this.rootStore.uiStateStore.setFetchingProServices(true);
      const res = await Promise.all(
        user?.services?.map(async (item) => {
          const service = await get(`${SERVICE_URL}${item.sku}`);
          return service;
        })
      );
      user.setFullServices(res);
      console.log(res);
      this.rootStore.uiStateStore.setFetchingProServices(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingProServices(false);
    }
  }

  async loadServiceZones() {
    try {
      const res = await get(ZONE_URL);
      runInAction(() => (this.serviceZones = res));
      console.log(res);
    } catch (err) {}
  }
}
