import React, { useEffect } from "react";
import "./styles.css";

const SelectField = ({
  name,
  label,
  value = "",
  options,
  selectClasses,
  setField,
  disabled = false,
  onMouseDown = undefined
}) => {
  useEffect(() => {
    if (setField && !value && options.length > 0 && !options[0]?.placeholder) {
      setField(options[0].value, true);
    }
  }, [value, setField, options]);

  return (
    <div className="dropdown-container">
      {label && (
        <label htmlFor={name} className="field-label">
          {label}
        </label>
      )}
      {/* <div className="dropdown-wrapper w-dropdown"> */}
      <select
        name={name}
        id={name}
        className={selectClasses || "dropdown-toggle-7 header w-dropdown-toggle"}
        onChange={(e) => setField(e.target.value)}
        disabled={disabled}
        style={disabled ? { cursor: "not-allowed" } : {}}
        value={value}
        onMouseDown={onMouseDown}
      >
        {options.map((op, idx) => {
          return (
            <option key={idx} value={op.value} disabled={op.placeholder}>
              {op.text}
            </option>
          );
        })}
      </select>
      {/* </div> */}
    </div>
  );
};

export default SelectField;
