import { makeAutoObservable } from "mobx";
import { OrderMetadata } from "./metadata";
import { stringToFloat } from "../../../utils/number.utils";
export class LineItem {
  id = "";
  meta_data = [];
  name = "";
  parent_name = "";
  price = 0;
  product_id = 0;
  quantity = 0;
  sku = "";
  subtotal = "";
  subtotal_tax = "";
  tax_class = "";
  taxes = [];
  total = "";
  total_tax = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
    this.meta_data.map((data) => new OrderMetadata(data));
    this.price = stringToFloat(this.price);
    this.subtotal = stringToFloat(this.subtotal);
    this.subtotal_tax = stringToFloat(this.subtotal_tax);
    this.total = stringToFloat(this.total);
    this.total_tax = stringToFloat(this.total_tax);
  }
}
