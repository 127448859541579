import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { useStores } from "../../store";
import ToastContent from "../toast-content/ToastContent";

const Notifier = () => {
  const { notificationStore } = useStores();

  const storeDisplayed = (uuid) => {
    notificationStore.addDisplayed(uuid);
  };

  const alerts = notificationStore.alertNotifications;

  useMemo(() => {
    alerts.forEach((n) => {
      if (notificationStore.displayed.find((d) => d === n.uuid) !== undefined) return;
      toast(<ToastContent title={n.title} desc={n.description} timestamp={n.timestamp} />, {
        type: n.type,
        style: { borderRadius: "12px" },
      });
      storeDisplayed(n.uuid);
    });
  }, [alerts]);

  return null;
};

export default observer(Notifier);
