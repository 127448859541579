import { makeAutoObservable, toJS } from "mobx";
import { getLastItemInUrl } from "../../utils/string.utils";
import { Product } from "./product";

const PERMITTED_OVERLAY_KEYS = ["displayName", "popular", "featured", "covers"];

export class Collection {
  products = [];
  covers = [];
  enabled = true;
  name = "";
  stores = [];
  notes = "";
  _id = "";
  edits = 0;
  seoTitle = "";
  seoDescription = "";
  seoSlug = "";
  popular = [];
  fullPopular = [];
  featured = null;
  fullFeatured = null;
  overlays = [];
  currentFeatured = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, toJS(obj));
    this.setCover(this.cover); // FIXME
    this.resetEdits();
  }

  getOverlay(store) {
    if (store === "0" || store === 0) return this;

    let overlay = this.overlays.find(o => o.store === store);
    if (!overlay) {
      this.overlays.push({ store, data: {} });
      overlay = this.overlays.find(o => o.store === store);
    }

    if (!overlay.data) {
      overlay.data = {};
    }

    return overlay.data;
  }

  edited() {
    this.edits++;
  }

  resetEdits() {
    this.edits = 0;
  }

  setName(name) {
    this.name = name;
    this.edited();
  }

  setNotes(notes) {
    this.notes = notes;
    this.edited();
  }

  // TODO: Fix setCover and visibility
  setCover(item) {
    // [store]: cover
    if (!item) return;
    const coverMap = {};
    this.covers.forEach(c => coverMap[c.store] = c);
    coverMap[item.store ?? "0"] = {
      name: item?.name,
      url: item?.url || item,
      store: item?.store || "0",
      visible: item?.visible
    };
    this.covers = Object.values(coverMap);
    console.log("Updated covers", this.covers);
    this.edited();
  }

  removeCoverByUrl(url) {
    console.log(this);
    this.covers.splice(
      this.covers.findIndex((img) => img.url === url),
      1
    );
    this.edited();
  }

  // addProduct(id) {
  //   const items = this.products;
  //   if (!items.find((e) => e === id)) items.push(id);
  //   else return;
  //   this.products = items;
  //   console.log(this.products);
  //   this.edited();
  // }

  // FIXME: just set the products from the ids before putting / posting
  setProductsFromInfo(data) {
    const prods = data.map((item, idx) => item._id);
    this.products = prods;
    this.edited();
  }

  // removeProductById(id) {
  //   this.currentProducts.splice(
  //     this.products.findIndex((item) => item === id),
  //     1
  //   );
  //   this.edited();
  // }

  updateStoreVisibility(bool, store) {
    // const items = this.stores;
    if (bool) this.stores.push(store);
    else this.stores.splice(this.stores.indexOf(store), 1);
    this.edited();
  }

  setSeoTitle(title) {
    this.seoTitle = title;
    this.edited();
  }

  setSeoDescription(desc) {
    this.seoDescription = desc;
    this.edited();
  }

  setSeoSlug(slug) {
    this.seoSlug = slug;
    this.edited();
  }

  setEnabled(val) {
    this.enabled = val;
    this.edited();
  }

  setFullPopular(res, initial = false) {
    this.fullPopular = res;
    if (!initial) this.edited();
  }

  addFullPopular(item) {
    if (!item?.name) return;
    if (!this.fullPopular.find((e) => e.name === item.name)) this.fullPopular.push(item);
    else return;
    this.edited();
  }

  removeFullPopular(name) {
    this.fullPopular.splice(
      this.fullPopular.findIndex((item) => item.name === name),
      1
    );
    this.edited();
  }

  setPopularFromInfo() {
    this.popular = this.fullPopular.map((item) => item._id);
    this.edited();
  }

  getFeatured(store) {
    const doc = this.getOverlay(store ?? "0");
    return doc.featured ?? this.featured; // fallback to full object
  }

  hasOverlaidFeatured(store) {
    const doc = this.getOverlay(store);
    return doc?.featured ? true : false;
  }

  setFeatured(itemId, store) {
    const doc = this.getOverlay(store ?? "0");
    doc.featured = itemId;
    this.currentFeatured = itemId;
    // this.edited();
  }

  setFullFeatured(item, initial = false, store = "0") {
    if (!item) return;
    const doc = this.getOverlay(store);
    doc.fullFeatured = new Product(item);
    this.setFeatured(item?._id, store);
    if (!initial) this.edited();
  }

  setFeaturedFromInfo() {
    this.featured = this.fullFeatured?._id || null;
    this.edited();
  }
}
