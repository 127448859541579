import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../store";
import Table from "../../../components/table/Table";
import AddServiceProPopup from "../../../components/add-service-pro-popup/AddServiceProPopup";
import ContentModule from "../../../components/content-module/ContentModule";
import { UsersPurple, UsersRed } from "../../../assets/images";
import DeleteCell from "../../../components/table/DeleteCell";

const ServicePros = ({ product }) => {
  const { productStore, uiStateStore } = useStores();
  const [showSelect, setShowSelect] = useState(false);

  const columns = [
    // {
    //   Header: "First Name",
    //   accessor: "partner_fname",
    // },
    // {
    //   Header: "Last Name",
    //   accessor: "partner_lname",
    // },
    {
      Header: "Name",
      accessor: (row) =>
        row.name ||
        `${row?.partner_fname || ""} ${row.partner_lname || ""}`.trim() ||
        `${row.editor_fname} ${row.editor_lname}`,
    },
    {
      Header: "Partner ID",
      accessor: (data) => data.partner_id || data.editor_id,
    },
    {
      Header: "Email",
      accessor: (data) => data.email || data.partner_email || data.editor_email,
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (e) => (
        <DeleteCell
          action={() => null}
          disabled={true}
          tooltip="Please contact an administrator to remove this pro"
        />
      ),
    },
  ];

  useEffect(() => {
    productStore.getServicePros();
  }, [product]);

  return (
    <>
      <ContentModule
        image={product.servicePros.length > 0 ? UsersPurple : UsersRed}
        caption={product.name}
        value={!uiStateStore.fetchingProductPros ? product.servicePros.length : "Loading..."}
        error={!uiStateStore.fetchingProductPros && product.servicePros.length === 0}
      />
      <AddServiceProPopup
        show={showSelect}
        setShow={(s) => setShowSelect(s)}
        rowClick={(cells) => {
          cells[0].row.toggleRowSelected();
        }}
        hasCheckbox
        buttons={[
          {
            text: "Add",
            onClick: (rows) => {
              rows.map((row, idx) => {
                if (row.isSelected) product.addServicePro(row.original);
                return null;
              });
              setShowSelect(false);
            },
            style: { minWidth: "100px", marginLeft: "0px" },
          },
        ]}
      />
      <Table
        tableName={product?.name}
        DATA={product?.servicePros}
        COLUMNS={columns}
        createText={"Add Pro"}
        createButtonClick={() => setShowSelect(true)}
        rowResults={product?.servicePros?.length || 1}
      />
    </>
  );
};

export default observer(ServicePros);
