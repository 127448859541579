import React, { useState } from "react";
import Table from "../../../components/table/Table";
import DeleteCell from "../../../components/table/DeleteCell";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import AddProductPopup from "../../../components/add-product-popup/AddProductPopup";
import { observer } from "mobx-react-lite";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../../utils/number.utils";
import { get } from "../../../utils/api";
import { PRODUCT_BASE_URL } from "../../../store/productStore";
import { useStores } from "../../../store";

const Products = ({ product }) => {
  const { uiStateStore, routeStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [showSelect, setShowSelect] = useState(false);

  const columns = [
    {
      Header: "",
      accessor: "drag",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (data) => {
        return (<span 
          className="cell-link"
          onClick={() => routeStore.newTab(`/products/${encodeURIComponent(data.row.values.name)}`)}
        >
          <strong className="in-text-link">{data.value}</strong>
        </span>);
      }
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => <StatusText color={determineColor(e.value)} text={e.value} />,
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (e) => (
        <DeleteCell action={() => product.removeProductByName(e.cell.row.values.name)} />
      ),
    },
  ];
  return (
    <>
      <AddProductPopup
        show={showSelect}
        title={`Add ${product.type === "PackageSet" ? "Packages" : "Products"}`}
        setShow={(s) => setShowSelect(s)}
        rowClick={(cells) => {
          cells[0].row.toggleRowSelected();
        }}
        search={`search[type]=^${
          product.type === "Package" ? `(?!AddonService|Package|PackageSet).*` : `Package`
        }$&limit=10`}
        hasCheckbox
        buttons={[
          {
            text: "Add",
            onClick: async (_, __, rowIds) => {
              // HACK: React Table v7 does not sync selectedFlatRows with Row Ids
              // FIXME: Should probably implement custom select instead
              // Apparently fixed in v8, which is not ready for production yet
              Object.keys(rowIds)?.map(async (id, idx) => {
                const res = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
                product.addProduct(res.products[0]);
                return null;
              });
              setShowSelect(false);
            },
            style: { minWidth: "100px", marginLeft: "0px" },
          },
        ]}
      />
      <Table
        tableName={"Products"}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        hasSearch={false}
        createText={"Add"}
        createButtonClick={() => setShowSelect(true)}
        COLUMNS={columns}
        rowResults={results}
        DATA={product?.products}
        draggable
        setData={(d) => product.setProducts(d)}
        noBottom={results >= product.products.length}
      />
    </>
  );
};

export default observer(Products);
