export const addOnTableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Link Sku",
    accessor: "link.sku",
    Cell: (e) => e.value || <strong>PLEASE SET!</strong>,
  },
  {
    Header: "Progresses To",
    accessor: "progressTo",
    Cell: (e) => e.value || "None",
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: (e) => e.value || "NOT SET",
  },
];
