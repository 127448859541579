import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Table from "../../../components/table/Table";
import { useStores } from "../../../store";

const Booking = () => {
  const { serviceProStore } = useStores();
  useEffect(() => {
    serviceProStore.loadServiceZones();
  }, []);

  const columns = [
    {
      Header: "ID",
      accessor: "city_id",
    },
    {
      Header: "City",
      accessor: "city_name",
    },
  ];

  return (
    <>
      <Table
        tableName={"Service Zones"}
        DATA={serviceProStore.serviceZones}
        COLUMNS={columns}
        rowResults={25}
      />
    </>
  );
};

export default observer(Booking);
