import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

export class Notification {
  uuid = uuidv4();
  title = "";
  timestamp = new Date();
  description = "";
  displayAlert;
  type = "info";
  read = false;
  stackTrace;

  constructor(obj) {
    makeAutoObservable(this);
    Object.assign(this, obj);
  }
}
