import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useStores } from "../../store";
import { PRODUCT_BASE_URL } from "../../store/productStore";
import { get } from "../../utils/api";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import AddProductPopup from "../add-product-popup/AddProductPopup";
import DeleteCell from "../table/DeleteCell";
import Table from "../table/Table";

const InspiredTrendsTable = ({ product }) => {
  const { productStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [showSelect, setShowSelect] = useState(false);

  const columns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (cell) => (
        <DeleteCell
          action={() => productStore.currentProduct.removeOtherProduct(cell.row.original)}
        />
      ),
    },
  ];

  return (
    <>
      <AddProductPopup
        show={showSelect}
        title={`Add Products`}
        setShow={(s) => setShowSelect(s)}
        rowClick={(cells) => {
          cells[0].row.toggleRowSelected();
        }}
        // search={`search[type]=^(${productStore.currentProduct.type}|Package|PackageSet)$&limit=10`}
        hasCheckbox
        buttons={[
          {
            text: "Add",
            onClick: async (_, __, rowIds) => {
              // HACK: React Table v7 does not sync selectedFlatRows with Row Ids
              // FIXME: Should probably implement custom select instead
              // Apparently fixed in v8, which is not ready for production yet
              Object.keys(rowIds)?.map(async (id, idx) => {
                const res = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
                productStore.currentProduct.addOtherProduct(res.products[0]);
                return null;
              });
              setShowSelect(false);
            },
            style: { minWidth: "100px", marginLeft: "0px" },
          },
        ]}
      />
      <Table
        tableName={"Inspired by Your Shopping Trends"}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        hasSearch={false}
        createText={"Add"}
        createButtonClick={() => setShowSelect(true)}
        COLUMNS={columns}
        rowResults={results}
        DATA={productStore.currentProduct.otherProducts}
        // draggable
        setData={(d) => productStore.currentProduct.setOtherProducts(d)}
        noBottom={results >= productStore.currentProduct.otherProducts.length}
      />
    </>
  );
};

export default observer(InspiredTrendsTable);
