import React from "react";
import AlertModule from "../../components/alert-module/AlertModule";

const LoginAlert = ({ show, setShow }) => {
  return show ? (
    <div style={{ width: "25vw", maxWidth: "350px", marginTop: "15px" }}>
      <AlertModule
        message={`Could not find a user with your credentials!`}
        open={show}
        setOpen={(o) => setShow(o)}
      />
    </div>
  ) : null;
};

export default LoginAlert;
