import {
  ChevronDown,
  OrdersList,
  ProductType,
  AttributeTag,
  PuzzlePiece,
  OpenPackage,
  UserFilled,
  DiscountTag,
  DatabaseFilled,
  SettingsCogFilled,
  LogoutIcon,
  PackageFilled,
  AnalyticsIcon,
  ShoppingCartFilled,
  JobTie,
  Aperture,
  Tool,
  Message
} from "../../assets/images";

import { rootStore } from "../../store";

const defaultClasses = "nav-link w-inline-block nav-link-text";
const activeClasses = "nav-link w-inline-block w--current";

const dropClasses = "nav-link-dropdown w-dropdown-toggle nav-link-text";
const dropItemClasses = "nav-link-dropdown-item w-inline-block nav-link-text";
const dItemActiveClasses = "nav-link-dropdown-item w-inline-block nav-link-text w--current";

const ADMIN_SIDEBAR = [
   {
      title: "Users",
      path: "/users",
      icon: UserFilled,
      cName: defaultClasses,
      acName: activeClasses,
    },
];

const STORE_SIDEBAR = [
  {
    title: "Orders",
    path: "/orders",
    hasDivider: true,
    icon: OrdersList,
    cName: defaultClasses,
    acName: activeClasses,
  },
  {
    title: "Products",
    path: "/products",
    icon: PackageFilled,
    dropdownIcon: ChevronDown,
    cName: dropClasses,
    acName: activeClasses,
    subNav: [
      {
        title: "All Products",
        path: "/products",
        icon: PackageFilled,
        cName: dropItemClasses,
        acName: dItemActiveClasses,
      },
      {
        title: "Product Type",
        path: "/product-types",
        icon: ProductType,
        cName: dropItemClasses,
        acName: dItemActiveClasses,
      },
      // {
      //   title: "Attributes",
      //   path: "/product-attributes",
      //   icon: AttributeTag,
      //   cName: dropItemClasses,
      //   acName: dItemActiveClasses,
      // },
      {
        title: "Add-Ons",
        path: "/product-add-ons",
        icon: PuzzlePiece,
        cName: dropItemClasses,
        acName: dItemActiveClasses,
      },
      {
        title: "Property Webpages",
        path: "/property-webpages",
        icon: OrdersList,
        cName: dropItemClasses,
        acName: dItemActiveClasses,
      },
    ],
  },
  {
    title: "Collections",
    path: "/collections",
    icon: OpenPackage,
    cName: defaultClasses,
    acName: activeClasses,
  },
  {
    title: "Discounts",
    path: "/discounts",
    icon: DiscountTag,
    cName: defaultClasses,
    acName: activeClasses,
  },
  {
    title: "Customers",
    path: "/customers",
    icon: UserFilled,
    dropdownIcon: ChevronDown,
    cName: dropClasses,
    acName: activeClasses,
    subNav: [
      {
        title: "All Customers",
        path: "/customers",
        hasDivider: true,
        icon: UserFilled,
        cName: dropItemClasses,
        acName: dItemActiveClasses,
      },
      {
        title: "Agent Database",
        path: "/agent-database",
        hasDivider: true,
        icon: DatabaseFilled,
        cName: dropItemClasses,
        acName: dItemActiveClasses,
      },
    ],
  },
  {
    title: "Analytics",
    path: "/analytics",
    icon: AnalyticsIcon,
    cName: defaultClasses,
    acName: activeClasses,
  },
];


const BOOKING_SIDEBAR = [
  {
    title: "Jobs",
    path: "/jobs",
    icon: JobTie,
    cName: defaultClasses,
    acName: activeClasses,
  },
  {
    title: "Resources",
    path: "/resources",
    icon: Aperture,
    cName: defaultClasses,
    acName: activeClasses,
  },
];

export function getSidebarData() {
  // TODO: FIXME: Scraper doesn't show up unless this is here
  console.log(rootStore.accountStore.user.applications);
  const DEFAULT_SIDEBAR = [
    {
      title: "Orders",
      path: "/orders",
      hasDivider: true,
      icon: OrdersList,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Quotes",
      path: "/quotes",
      icon: Message,
      cName: defaultClasses,
      acName: activeClasses 
    },
    {
      title: "Products",
      path: "/products",
      icon: PackageFilled,
      dropdownIcon: ChevronDown,
      cName: dropClasses,
      acName: activeClasses,
      subNav: [
        {
          title: "All Products",
          path: "/products",
          icon: PackageFilled,
          cName: dropItemClasses,
          acName: dItemActiveClasses,
        },
        {
          title: "Product Type",
          path: "/product-types",
          icon: ProductType,
          cName: dropItemClasses,
          acName: dItemActiveClasses,
        },
        // {
        //   title: "Attributes",
        //   path: "/product-attributes",
        //   icon: AttributeTag,
        //   cName: dropItemClasses,
        //   acName: dItemActiveClasses,
        // },
        {
          title: "Add-Ons",
          path: "/product-add-ons",
          icon: PuzzlePiece,
          cName: dropItemClasses,
          acName: dItemActiveClasses,
        },
        {
          title: "Property Webpages",
          path: "/property-webpages",
          icon: OrdersList,
          cName: dropItemClasses,
          acName: dItemActiveClasses,
        },
      ],
    },
    {
      title: "Collections",
      path: "/collections",
      icon: OpenPackage,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Discounts",
      path: "/discounts",
      icon: DiscountTag,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Customers",
      path: "/customers",
      icon: UserFilled,
      dropdownIcon: ChevronDown,
      cName: dropClasses,
      acName: activeClasses,
      subNav: [
        {
          title: "All Customers",
          path: "/customers",
          hasDivider: true,
          icon: UserFilled,
          cName: dropItemClasses,
          acName: dItemActiveClasses,
        },
        {
          title: "Agent Database",
          path: "/agent-database",
          hasDivider: true,
          icon: DatabaseFilled,
          cName: dropItemClasses,
          acName: dItemActiveClasses,
        },
      ],
    },
    {
      title: "Analytics",
      path: "/analytics",
      icon: AnalyticsIcon,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Users",
      path: "/users",
      icon: UserFilled,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Stores",
      path: "/stores",
      icon: ShoppingCartFilled,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Jobs",
      path: "/jobs",
      icon: JobTie,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Resources",
      path: "/resources",
      icon: Aperture,
      cName: defaultClasses,
      acName: activeClasses,
    },
    (rootStore.accountStore.user.applications.find(app => app.name === "scraper") && {
      title: "Scraper",
      path: "/scraper",
      icon: Tool,
      hasDivider: true,
      cName: defaultClasses,
      acName: activeClasses
    }),
    {
      title: "Account",
      path: `/account`,
      hasDivider: true,
      icon: UserFilled,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Admin Settings",
      path: "/admin-settings",
      icon: SettingsCogFilled,
      cName: defaultClasses,
      acName: activeClasses,
    },
    {
      title: "Logout",
      path: "#",
      icon: LogoutIcon,
      cName: "logout red " + defaultClasses,
      acName: "logout red " + defaultClasses,
      onClick: () => {
        rootStore.uiStateStore.setLogoutModal(true);
      },
    },
  ];
  return DEFAULT_SIDEBAR.filter(v => v !== undefined);
}
