import { action, makeObservable, observable } from "mobx";
import { get, post } from "../utils/api";
import { SORT_TYPE } from "../utils/select.utils";
import { Client } from "./domain/client";

const CLIENT_URL = "clients/";

export class ClientStore {
  clients = [];

  totalPages = 0;

  currentPage = 1;

  totalClients = 0;

  currentClient = new Client();

  clientSnapshot = new Client();

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      clients: observable,
      totalPages: observable,
      loadClients: action,
      setProps: action,
      currentPage: observable,
      setCurrentPage: action,
      totalClients: observable,
    });
  }

  async loadClients(rawSearch, sort = SORT_TYPE) {
    this.rootStore.uiStateStore.setFetchingClientContent(true);

    const baseUrl = `${CLIENT_URL}?page=${this.currentPage}${
      sort.sortBy ? `&sort[${sort.sortBy}]=${sort.sortDirection}` : ""
    }`;
    const fetchUrl = rawSearch ? `${baseUrl}&${rawSearch}` : baseUrl + `&limit=25`;

    get(fetchUrl)
      .then((res) => {
        if (!this.rootStore.uiStateStore.fetchingClientContent) return;
        console.log(res);
        this.setProps(res);
      })
      .catch((err) => {
        // no op
        this.rootStore.uiStateStore.setFetchingClientContent(false);
      });
  }

  setProps(res) {
    this.clients = res.clients;
    this.totalPages = res.totalPages;
    this.totalClients = res.totalClients;
    this.rootStore.uiStateStore.setFetchingClientContent(false);
  }

  async getClient(id) {
    this.rootStore.uiStateStore.setFetchingCurrentClient(true);
    get(`${CLIENT_URL}${id}`)
      .then((res) => {
        console.log(res);
        this.currentClient.fromObject(res);
        this.clientSnapshot.fromObject(res);
        this.rootStore.uiStateStore.setFetchingCurrentClient(false);
      })
      .catch(() => {
        this.rootStore.uiStateStore.setFetchingCurrentClient(false);
      });
  }

  async createClient(obj) {
    let error = "";
    obj.firstName = obj.firstName || "";
    obj.lastName = obj.lastName || "";
    try {
      return await post("/clients", obj);
    } catch (err) {
      error = err;
    }
    return error;
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }
}
