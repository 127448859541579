import { observer } from "mobx-react-lite";
import React from "react";
import Table from "../table/Table";

// TODO: More Columns
const OrderItemTable = ({ order }) => {
  const columns = [
    { Header: "Item", accessor: "name" },
    { Header: "ID", accessor: "product_id" },
    { Header: "Quantity", accessor: "quantity" },
    {
      Header: "Item Price",
      accessor: "price",
      Cell: (e) => `${order.raw.currency_symbol}${e.value.toFixed(2)}`,
    },
  ];

  return (
    <Table
      tableName={"Items"}
      DATA={order?.lineItems}
      COLUMNS={columns}
      rowResults={order?.lineItems?.length || 1}
      noBottom
    />
  );
};

export default observer(OrderItemTable);
