import { observer } from "mobx-react-lite";
import React from "react";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import Button from "../../../components/button/Button";
import TextBoxField from "../../../components/text-box-field/TextBoxField";

const textboxStyle = {
  maxHeight: "40px",
  minWidth: "100%",
  borderRadius: "5px",
};

const Security = () => {
  return (
    <>
      <BoxedContent>
        <div className="text-button-combo space-bottom">
          <div className="content-width-small">
            <h4>Two Factor Authentication</h4>
            <div className="form-caption">
              Two-Factor Authentication &#40;2FA&#41; can be used to help protect your account
            </div>
          </div>
          <Button text={"Enable"} classes={"button button-small"} disabled />
        </div>
        <div className="divider"></div>
        <div className="w-layout-grid _2-grid" style={{ paddingTop: "24px" }}>
          <div className="content-width-small">
            <div className="space-bottom">
              <h4>Change Password</h4>
              <div className="form-caption">
                You can always change your password for security reasons or reset your password in
                case you forgot it.
              </div>
            </div>
            <Button
              text={"Forgot Password?"}
              classes={`button button-small button-outline`}
              disabled
            />
          </div>
          <div className="form-wrap ">
            <h6>Current password</h6>
            <TextBoxField
              resize="none"
              style={textboxStyle}
              wrapperStyle={{ paddingRight: "0px" }}
              disabled
            />
            <h6>New password</h6>
            <TextBoxField
              resize="none"
              style={textboxStyle}
              wrapperStyle={{ paddingRight: "0px" }}
              disabled
            />
            <h6>Confirm new Password</h6>
            <TextBoxField
              resize="none"
              style={textboxStyle}
              wrapperStyle={{ paddingRight: "0px" }}
              disabled
            />
            <Button
              text={"Update Password"}
              classes={`button button-small`}
              style={{ width: "100%", marginRight: "15px" }}
              disabled
            />
          </div>
        </div>
      </BoxedContent>
    </>
  );
};

export default observer(Security);
