import { makeAutoObservable } from "mobx";
import { get, put, deleteAt, post } from "../utils/api";
import { createSkuFromName, removeKebab, titleCase } from "../utils/string.utils";
import { Addon } from "./domain/product-metadata/addon";
import { ProductDetail } from "./domain/product-metadata/detail";

const METADATA_URL = `/metadata/`;
const ADDON_URL = `addons`;
const DETAIL_URL = `details`;

export class MetadataStore {
  addons = [];
  details = [];

  currentAddOn = new Addon();
  addonSnapshot = new Addon();

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadAddons(search) {
    this.rootStore.uiStateStore.setFetchingAddonContent(true);
    try {
      const res = await get(`${METADATA_URL}${ADDON_URL}/${search || ""}`);
      console.log(res);
      this.setAddons(res);
      this.rootStore.uiStateStore.setFetchingAddonContent(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingAddonContent(false);
    }
  }

  async getAddOn(id) {
    this.resetAddons();
    this.rootStore.uiStateStore.setFetchingCurrentAddon(true);
    try {
      const res = await get(`${METADATA_URL}${ADDON_URL}/${id}`);
      console.log(`GET ADDON: `, res);
      if (res?.progressTo) {
        try {
          // HACK: Filter for the addon it progresses to
          const progressTo = this.addons.length
            ? this.addons
            : await get(`${METADATA_URL}${ADDON_URL}`);
          res.progressTo = progressTo.filter(
            (addon) => addon.name.toLowerCase() === removeKebab(res.progressTo)
          )[0];
        } catch (err) {
          console.log(err);
        }
      }
      this.currentAddOn.fromObject(res);
      this.addonSnapshot.fromObject(res);
      this.rootStore.uiStateStore.setFetchingCurrentAddon(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingCurrentAddon(false);
    }
  }

  updateAddonSnapshot() {
    this.addonSnapshot = new Addon(this.currentAddOn);
  }

  currentAddonToSnapshot() {
    this.currentAddOn = new Addon(this.addonSnapshot);
  }

  resetAddons() {
    this.currentAddOn = new Addon();
    this.addonSnapshot = new Addon();
  }

  async getProductDetails(product) {
    this.rootStore.uiStateStore.setFetchingProductDetails(true);
    console.log(`GET ${product.name || product.type} DETAILS`, product);
    try {
      const type = await get(`product-type/${product.type}`);

      // FIXME: using the action does not work

      product.fullDetails = await Promise.all(
        type?.details?.map(async (d, idx) => {
          const res = await get(`${METADATA_URL}${DETAIL_URL}/${d.detail}`);
          if (product.name) res.editable = false;
          return new ProductDetail(res);
        })
      );
      if (product.name) {
        const res = await Promise.all(
          product?.details?.map(async (d) => {
            if (!product.name) return;
            const res = await get(`${METADATA_URL}${DETAIL_URL}/${d.detail}`);
            return new ProductDetail(res);
          })
        );
        product.fullDetails = [...product.fullDetails, ...res];
      }

      product.fullCloserLooks = await Promise.all(
        type?.closerLook?.map(async (cl, idx) => {
          const res = await get(`${METADATA_URL}${DETAIL_URL}/${cl.detail}`);
          if (product.name) res.editable = false;
          return new ProductDetail(res);
        })
      );
      if (product.name) {
        const res = await Promise.all(
          product?.closerLook?.map(async (cl) => {
            if (!product.name) return;
            const res = await get(`${METADATA_URL}${DETAIL_URL}/${cl.detail}`);
            return new ProductDetail(res);
          })
        );
        product.fullCloserLooks = [...product.fullCloserLooks, ...res];
      }

      product.fullFaq = await Promise.all(
        type?.faq?.map(async (faq, idx) => {
          const res = await get(`${METADATA_URL}${DETAIL_URL}/${faq.detail}`);
          if (product.name) res.editable = false;
          return new ProductDetail(res);
        })
      );
      if (product.name) {
        const res = await Promise.all(
          product?.faq?.map(async (faq) => {
            if (!product.name) return;
            const res = await get(`${METADATA_URL}${DETAIL_URL}/${faq.detail}`);
            return new ProductDetail(res);
          })
        );
        product.fullFaq = [...product.fullFaq, ...res];
      }

      product.fullReviews = await Promise.all(
        type?.reviews?.map(async (rev, idx) => {
          const res = await get(`${METADATA_URL}${DETAIL_URL}/${rev.detail}`);
          if (product.name) res.editable = false;
          return new ProductDetail(res);
        })
      );
      if (product.name) {
        const res = await Promise.all(
          product?.reviews?.map(async (rev) => {
            if (!product.name) return;
            const res = await get(`${METADATA_URL}${DETAIL_URL}/${rev.detail}`);
            if (product.name) res.editable = false;
            return new ProductDetail(res);
          })
        );
        product.fullReviews = [...product.fullReviews, ...res];
      }
    } catch (err) {
      // no op
    } finally {
      this.rootStore.uiStateStore.setFetchingProductDetails(false);
    }
  }

  async getProductAddons(product) {
    console.log(`Attempting with Product`, product);
    this.rootStore.uiStateStore.setFetchingProductAddons(true);
    try {
      // FIXME: using the action does not work
      product.addons = await Promise.all(
        product?.addons?.map(async (data) => {
          if (!data.addon) return;
          const res = await get(`${METADATA_URL}${ADDON_URL}/${data.addon}`);
          console.log(res);
          return new Addon(res);
        })
      );
      this.rootStore.uiStateStore.setFetchingProductAddons(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingProductAddons(false);
    }
  }

  setAddons(res) {
    this.addons = res;
  }

  setDetails(res) {
    this.details = res;
  }

  async createAddon() {
    const { edits, _id, ...obj } = this.currentAddOn;
    obj.progressTo = createSkuFromName(obj.progressTo?.name) || "";
    obj.link = { type: this.currentAddOn.link.type, sku: this.currentAddOn.link.sku };
    let result = "";
    try {
      const res = await post(`${METADATA_URL}${ADDON_URL}`, obj);
      console.log(`RESPONSE`, res);
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Addon ${obj.name} has been created!`,
        "success"
      );
      this.loadAddons();
    } catch (err) {
      // console.error(err);
      result = err;
    }

    return result;
  }

  async createDetail(detail) {
    const res = await post(`${METADATA_URL}${DETAIL_URL}`, detail);
    return res;
  }

  async updateDetail(detail) {
    const { _id, editable, ...obj } = detail;
    const res = await put(`${METADATA_URL}${DETAIL_URL}/${_id}`, obj);
    return res;
  }

  async updateAllDetails(product) {
    const allDetails = [
      ...product.fullDetails,
      ...product.fullReviews,
      ...product.fullCloserLooks,
      ...product.fullFaq,
    ];
    await Promise.all(
      allDetails.map(async (detail) => {
        await this.updateDetail(detail);
      })
    );
    product.setAllDetails();
  }

  async updateAddon() {
    const { edits, ...obj } = this.currentAddOn;
    obj.progressTo = createSkuFromName(obj.progressTo?.name) || "";
    // obj.link = { type: this.currentAddOn.link.type, sku: this.currentAddOn.link.sku };
    let result = "";
    try {
      await put(`${METADATA_URL}${ADDON_URL}/${obj._id}`, obj);
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Updated add-on ${titleCase(this.addonSnapshot.name)}`,
        "success"
      );
      this.updateAddonSnapshot();
    } catch (err) {
      result = err;
    }

    return result;
  }

  async deleteAddon() {
    deleteAt(`${METADATA_URL}${ADDON_URL}/${this.currentAddOn._id}`)
      .then(() => {
        this.rootStore.notificationStore.createNotification(
          "SUCCESS",
          `Add-on ${this.currentAddOn.name} has been deleted`,
          "success"
        );
      })
      .catch(() => {
        //
      });
  }
}
