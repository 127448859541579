import { observer } from "mobx-react-lite";
import React from "react";
import { TempAvatar } from "../../../assets/images";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import Button from "../../../components/button/Button";
import SelectField from "../../../components/select-field/SelectField";
import TextBoxField from "../../../components/text-box-field/TextBoxField";
import { useStores } from "../../../store";
import { USER_STATUS_FIELDS } from "../../../utils/select.utils";

const General = ({ isCurrentAccount = false }) => {
  const { userStore } = useStores();

  return (
    <>
      <BoxedContent>
        <div className="account-2">
          <div className="user-details">
            <img loading="lazy" src={userStore.currentUser.avatarUrl} alt="" className="user-avatar" />
            <div>
              <h5 className="no-space-bottom" style={{ fontWeight: 400 }}>
                {userStore.currentUser.name}
              </h5>
              <div className="small-text text-grey-3">Profile Picture Size: {`400px x 400px`}</div>
            </div>
          </div>
          <Button text={"Upload new avatar"} classes={`button-17 button-small`} disabled />
        </div>
        <div
          className="account-2"
          style={{ paddingTop: "40px", paddingBottom: "40px", marginBottom: "0px" }}
        >
          <div className="div-block-38 no-padding" style={{ display: "flex" }}>
            <h5
              className="heading-62"
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                paddingTop: "10px",
              }}
            >
              User Status
            </h5>
            <SelectField name={"user-status"} options={USER_STATUS_FIELDS} disabled />
          </div>
        </div>
        <div style={{ paddingTop: "40px" }}>
          <TextBoxField
            title={"Name"}
            field={userStore.currentUser.name}
            resize="none"
            style={{ maxHeight: "40px", minWidth: "50%", borderRadius: "5px" }}
            disabled
          />
          <div className="w-layout-grid form-grid">
            <TextBoxField
              title={"Email Address"}
              field={userStore.currentUser.email}
              resize="none"
              style={{ maxHeight: "40px", minWidth: "50%", borderRadius: "5px" }}
              disabled
            />

            <TextBoxField
              title={"Phone"}
              field={"N/A"}
              resize="none"
              style={{ maxHeight: "40px", minWidth: "50%", borderRadius: "5px" }}
              disabled
            />
          </div>
        </div>
      </BoxedContent>
      <div className="account-delete-2">
        <div className="content-width-small space-bottom-small">
          <h6>Delete your Account</h6>
          <div className="form-caption-2">
            Before deleting your account, please note that if you delete your account, it cannot
            recover it.
          </div>
        </div>
        <Button style={{ "background-color": "red" }} text={"Delete"} classes={`button-17 button-small bg-primary-rose`} disabled />
      </div>
    </>
  );
};

export default observer(General);
