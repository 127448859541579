import { observer } from "mobx-react-lite";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useStores } from "../../store";
import SelectField from "../select-field/SelectField";

const AddServiceZonePopup = ({ show, setShow }) => {
  const { userStore, serviceProStore, notificationStore } = useStores();
  const [zone, setZone] = useState(undefined);
  const [city, setCity] = useState("");
  const [exists, setExists] = useState(false);

  const handleDiscard = useCallback(() => {
    setZone(undefined);
    setCity("");
    setShow(false);
  }, [setShow]);

  const handleCreate = useCallback(() => {
    const valid = zone >= 1 && zone <= 3 && city;
    if (valid) {
      if (userStore.currentUser.addZone({ zone_number: Number.parseInt(zone), city_name: city }))
        handleDiscard();
      else setExists(true);
    }
  }, [userStore.currentUser, city, zone, handleDiscard]);

  const cities = useMemo(() => {
    const items = serviceProStore.serviceZones.map((z) => {
      return { text: z.city_name, value: z.city_name };
    });
    items.unshift({ text: "Select City", value: "", placeholder: true });

    return items;
  }, [serviceProStore.serviceZones]);

  useEffect(() => {
    serviceProStore.loadServiceZones();
  }, [serviceProStore]);

  useEffect(() => {
    setExists(false);
  }, [zone, city]);

  if (!show) return null;
  return (
    <div className="modal-wrapper">
      <div className="custom-modal">
        <div className="modal-title">
          <h1 className="title">Add Service Zone</h1>
        </div>
        <div className="form-block">
          <div className="field-item">
            <label htmlFor="service-zone">Zone Number</label>
            <input
              type="number"
              className="field-input"
              name="service-zone"
              placeholder={`Enter zone number (between 1 and 3)...`}
              defaultValue={zone}
              min={1}
              max={3}
              onChange={(e) => setZone(e.target.value)}
            />
            {(zone > 3 || zone < 1) && (
              <h5 className="red small-text">Zone number must be between 1 and 3!</h5>
            )}
            {!zone && <h5 className="red small-text">Zone number must be set!</h5>}
          </div>
          <div className="field-item">
            <SelectField
              name="service-city"
              label="Service City"
              options={cities}
              selectClasses="field-input"
              setField={setCity}
              value={city}
            />
            {!city && <h5 className="red small-text">A city must be selected!</h5>}
            {exists && <h5 className="orange small-text">Service Zone pairing already exists!</h5>}
          </div>
          <div className="button-wrapper padding">
            <div className="form-button w-button button-2" onClick={() => handleDiscard()}>
              Discard
            </div>
            <div className="form-button w-button button-2" onClick={() => handleCreate()}>
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddServiceZonePopup);
