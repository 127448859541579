import { useState, useCallback } from "react";

/**
 * Store an item to local storage.
 *
 * @param {string} key The key for identifying the item
 * @param {*} initialValue The initial value of the item
 */
const useLocalStorage = (key = "", initialValue = "") => {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      //Parse stored json, or if none return initial value
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      // If error, also return initial value
      console.log(err);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      try {
        // Save state
        setStoredValue(value);

        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (err) {
        // Handle error here, for now just log
        console.log(err);
      }
    },
    [key]
  );

  return [storedValue, setValue];
};

export default useLocalStorage;
