import React from "react";
import { CSSTransition } from "react-transition-group";
import { CloseImg } from "../../assets/images";
import "./styles.css";

const alertStyles = "alert-module-link w-inline-block link";
const AlertModule = ({ open, setOpen, message, style }) => {
  return (
    <CSSTransition in={open} timeout={400} classNames={"transition-alert"}>
      <div className={"alert-module"} style={style || {}}>
        <div>{message}</div>
        <div onClick={() => setOpen(!open)} className={alertStyles}>
          <img src={CloseImg} loading="lazy" alt="" />
        </div>
      </div>
    </CSSTransition>
  );
};

export default AlertModule;
