import { makeAutoObservable } from "mobx";

export class RouteStore {
  redirectedTo = undefined;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  redirect(path) {
    if (this.redirectedTo !== path) {
      this.redirectedTo = path;
    }
  }

  reset() {
    this.redirectedTo = "";
  }

  newTab(path) {
    // TODO: revisit
    window.open(path, "_blank");
  }
}
