import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "../../store";
import SelectField from "../select-field/SelectField";

const StoreSelector = () => {
  const { shopStore } = useStores();
  // FIXME: hackish workaround
  const [currentStore, setCurrentStore] = useState(shopStore.currentStore);

  // FIXME: Fix copy paste from product details
  useEffect(() => {
    if (!shopStore.hasFetched) shopStore.loadStores();
  }, []);

  const storeOptions = useMemo(() => {
    const stores = shopStore.stores.map((s, idx) => {
      return { text: s.name, value: s.id };
    });

    console.log("Stores", stores);
    return stores;
  }, [shopStore.stores]);

  return (
    <div>
      <SelectField
        name={"store-dropdown"}
        options={storeOptions}
        value={currentStore}
        setField={(e) => { shopStore.setCurrentStore(e); setCurrentStore(e); }}
      />
    </div>
  );
}

export default StoreSelector;