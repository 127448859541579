import { makeAutoObservable, toJS } from "mobx";
import equal from "fast-deep-equal/es6";
import { rootStore } from "..";
import { Attribute } from "./product-metadata/attribute";
import { Addon } from "./product-metadata/addon";
import { createSkuFromName, getLastItemInUrl } from "../../utils/string.utils";
import { META_DESC_LENGTH, SEO_TITLE_LENGTH } from "../../utils/number.utils";
export class Product {
  addons = [];
  attributes = [];
  closerLook = [];
  fullCloserLooks = [];
  //cover = [];
  covers = [];
  createdAt;
  details = [];
  fullDetails = [];
  faq = [];
  fullFaq = [];
  images = [];
  products = [];
  name = "";
  externalName = "";
  displayName = "";
  reviews = [];
  fullReviews = [];
  sinaIncomingIds = [];
  sku = "";
  linkedSku = "";
  status = "";
  bio = "";
  type = "";
  updatedAt;
  variationCount = 0; // number of variations
  edits = 0; // TODO: Autorun, computed or whatever paradigm later. For now just get it to work. (https://mobx.js.org/reactions.html#autorun)
  saveCounter = 0;
  detailsEdited = 0;
  notes = "";
  seoTitle = "";
  seoDescription = "";
  seoSlug = "";
  price = "";
  markup = "";
  _id = "";
  optionsHideable = false;
  linkedProduct = ""; // not used for now
  otherProducts = []; // for product type
  servicePros = [];
  hiddenDetails = {};
  requiresShipping = false;

  currentAttrName = "";
  // attrSnapshot = undefined;
  // currentAttr = {};
  newAttribute = new Attribute({ name: "" });

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  compare(obj) {
    return equal(this, obj);
  }

  resetEdits() {
    this.edits = 0;
  }

  edited() {
    this.edits++;
  }

  fromObject(obj) {
    const js = toJS(obj);
    this.resetEdits();
    this.stores = [];
    Object.assign(this, js);
    this.attributes = this.attributes.map((e) => new Attribute(e));
    this.addons = this.addons.map((e) => new Addon(e));
    this.resetNewAttr();
    if (!this.seoDescription) this.seoDescription = this.bio.trim().substring(0, META_DESC_LENGTH);
    if (!this.seoTitle) this.seoTitle = this.name.substring(0, SEO_TITLE_LENGTH);
    if (!this.seoSlug) this.seoSlug = createSkuFromName(this.name);
  }

  setName(name) {
    this.name = name;
    this.edits++;
  }

  setDisplayName(displayName) {
    this.displayName = displayName;
    this.edits++;
  }

  setExternalName(externalName) {
    this.externalName = externalName;
    this.edits++;
  }

  setAddOns(addons) {
    console.log("WHY ARENT YOU WORKING");
    this.addons = addons;
    this.edits++;
  }

  addAddon(res) {
    this.addons.push(new Addon(res));
    this.edited();
  }

  setAttributes(attributes) {
    this.attributes = attributes;
    this.edits++;
  }

  getAttributeVisibility(attribute) {
    return this.attributes[this.attributes.indexOf(attribute)].hidden;
  }

  setAttributeVisibility(attribute, value) {
    this.attributes = this.attributes.map((attr) => {
      if (attr === attribute) attr.hidden = value;
      return attr;
    });
    this.edits++;
  }

  updateAttribute(attribute) {
    this.attributes[this.attributes.findIndex((ele) => ele === attribute)] = attribute;
    this.edits++;
  }

  setStatus(status) {
    this.status = status;
    this.edits++;
  }

  setNotes(notes) {
    this.notes = notes;
    this.edits++;
  }

  setLinkedProduct(id) {
    if (id !== this.linkedProduct) {
      this.linkedProduct = id;
      this.edits++;
    }
  }

  addImage(url) {
    const temp = [...this.images];
    if (this.images.find((img) => img.url === url)) {
      rootStore.notificationStore.createNotification("WARN", "File already exists!", "warning");
      return;
    }
    temp.push({ url: url.url, store: url.store ?? "0", visible: url.visible });
    // console.log(`This is the temp array`);
    // console.log(temp);
    this.images = temp;
    this.edits++;
  }

  removeImageByUrl(url) {
    this.images.splice(
      this.images.findIndex((img) => img.url === url),
      1
    );
    this.edits++;
  }

  removeCoverByUrl(url) {
    this.covers.splice(
      this.covers.findIndex((img) => img.url === url),
      1
    );
    this.edits++;
  }

  // TODO: Cover Sets
  setCover(item) {
    if (!item) return;
    const coverMap = {};
    this.covers.forEach(c => coverMap[c.store] = c);
    coverMap[item.store ?? "0"] = {
      name: item?.name,
      url: item?.url || item,
      store: item?.store || "0",
      visible: item?.visible
    };
    this.covers = Object.values(coverMap);
    console.log("Updated covers", this.covers);
    this.edits++;
  }

  setImages(images) {
    this.images = images;
    this.edits++;
  }

  replaceImage(oldUrl, newItem) {
    if (!oldUrl) return;
    const index = this.images.findIndex((e) => e.url === oldUrl);
    this.images[index] = {
      url: newItem?.url || newItem,
      stores: newItem?.stores || [],
      visible: typeof newItem?.visible === "undefined" ? true : newItem.visible,
    };
    this.edits++;
  }

  setBio(bio) {
    this.bio = bio;
    this.edits++;
  }

  setSeoTitle(title) {
    this.seoTitle = title;
    this.edits++;
  }

  setSeoDescription(desc) {
    this.seoDescription = desc;
    this.edits++;
  }

  setSeoSlug(slug) {
    this.seoSlug = slug;
    this.edits++;
  }

  setMarkup(mk) {
    this.markup = mk;
    this.edits++;
  }

  setPrice(price) {
    this.price = price;
    this.edits++;
  }


  setRequiresShipping(bool) {
    this.requiresShipping = bool;
    this.edits++;
  }

  setProducts(items) {
    this.products = items;
    this.edits++;
  }

  addProduct(item) {
    const items = this.products;
    if (!items.find((e) => e.name === item.name)) items.push(item);
    else return;
    this.setProducts(items);
    this.edits++;
  }

  removeProductByName(name) {
    this.products.splice(
      this.products.findIndex((item) => item.name === name),
      1
    );
    this.edits++;
  }

  setOtherProducts(items) {
    this.otherProducts = items;
    this.edited();
  }

  addOtherProduct(product) {
    const items = this.otherProducts;
    if (!items.find((e) => e === product)) items.push(product);
    else return;
    this.setOtherProducts(items);
  }

  removeOtherProduct(product) {
    this.otherProducts.splice(
      this.otherProducts.findIndex((item) => item === product),
      1
    );
    this.edited();
  }

  removeBadProps() {
    const {
      _id,
      sku,
      type,
      linkedSku,
      //linkedProduct, // If uncommented, it will be removed as a bad prop
      // servicePros,
      fullCloserLooks,
      fullFaq,
      fullDetails,
      fullReviews,
      otherProducts,
      ...cleanProd
    } = this;

    // TODO: Change linkedSku to linkedProduct
    if (this.type !== "DesignService" || cleanProd.linkedProduct === "") {
      delete cleanProd.linkedProduct;
    }

    const otherProds = this.otherProducts.map((prod) => prod._id);
    cleanProd.otherProducts = otherProds;
    cleanProd.servicePros = this.servicePros.map((pro) => pro._id);
    return cleanProd;
  }

  setDetails(res) {
    this.details = res;
    this.edits++;
  }

  getDetailVisibility(id) {
    return this.hiddenDetails[id];
  }

  setDetailVisibility(detail, val) {
    this.hiddenDetails[detail] = val;
    this.edited();
  }

  setFullDetails(res, initial = false) {
    this.fullDetails = res;
    if (!initial) this.edited();
  }

  addFullDetail(res) {
    this.fullDetails.push(res);
    this.edited();
  }

  removeFullDetail(detail) {
    this.fullDetails.splice(
      this.fullDetails.findIndex((d) => d === detail),
      1
    );
    this.edited();
  }

  setFullCloserLooks(res, initial = false) {
    this.fullCloserLooks = res;
    if (!initial) this.edited();
  }
  addFullCloserLook(res) {
    this.fullCloserLooks.push(res);
    this.edited();
  }
  removeFullCloserLook(detail) {
    this.fullCloserLooks.splice(
      this.fullCloserLooks.findIndex((d) => d === detail),
      1
    );
    this.edited();
  }
  setFullReviews(res, initial = false) {
    this.fullReviews = res;
    if (!initial) this.edited();
  }
  addFullReview(res) {
    this.fullReviews.push(res);
    this.edited();
  }
  removeFullReview(detail) {
    const reviewIndex = this.fullReviews.findIndex((d) => d._id === detail._id);
    if (reviewIndex !== -1) {
      this.fullReviews.splice(
        reviewIndex,
        1
      );
      this.edited();
    }
    this.detailsEdited++;
  }

  setFullFaq(res, initial = false) {
    this.fullFaq = res;
    if (!initial) this.edited();
  }
  addFullFaq(res) {
    this.fullFaq.push(res);
    console.log(res);
    this.edited();
  }
  removeFullFaq(detail) {
    const faqIndex = this.fullFaq.findIndex((d) => d._id === detail._id);
    if (faqIndex !== -1) {
      this.fullFaq.splice(
        faqIndex,
        1
      );
      this.edited();
    }
    this.detailsEdited++;
  }

  setAllDetails() {
    const { currentStore } = rootStore.shopStore;
    //, store: rootStore.shopStore.currentStore ?? "0" 
    this.details = this.fullDetails
      .filter((data) => data.editable)
      .map((data) => {
        return { detail: data._id };
      });
    this.closerLook = this.fullCloserLooks
      .filter((data) => data.editable)
      .map((data) => {
        return { detail: data._id };
      });
    this.faq = this.fullFaq
      .filter((data) => data.editable || data.fresh)
      .map((data) => {
        const exists = this.faq.find(d => d.detail === data._id);
        console.log("FAQ exists", exists);
        data.fresh = false;
        if (!exists || (exists && exists.store === undefined)) {
          return { detail: data._id, store: currentStore };
        } else {
          return { detail: data._id, store: exists.store };
        }
      });
    console.log("FAQ is now", this.faq)
    this.reviews = this.fullReviews
      .filter((data) => data.editable || data.fresh)
      .map((data) => {
        const exists = this.reviews.find(d => d.detail === data._id);
        data.fresh = false;
        if (!exists || (exists && exists.store === undefined)) {
          return { detail: data._id, store: currentStore };
        } else {
          return { detail: data._id, store: exists.store };
        }
      });
  }
  setCurrentAttrName(name) {
    this.currentAttrName = name;
  }

  removeAttribute(attribute) {
    this.attributes.splice(
      this.attributes.findIndex((attr) => attr === attribute),
      1
    );
    this.edits++;
  }

  updateStoreVisibility(bool, store) {
    // const items = this.stores;
    if (bool) this.stores.push(store);
    else this.stores.splice(this.stores.indexOf(store), 1);
    this.edits++;
  }

  resetNewAttr() {
    this.newAttribute = new Attribute({ name: "" });
  }

  // TODO: List of _ids (look like UUID) rather than partner_id
  addServicePro(pro) {
    const items = this.servicePros;
    if (!items.find((e) => e.partner_id === pro.partner_id)) items.push(pro);
    else return;
    this.setServicePros(items);
    this.edited();
  }

  setServicePros(pros) {
    this.servicePros = pros;
  }

  get currentAttr() {
    const attr =
      this.attributes.find((ele) => ele.name === this.currentAttrName) || this.newAttribute;
    // if (!this.attrSnapshot) runInAction(() => (this.attrSnapshot = new Attribute(toJS(attr))));

    return attr;
  }

  get isNewAttribute() {
    return this.newAttribute.compare(this.currentAttr);
  }

  addAttribute(attr) {
    this.attributes.push(attr);
    this.edits++;
  }
}
