import React from "react";
import useDateFromNow from "../../hooks/useDateFromNow";
import "./styles.css";

const ToastContent = ({ title, desc, timestamp }) => {
  const { fromNowString } = useDateFromNow(timestamp);

  return (
    <div className="toast-content">
      <div className="toast-header">
        <strong className="toast-title">{title}</strong>
        <small className="toast-time-ago">
          <em>{fromNowString} ago</em>
        </small>
      </div>
      <span className="toast-message">{desc}</span>
    </div>
  );
};

export default ToastContent;
