import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeft1, NotFoundImg, NotFoundImg500, NotFoundImg800 } from "../../assets/images";

const NotFound = () => {
  return (
    <div className="utility-page-wrap">
      <div className="utility-page-content w-form">
        <img
          src={NotFoundImg}
          alt=""
          sizes="(max-width: 767px) 150px, 200px"
          srcSet={`${NotFoundImg500} 500w, ${NotFoundImg800}  800w, ${NotFoundImg} 2000w`}
          className="utility-page-icon"
        />
        <h2>Page Not Found</h2>
        <div>The page you are looking for doesn&#x27;t exist or has been moved.</div>
        <Link to="/" className="back-to-home text-primary-blue w-inline-block">
          <img src={ArrowLeft1} loading="lazy" alt="" className="back-icon" />
          <div>Back to Home</div>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
