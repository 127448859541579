import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStores } from "../../store";
import { SHOP_DETAILS } from "../../utils/shops.utils";
import CreateClient from "../create-client/CreateClient";
import Table from "../table/Table";
import { customerTableColumns } from "./customerTableColumns";
import CustomerTableDropdown from "./CustomerTableDropdown";

const CustomerTable = () => {
  const { clientStore, uiStateStore, orderStore, routeStore, shopStore } = useStores();
  const [ createOpen, setCreateOpen ] = useState(false);

  const rowResults = 25;

  const tabs = [
    { heading: "All Customers" },
    ...SHOP_DETAILS.filter(store => store.placeholder !== true).map(store => {
      return { heading: store.name, store: store.id }
    })
    // { heading: "Not Registered" },
    // { heading: "Registered" },
    // { heading: "Invited" },
    // { heading: "Archived" },
  ];

  function nextPage(pageNumber) {
    clientStore.setCurrentPage(pageNumber);
  }

  // FIXME: Temp solution, need to fetch data periodically
  useEffect(() => {
    orderStore.loadOrders();
  }, []);

  useEffect(() => {
    // notificationStore.createNotification("Test", "Notification", "warning", undefined);
    const filters = [];

    // Filter by store if necessary
    const selectedTab = tabs[uiStateStore.clientTableTab - 1];
    if (selectedTab && selectedTab.store !== undefined) {
      filters.push(`search[store]=^${selectedTab.store}$`);
    }

    // Then add user's filter
    if (uiStateStore.clientTableFilterText) {
      filters.push(`search[${uiStateStore.clientTableFilterBy}]=${uiStateStore.clientTableFilterText}`)
    }

    clientStore.loadClients(
      filters.length !== 0
        ? filters.join("&")
        : "",
      uiStateStore.clientTableSort
    );
  }, [
    uiStateStore.clientTableTab,
    clientStore.currentPage,
    uiStateStore.clientTableFilterBy,
    uiStateStore.clientTableFilterText,
    uiStateStore.clientTableSort,
  ]);

  return (
    <div className="dashboard-container-2">
      <CreateClient show={createOpen} setShow={setCreateOpen} />
      <Table
        tabNames={tabs}
        tableName={"Customers"}
        COLUMNS={customerTableColumns}
        DATA={clientStore.clients}
        pages={clientStore.totalPages}
        nextPage={nextPage}
        currentPage={clientStore.currentPage}
        rowResults={rowResults}
        setTabIndex={(e) => uiStateStore.setClientTableTab(e)}
        currentTab={uiStateStore.clientTableTab}
        loading={uiStateStore.fetchingClientContent}
        rowClick={(cells) => routeStore.redirect(`customers/${cells[0].value}`)}
        selectField={<CustomerTableDropdown />}
        filter={uiStateStore.clientTableFilterText}
        setFilter={(text) => uiStateStore.setClientTableFilterText(text)}
        sort={uiStateStore.clientTableSort}
        setSort={(sort) => uiStateStore.setClientTableSort(sort)}
        createText={"Create Customer"}
        createButtonClick={() => setCreateOpen(true)}
      />
    </div>
  );
};

export default observer(CustomerTable);
