import React, { useEffect } from "react";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import DetailedDescriptionTable from "../../../components/detailed-description-table/DetailedDescriptionTable";
import TextBoxField from "../../../components/text-box-field/TextBoxField";
import StoreVisibility from "../../../components/store-visibility/StoreVisibility";
import FaqTable from "../../../components/faq-table/FaqTable";
import SelectField from "../../../components/select-field/SelectField";
import CloserLookTable from "../../../components/closer-look-table/CloserLookTable";
import { PRODUCT_STATUSES } from "../../../utils/select.utils";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../store";
import { genericNameSchema, markupOrPriceSchema } from "../../../utils/validation.utils";
import { META_DESC_LENGTH, SEO_TITLE_LENGTH } from "../../../utils/number.utils";
import CheckboxField from "../../../components/checkbox-field/CheckboxField";

const DISALLOW_BASE_PRICE = ["Repackage", "Sina"];
const ALLOW_MASTER_MARKUP = DISALLOW_BASE_PRICE;

const Information = ({ product, isDuplicate }) => {
  const { productStore } = useStores();

  useEffect(() => {
    if (product.type === "Repackage") productStore.loadLinkedSkus();
    else if (product.type === "DesignService") productStore.loadPrintProducts();
  }, [product.type, productStore]);
  return (
    <>
      <BoxedContent title={"Internal Notes"}>
        <TextBoxField
          field={product.notes}
          setField={(e) => product.setNotes(e)}
          placeholder={"Enter product internal notes..."}
        />
      </BoxedContent>

      {/* Product Information */}
      <BoxedContent title={"Product Information"}>
        <div className="with-border">
          <h5 className="heading-62">Product Status</h5>
          <SelectField
            name={"product-status"}
            options={PRODUCT_STATUSES}
            setField={(e) => product.setStatus(e)}
            value={product.status}
          />
        </div>
        <StoreVisibility
          stores={product.stores}
          updateStores={(bool, store) => product.updateStoreVisibility(bool, store)}
        />
        <>
          <h5 className="heading-62">Internal Name</h5>
          <TextBoxField
            field={product.name}
            placeholder={"Enter internal product name..."}
            setField={(f) => product.setName(f)}
            validationSchema={genericNameSchema}
          />
        </>
        <div style={{ marginBottom: "20px" }}>
          <h5 className="heading-62">External Name</h5>
          <TextBoxField
            field={product.externalName}
            placeholder={"Enter display name (this is what is displayed on all active sites)"}
            setField={(f) => product.setExternalName(f)}
          />
        </div>
        {product.type === "Package" && (
          <div style={{ marginBottom: "20px" }}>
            <h5 className="heading-62">Display Name</h5>
            <TextBoxField
              field={product.displayName}
              placeholder={"Enter display name (this is what is shown on the packages page)"}
              setField={(f) => product.setDisplayName(f)}
            />
          </div>
        )}
        {product.type === "Repackage" && (
          <div style={{ marginBottom: "20px" }}>
            <h5 className="heading-62">Linked Supplier Product</h5>
            <SelectField
              value={product.linkedSku}
              name={"linked-product"}
              options={
                product.type === "Repackage"
                  ? productStore.linkedSkus
                  : [{ text: "N/A", value: "" }]
              }
              disabled={!isDuplicate}
            />
          </div>
        )}
        {product.type === "DesignService" && (
          <div style={{ marginBottom: "20px" }}>
            <h5 className="heading-62">Linked Product</h5>
            <SelectField
              name={"linked-product"}
              setField={(e) => product.setLinkedProduct(e)}
              options={
                product.type === "DesignService"
                  ? productStore.printProducts
                  : [{ text: "N/A", value: "" }]
              }
              value={product.linkedProduct}
            />
          </div>
        )}
        <>
          <h5 className="heading-62">Short Description</h5>
          <TextBoxField
            field={product.bio}
            placeholder={"Enter product description..."}
            setField={(b) => {
              // Set SEO Desc to bio if they are the same or seoDesc is empty
              if (product.seoDescription === product.bio || !product.seoDescription)
                product.setSeoDescription(b.substring(0, META_DESC_LENGTH));
              product.setBio(b);
            }}
          />
        </>
        {!DISALLOW_BASE_PRICE.find((t) => t === product.type) && (
          <div className="with-border">
            <h5 className="heading-62">{`Base Price ($)`}</h5>
            <TextBoxField
              field={product.price}
              placeholder={"Enter product Base Price..."}
              setField={(b) => product.setPrice(b)}
              style={{ width: "250px" }}
              validationSchema={markupOrPriceSchema}
            />
          </div>
        )}
        {ALLOW_MASTER_MARKUP.find((t) => t === product.type) && (
          <div className="with-border">
            <h5 className="heading-62">{`Master Markup (%)`}</h5>
            <TextBoxField
              field={product.markup}
              placeholder={"Enter product markup..."}
              setField={(b) => product.setMarkup(b)}
              style={{ width: "250px" }}
              resize={"none"}
              validationSchema={markupOrPriceSchema}
            />
          </div>
        )}
        {product.type === "Generic" && (
          <div>
            <h5 className="heading-62">Requires shipping?</h5>
            <CheckboxField
              name="requiresShipping"
              setField={(v) => product.setRequiresShipping(v)}
              label="Yes"
              labelOff="No"
              isChecked={product.requiresShipping}
            />
          </div>
        )}
      </BoxedContent>

      {/* Detailed Description Table */}
      <DetailedDescriptionTable product={product} />

        {/* Closer Look table */}
      <CloserLookTable product={product} />

      {/* FAQ Table */}
      <FaqTable product={product} />

      {/* Search Engine Listing */}
      <BoxedContent title={"Search Engine Listing"}>
        {/* <div className="div-block-38"> */}
        <div style={{ marginTop: "20px" }}>
          <h5 className="heading-62">Page Title</h5>
          <TextBoxField
            field={product.seoTitle}
            placeholder={"Enter page title..."}
            maxLength={SEO_TITLE_LENGTH}
            setField={(data) => product.setSeoTitle(data)}
          />
        </div>
        <>
          <h5 className="heading-62">Meta Description</h5>
          <TextBoxField
            field={product.seoDescription}
            placeholder={"Enter meta description..."}
            maxLength={META_DESC_LENGTH}
            setField={(data) => product.setSeoDescription(data)}
          />
        </>
        <>
          <h5 className="heading-62">URL Handle</h5>
          <TextBoxField
            field={product.seoSlug}
            placeholder={"Enter URL handle..."}
            setField={(data) => product.setSeoSlug(data)}
          />
        </>
        {/* </div> */}
      </BoxedContent>
    </>
  );
};

export default observer(Information);
