import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";

const JobTable = () => {
  const { jobStore, uiStateStore } = useStores();

  useEffect(() => {
    jobStore.loadJobs();
    // jobStore.getJob(306); // just test
  }, []);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Job",
      accessor: "service_name",
      Cell: (e) => e.value || "N/A",
    },
    {
      Header: "Booking Date",
      accessor: "booking_date",
    },
    {
      Header: "Due Date",
      accessor: "due_date",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Job Status",
      accessor: "status",
    },
  ];

  return (
    <div className="dashboard-container-2">
      <Table
        tableName={"Jobs"}
        DATA={jobStore.jobs}
        COLUMNS={columns}
        loading={uiStateStore.fetchingJobContent}
        rowResults={25}
        rowClick={(cells) => jobStore.getJob(cells[0].value)}
      />
    </div>
  );
};

export default observer(JobTable);
