import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Table from "../table/Table";
import { useStores } from "../../store";
import DeleteCell from "../table/DeleteCell";
import CheckboxField from "../checkbox-field/CheckboxField";
import AddDetailPopup from "../add-detail-popup/AddDetailPopup";
import TextBoxField from "../text-box-field/TextBoxField";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import ReactTooltip from "react-tooltip";
import { DETAIL_TOOLTIP } from "../../utils/string.utils";

const DetailedDescriptionTable = ({ product }) => {
  const { productStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [createOpen, setCreateOpen] = useState(false);

  const detDescTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    // {
    //   Header: "Template",
    //   accessor: "template",
    // },
    {
      Header: "Title",
      accessor: "title",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.title}
                setField={(val) => {
                  e.cell.row.original.setTitle(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description Title"}
                style={{ minWidth: "90px", maxWidth: "250px" }}
                resize={"horizontal"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.description}
                setField={(val) => {
                  e.cell.row.original.setDescription(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description"}
                style={{ minWidth: "250px", width: "600px", maxWidth: "800px" }}
                resize={"both"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Visibility",
      accessor: "visibility",
      Cell: (e) => (
        <div style={{ display: "inline-block" }}>
          <CheckboxField
            name="add"
            label={"Shown"}
            labelOff={"Hidden"}
            isChecked={!productStore.currentProduct.getDetailVisibility(e.cell.row.original._id)}
            setField={(val) => {
              productStore.currentProduct.setDetailVisibility(e.cell.row.original._id, !val);
              console.log(`Changing Detail to:`, val);
            }}
          />
        </div>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (cell) => (
        <DeleteCell
          action={() => productStore.currentProduct.removeFullDetail(cell.row.original)}
          disabled={!cell.row.original.editable}
          tooltip={!cell.row.original.editable ? DETAIL_TOOLTIP : ""}
        />
      ),
    },
  ];

  return (
    <>
      <AddDetailPopup type={"Detail"} show={createOpen} setShow={(e) => setCreateOpen(e)} />
      <Table
        tableName={"Detailed Description"}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        // hasSearch={false}
        createText={"Add"}
        createButtonClick={() => setCreateOpen(true)}
        COLUMNS={detDescTableColumns}
        rowResults={results}
        DATA={productStore.currentProduct.fullDetails}
        noBottom={results >= productStore.currentProduct.fullDetails.length}
        hiddenColumns={productStore.currentProduct.name ? [] : ["visibility"]}
      />
    </>
  );
};

export default observer(DetailedDescriptionTable);
