import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { SORT_TYPE } from "../utils/select.utils";

export class UiStateStore {
  hasDirtyData = false;

  fetchingStoreContent = false;

  fetchingLinkedSkus = false;

  fetchingCurrentAddon = false;

  fetchingAddonContent = false;

  fetchingJobContent = false;

  //#region Product Page States
  fetchingProductContent = false;
  productTableTab = 1;
  fetchingCurrentProduct = false;
  productDetailTab = 1;
  productTableFilterText = "";
  productTableFilterBy = "name";
  productTableSort = SORT_TYPE;
  //#endregion

  fetchingProductTypes = false;
  fetchingProductLinks = false;
  fetchingProductPros = false;
  fetchingProductDetails = false;
  fetchingProductAddons = false;

  //#region Order Page States
  fetchingOrderContent = false;
  fetchingCurrentOrder = false;
  orderTableTab = 1;
  orderTableFilterText = "";
  orderTableFilterBy = "raw.billing.first_name";
  orderAlertOpen = false;
  orderDetailTab = 1;
  orderTableSort = SORT_TYPE;
  //#endregion

  fetchingClientContent = false;
  fetchingCurrentClient = false;
  clientTableTab = 1;
  clientDetailTab = 1;
  clientTableFilterText = "";
  clientTableFilterBy = "firstName";
  clientTableSort = SORT_TYPE;

  fetchingUserContent = false;
  fetchingCurrentUser = false;
  userTableTab = 1;
  userDetailTab = 1;

  addonDetailTab = 1;

  logoutModalOpen = false;

  productTypeDetailTab = 1;

  fetchingCollectionContent = false;
  fetchingCurrentCollection = false;
  fetchingCollectionProducts = false;
  collectionTableFilterText = "";
  collectionTableFilterBy = "name";
  collectionTableSort = SORT_TYPE;

  fetchingQuoteContent = false;
  fetchingCurrentQuote = false;
  quoteTableFilterText = "";
  quoteTableFilterBy = "store";
  quoteTableSort = SORT_TYPE;
  quoteTableTab = 1;

  fetchingProServices = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UiStateStore",
      properties: [
        "productTableTab",
        "orderTableTab",
        "clientTableTab",
        "userTableTab",
        "productTypeDetailTab",
        "productDetailTab",
        "clientDetailTab",
        "userDetailTab",
        "orderDetailTab",
        "addonDetailTab",
        "orderTableFilterText",
        "orderTableFilterBy",
        "productTableFilterText",
        "collectionTableFilterText",
        "collectionTableFilterBy",
        "productTableFilterBy",
        "clientTableFilterText",
        "clientTableFilterBy",
        "productTableSort",
        "collectionTableSort",
        "orderTableSort",
        "clientTableSort",
      ],
    });
  }

  //#region Product Page Actions
  setFetchingProductContent(_) {
    this.fetchingProductContent = _;
  }

  setProductTableTab(tab) {
    this.productTableTab = tab;
  }
  //#endregion

  //#region Order Page Actions
  setFetchingOrderContent(_) {
    this.fetchingOrderContent = _;
  }

  setOrderTableTab(tab) {
    this.orderTableTab = tab;
  }

  setOrderTableFilterText(text) {
    this.orderTableFilterText = text;
  }

  setOrderTableFilterBy(text) {
    this.orderTableFilterBy = text;
  }
  //#endregion

  setFetchingClientContent(_) {
    this.fetchingClientContent = _;
  }

  setClientTableTab(tab) {
    this.clientTableTab = tab;
  }

  setFetchingUserContent(_) {
    this.fetchingUserContent = _;
  }

  setUserTableTab(tab) {
    this.userTableTab = tab;
  }

  setLogoutModal(open) {
    this.logoutModalOpen = open;
  }

  setFetchingCurrentProduct(_) {
    this.fetchingCurrentProduct = _;
  }

  setOrderAlertOpen(open) {
    this.orderAlertOpen = open;
  }

  setDirtyData(dirty, discard) {
    this.hasDirtyData = dirty;
    if (discard) {
      discard();
    }
  }

  setFetchingProductTypes(fetch) {
    this.fetchingProductTypes = fetch;
  }

  setFetchingStoreContent(fetch) {
    this.fetchingStoreContent = fetch;
  }

  setFetchingLinkedSkus(fetch) {
    this.fetchingLinkedSkus = fetch;
  }

  setFetchingCurrentAddon(_) {
    this.fetchingCurrentAddon = _;
  }

  setFetchingCurrentOrder(_) {
    this.fetchingCurrentOrder = _;
  }

  setFetchingProductLinks(_) {
    this.fetchingProductLinks = _;
  }

  setProductDetailTab(tab) {
    this.productDetailTab = tab;
  }

  setProductTypeDetailTab(tab) {
    this.productTypeDetailTab = tab;
  }

  setFetchingProductPros(_) {
    this.fetchingProductPros = _;
  }

  setFetchingCurrentClient(_) {
    this.fetchingCurrentClient = _;
  }

  setFetchingCollectionContent(_) {
    this.fetchingCollectionContent = _;
  }

  setFetchingQuoteContent(_) {
    this.fetchingQuoteContent = _;
  }

  setQuoteTableTab(_) {
    this.quoteTableTab = _;
  }

  setClientDetailTab(_) {
    this.clientDetailTab = _;
  }

  setUserDetailTab(_) {
    this.userDetailTab = _;
  }

  setOrderDetailTab(_) {
    this.orderDetailTab = _;
  }

  setAddonDetailTab(_) {
    this.addonDetailTab = _;
  }

  setFetchingCurrentCollection(_) {
    this.fetchingCurrentCollection = _;
  }

  setFetchingCurrentQuote(_) {
    this.fetchingCurrentQuote = _;
  }

  setFetchingCollectionProducts(_) {
    this.fetchingCollectionProducts = _;
  }

  setFetchingProductDetails(_) {
    this.fetchingProductDetails = _;
  }

  setFetchingCurrentUser(_) {
    this.fetchingCurrentUser = _;
  }

  setProductTableFilterBy(text) {
    this.productTableFilterBy = text;
  }

  setProductTableFilterText(text) {
    this.productTableFilterText = text;
  }

  setFetchingAddonContent(_) {
    this.fetchingAddonContent = _;
  }

  setFetchingJobContent(_) {
    this.fetchingJobContent = _;
  }

  setFetchingProServices(_) {
    this.fetchingProServices = _;
  }

  setCollectionTableFilterText(text) {
    this.collectionTableFilterText = text;
  }

  setCollectionTableFilterBy(text) {
    this.collectionTableFilterBy = text;
  }

  setClientTableFilterText(text) {
    this.clientTableFilterText = text;
  }

  setClientTableFilterBy(text) {
    this.clientTableFilterBy = text;
  }

  setProductTableSort(sort = SORT_TYPE) {
    this.productTableSort = sort;
  }

  setCollectionTableSort(sort = SORT_TYPE) {
    this.collectionTableSort = sort;
  }

  setOrderTableSort(sort = SORT_TYPE) {
    this.orderTableSort = sort;
  }

  setClientTableSort(sort = SORT_TYPE) {
    this.clientTableSort = sort;
  }

  setFetchingProductAddons(_) {
    this.fetchingProductAddons = _;
  }
  
}
