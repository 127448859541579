import { makeAutoObservable } from "mobx";

export class Account {
  createdAt;
  name = "";
  routes = {};
  applications = [];
  type = "";
  email = "";
  avatarUrl = "";
  updatedAt;
  _id = "";
  partner = {};
  partner_id = 0;
  services = [];
  zones = [];
  fullServices = [];
  edits = 0;
  editor_id = null;
  organizations = [];
  absences = [];

  constructor(obj) {
    makeAutoObservable(this);
    Object.assign(this, obj);
  }

  fromObject(obj) {
    this.partner = {};
    this.partner_id = 0;
    this.services = [];
    this.zones = [];
    Object.assign(this, obj);
    // HACK: Type is not on response
    if ((this.partner_id || this.editor_id) && !this.type) this.type = "ServicePro";
  }

  // For Service Pros, since they do not have ids on the response
  setId(id) {
    this._id = id;
  }

  edited() {
    this.edits++;
  }

  setFullServices(data) {
    this.fullServices = data;
  }

  addFullService(data) {
    const items = this.fullServices;
    if (!items.find((e) => (e?.pservice_sku || e?.sku) === (data?.pservice_sku || data?.sku)))
      items.push({ ...data, editable: true });
    else return;
    this.setFullServices(items);
    this.edited();
  }

  removeFullService(data) {
    this.fullServices.splice(
      this.fullServices.findIndex((e) => {
        return e.sku === data.sku;
      }),
      1
    );
    this.setFullServices(this.fullServices.map((item) => item));
    this.edited();
  }

  setZones(data) {
    this.zones = data;
  }

  addZone(data) {
    console.log(data);
    const items = this.zones;
    if (
      !items.find((e) => e?.city_name === data?.city_name && e?.zone_number === data?.zone_number)
    )
      items.push(data);
    else return false;
    this.setZones(items);
    this.edited();
    return true;
  }

  removeZone(data) {
    this.zones.splice(
      this.zones.findIndex((e) => {
        return e?.city_name === data?.city_name && e?.zone_number === data?.zone_number;
      }),
      1
    );
    this.setZones(this.zones.map((item) => item));
    this.edited();
  }

  resetEdits() {
    this.edits = 0;
  }

  hasAbs() {
    // TODO: Only check current org.
    const hasAbsApp = this.organizations?.find(
      o => o.applications?.find(a => a.id === "abs")
    )
    
   return hasAbsApp !== undefined;
  }
}
