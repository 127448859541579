import { makeAutoObservable, runInAction } from "mobx";
import { deleteAt, get, post, put } from "../utils/api";
import { SORT_TYPE } from "../utils/select.utils";
import { titleCase } from "../utils/string.utils";
import { Collection } from "./domain/collection";
import { Product } from "./domain/product";
import { PRODUCT_BASE_URL } from "./productStore";

const COLLECTIONS_URL = `collections/`;

export class CollectionStore {
  collections = [];
  totalCollections = 0;
  totalPages = 1;
  currentPage = 1;
  currentCollection = new Collection();
  collectionSnapshot = new Collection();
  currentProducts = []; // TODO: use fullProducts similar to how products work
  snapshotProducts = [];
  hasFetchedProducts = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadCollections(rawSearch, sort = SORT_TYPE) {
    this.rootStore.uiStateStore.setFetchingCollectionContent(true);
    const baseUrl = `${COLLECTIONS_URL}?page=${this.currentPage}${sort.sortBy ? `&sort[${sort.sortBy}]=${sort.sortDirection}` : ""
      }`;
    const fetchUrl = rawSearch ? `${baseUrl}&${rawSearch}` : baseUrl + `&limit=25`;

    try {
      const res = await get(fetchUrl);
      if (!this.rootStore.uiStateStore.fetchingCollectionContent) return;
      console.log(res);
      this.setProps(res);
      this.rootStore.uiStateStore.setFetchingCollectionContent(false);
    } catch (err) {
      // no opp
    }
  }

  setProps(res) {
    this.collections = res.collections;
    this.totalPages = res.totalPages;
    this.totalCollections = res.totalCollections;
  }

  async getCollection(name) {
    this.resetCollections();
    this.rootStore.uiStateStore.setFetchingCurrentCollection(true);

    try {
      const res = await get(`${COLLECTIONS_URL}${name}`);
      console.log(res);
      this.currentCollection.fromObject(res);

      // Get Popular Products
      const popular = await Promise.all(
        this.currentCollection?.popular?.map(async (id, idx) => {
          const item = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
          return new Product(item.products[0]);
        })
      );
      this.currentCollection.setFullPopular(popular, true);
      console.log("Popular", this.currentCollection.fullPopular);

      // Get Featured Product
      if (this.currentCollection.featured) {
        const featured = this.currentCollection.featured
          ? await get(`${PRODUCT_BASE_URL}?search[_id]=${this.currentCollection.featured}`)
          : null;
        this.currentCollection.setFullFeatured(featured.products[0], true);
      }
      console.log("Featured", this.currentCollection.fullFeatured);

      this.collectionSnapshot.fromObject(this.currentCollection);
    } catch (err) {
      console.error(err);
      this.rootStore.routeStore.redirect(`/collections`);
    } finally {
      this.rootStore.uiStateStore.setFetchingCurrentCollection(false);
    }
  }

  async loadCollectionProducts(products) {
    this.rootStore.uiStateStore.setFetchingCollectionProducts(true);

    try {
      const arr = await Promise.all(
        products.map(async (id, idx) => {
          const res = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
          return new Product(res.products[0]);
        })
      );
      runInAction(() => {
        this.currentProducts = arr;
        this.snapshotProducts = arr;
        this.hasFetchedProducts = true;
      });
    } catch (err) {
    } finally {
      this.rootStore.uiStateStore.setFetchingCollectionProducts(false);
    }
  }

  currentToSnapshot() {
    this.currentCollection = new Collection(this.collectionSnapshot);
    this.currentProducts = this.snapshotProducts;
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  addProduct(product) {
    if (!product?._id) return;
    if (!this.currentProducts.find((e) => e._id === product._id))
      this.currentProducts.push(product);
    else return;
    console.log(this.currentProducts);
    this.currentCollection.edited();
  }

  removeProduct(name) {
    this.currentProducts.splice(
      this.currentProducts.findIndex((item) => item.name === name),
      1
    );
    this.currentCollection.edited();
  }

  setCurrentProducts(products) {
    this.currentProducts = products;
    this.currentCollection.edited();
  }

  resetCollections() {
    this.currentCollection = new Collection();
    this.collectionSnapshot = new Collection();
    this.currentProducts = [];
    this.snapshotProducts = [];
  }

  async pushProduct() {
    let error = "";
    try {
      await put(
        `${COLLECTIONS_URL}${encodeURIComponent(this.collectionSnapshot.name)}/push`,
        {}
      );
      this.rootStore.notificationStore.createNotification(
        "(DEBUG) No errors",
        "Came back without any errors",
      )
    } catch (err) {
      error = err;
    }
    return error;
  }

  async createCollection() {
    const { id, cover, ...obj } = this.currentCollection;
    if (cover instanceof Array) {
      obj.cover = cover[0];
    }
    let result = "";
    try {
      await post(`${COLLECTIONS_URL}`, obj);
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Collection ${obj.name} has been created!`,
        "success"
      );
      this.loadCollections();
    } catch (err) {
      result = err;
    }

    return result;
  }

  async updateCollection() {
    let result = "";
    this.currentCollection.setProductsFromInfo(this.currentProducts);
    this.currentCollection.setPopularFromInfo();
    this.currentCollection.setFeaturedFromInfo();
    const { fullPopular, fullFeatured, currentFeatured, ...obj } = this.currentCollection;
    //obj.cover = cover[0];
    for (const overlay of (this.currentCollection.overlays ?? [])) {
      if (!overlay.data) continue;
      delete overlay.data.fullPopular;
      delete overlay.data.fullFeatured;

      for (const key in overlay.data) {
        if (overlay.data[key] === undefined) {
          delete overlay.data[key];
        }
      }
    }
    try {
      const res = await put(
        `${COLLECTIONS_URL}${encodeURIComponent(this.collectionSnapshot.name)}`,
        obj
      );
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Updated collection ${titleCase(this.collectionSnapshot.name)}`,
        "success"
      );
      this.collectionSnapshot.fromObject(this.currentCollection);
      runInAction(() => {
        this.snapshotProducts = this.currentProducts.map((item) => item);
      });
    } catch (err) {
      result = err;
    }

    return result;
  }

  async deleteCollection() {
    deleteAt(`${COLLECTIONS_URL}${encodeURIComponent(this.collectionSnapshot.name)}`)
      .then(() => {
        this.rootStore.notificationStore.createNotification(
          "SUCCESS",
          `Collection ${this.collectionSnapshot.name} has been deleted`,
          "success"
        );
        // this.loadProducts(this.productType);
      })
      .catch(() => { });
  }
}
