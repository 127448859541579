import { UiStateStore } from "./uiStateStore";
import { ProductStore } from "./productStore";
import { OrderStore } from "./orderStore";
import { RouteStore } from "./routeStore";
import { ClientStore } from "./clientStore";
import { UserStore } from "./userStore";
import { NotificationStore } from "./notificationStore";
import { AccountStore } from "./accountStore";
import { ShopStore } from "./shopStore";
import { MetadataStore } from "./metadataStore";
import { CollectionStore } from "./collectionStore";
import { ServiceProStore } from "./serviceProStore";
import { JobStore } from "./jobStore";
import { QuoteStore } from "./quoteStore";

export class RootStore {
  /**
   * Stores state information having to do with the User Interface.
   * Things like whether menus are opened or close, and state that other UI components
   * may be concerned with.
   */
  uiStateStore;

  /**
   * Stores information related to products.
   */
  productStore;

  orderStore;

  routeStore;

  clientStore;

  userStore;

  notificationStore;

  accountStore;

  shopStore;

  metadataStore;

  collectionStore;

  serviceProStore;

  jobStore;

  quoteStore;

  constructor() {
    this.uiStateStore = new UiStateStore(this);
    this.productStore = new ProductStore(this);
    this.orderStore = new OrderStore(this);
    this.routeStore = new RouteStore(this);
    this.clientStore = new ClientStore(this);
    this.userStore = new UserStore(this);
    this.notificationStore = new NotificationStore(this);
    this.accountStore = new AccountStore(this);
    this.shopStore = new ShopStore(this);
    this.metadataStore = new MetadataStore(this);
    this.collectionStore = new CollectionStore(this);
    this.serviceProStore = new ServiceProStore(this);
    this.jobStore = new JobStore(this);
    this.quoteStore = new QuoteStore(this);
  }

  initialFetch() {
    this.productStore.loadProducts();
    this.orderStore.loadOrders();
    this.clientStore.loadClients();
    this.userStore.loadUsers();
    this.shopStore.loadStores();
  }
}
