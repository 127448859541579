import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
// import { useStores } from "../../store";
import "./styles.css";

const ChangeBanner = ({ show, handleDiscard, handleSave }) => {
  // const { uiStateStore } = useStores();
  useEffect(() => {
    const list = document.querySelectorAll(".App, .sidebar-content");
    if (show) list.forEach((e) => e.classList.add("pad-app"));
    else list.forEach((e) => e.classList.remove("pad-app"));
    return () => list.forEach((e) => e.classList.remove("pad-app"));
  }, [show]);

  return show ? (
    <div className="banner">
      <div className="title-banner">
        <h2 className="text-banner">Unsaved Changes</h2>
        <div className="wrapper__btn">
          <div onClick={handleDiscard} className="change-banner-button w-button">
            Discard
          </div>
          <div onClick={handleSave} className="change-banner-button w-button">
            Save
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default observer(ChangeBanner);
