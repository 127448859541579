import { useCallback } from "react";
import ChangeDialog from "../components/change-dialog/ChangeDialog";
import ChangeBanner from "../components/change-banner/ChangeBanner";
import { rootStore } from "../store";

// TODO: Change so setClean has to be explicitly called so validation can be done
/**
 * Gives access to unsaved changes banner and confirm prompt.
 * Accepts a function to execute when discarding changes and one to execute when saving.
 *
 * @param {*} props
 * @returns A confirm prompt component, unsaved changes banner component, setDirty and setClean method.
 */
const useUnsavedChanges = (discardChanges, saveChanges) => {
  // const [isDirty, setDirty] = useState(false);

  const updateDirtyState = useCallback((dirty) => {
    rootStore.uiStateStore.setDirtyData(dirty);
    // setDirty(dirty);
  }, []);

  const handleDiscard = useCallback(() => {
    discardChanges();
    // updateDirtyState(false);
  }, [discardChanges]);

  const handleSave = useCallback(() => {
    saveChanges();
    // updateDirtyState(false);
  }, [saveChanges]);

  const UnsavedChangesBanner = (
    <ChangeBanner
      show={rootStore.uiStateStore.hasDirtyData}
      handleDiscard={() => handleDiscard()}
      handleSave={() => handleSave()}
    />
  );

  const ConfirmPrompt = (
    <ChangeDialog
      isBlocking={rootStore.uiStateStore.hasDirtyData}
      discardChanges={() => handleDiscard()}
    />
  );

  return [
    ConfirmPrompt,
    UnsavedChangesBanner,
    () => updateDirtyState(true),
    () => updateDirtyState(false),
  ];
};

export default useUnsavedChanges;
