import { makeAutoObservable } from "mobx";

export class OrderMetadata {
  display_key = "";
  display_value = "";
  id;
  key = "";
  value = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }
}

export class RawMetadata {
  id = "";
  key = "";
  value = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }
}
