import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import { useStores } from "../../../store";
import AddServiceZonePopup from "../../../components/add-service-zone/AddServiceZonePopup";
import DeleteCell from "../../../components/table/DeleteCell";

const ServiceZone = () => {
  const { userStore } = useStores();
  const [show, setShow] = useState(false);

  // TODO: POST-MVP, service area with region, city and province
  const columns = [
    {
      Header: "Zone",
      accessor: "zone_number",
      Cell: (e) => `Zone ${e.value}`,
    },
    {
      Header: "City",
      accessor: "city_name",
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (e) => <DeleteCell action={() => userStore.currentUser.removeZone(e.row.original)} />,
    },
  ];
  return (
    <>
      <AddServiceZonePopup show={show} setShow={(s) => setShow(s)} />
      <Table
        tableName={`Service Zones`}
        DATA={userStore.currentUser.zones}
        COLUMNS={columns}
        rowResults={25}
        createText={"Add Zone"}
        createButtonClick={() => setShow(true)}
      />
    </>
  );
};

export default observer(ServiceZone);
