import React, { createRef, useEffect } from "react";
import { useStores } from "../../store";
import { post } from "../../utils/api";
import { getLastItemInUrl } from "../../utils/string.utils";

const FileUpload = ({
  name,
  accept = "image/*, video/*",
  clicked,
  resetClicked,
  handleUpload,
  multiple = false,
}) => {
  const fileInput = createRef();
  const { notificationStore, shopStore } = useStores();

  const handleClick = (e) => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    // alert(`Selected file - ${fileInput.current.files[0].name}`);
    // console.log(fileInput.current.files[0]);
    // if (handleUpload && fileInput.current.files) {
    // const url = URL.createObjectURL(fileInput.current.files[0]);
    // handleUpload(url);
    // uiStateStore.storedBlobs.push(fileInput.current.files[0]);
    // uiStateStore.storedURLs.push(url);
    // } else {
    const formData = new FormData();
    for (let i = 0; i < fileInput.current.files.length; i++) {
      formData.append(`file[${i}]`, fileInput.current.files[i]);
    }
    const currentStore = shopStore.currentStore;
    notificationStore.createNotification(
      `Uploading files...`,
      `Please wait while your files are being uploaded.`
    );
    post(`/media/`, formData)
      .then((res) => {
        if (handleUpload) res.forEach((data) =>
          handleUpload({
            name: getLastItemInUrl(data.url),
            url: data.url,
            store: currentStore ?? "0",
            visible: true
          }));
      })
      .catch((e) => {
        console.error(e);
        notificationStore.createNotification(
          `ERROR`,
          `Error occurred while uploading file!`,
          `error`
        );
      });
    fileInput.current.value = "";
    // }

    resetClicked();
  };

  useEffect(() => {
    if (clicked) {
      handleClick();
      resetClicked();
    }
  }, [clicked]);

  return (
    <form encType="multipart/form-data">
      <input
        type={"file"}
        name={name}
        id={name}
        accept={accept}
        ref={fileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple={multiple ? "multiple" : ""}
      />
    </form>
  );
};

export default FileUpload;
