import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ProductAddOnTable from "../../../components/product-add-on-table/ProductAddOnTable";
const Addons = ({ product }) => {
  return (
    <>
      <ProductAddOnTable product={product} />
    </>
  );
};

export default observer(Addons);
