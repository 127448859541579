import * as yup from "yup";
import { META_DESC_LENGTH, SEO_TITLE_LENGTH } from "./number.utils";

// TODO: validation schemas for before posting/putting to api?

// Common

export const markupOrPriceSchema = yup.lazy((value) =>
  value === ""
    ? yup.string().optional()
    : yup
        .number()
        .nullable()
        .optional()
        .typeError("Must be a number!")
        .positive("Cannot be negative!")
);

export const genericNameSchema = yup.string().required(`Name is required!`);

export const seoTitleSchema = yup.string().optional().max(SEO_TITLE_LENGTH);

export const seoDescSchema = yup.string().optional().max(META_DESC_LENGTH);

export const seoSlugSchema = yup.string().optional(); // TODO: Invalid characters?

// Attribute

export const internalNameSchema = yup.string().required(`Internal name is required!`);

export const externalNameSchema = yup.string().optional();

// Addon

export const addonBioSchema = yup.string().required(`A bio is required!`);
