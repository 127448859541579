import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../store";
import { PRODUCT_SEARCH_FIELDS } from "../../utils/select.utils";
import SearchTableDropdown from "../table/SearchTableDropdown";
const ProductTableDropdown = () => {
  const { uiStateStore } = useStores();
  return (
    <SearchTableDropdown
      searchFields={PRODUCT_SEARCH_FIELDS}
      filterBy={uiStateStore.productTableFilterBy}
      setFilterBy={(val) => uiStateStore.setProductTableFilterBy(val)}
    />
  );
};

export default observer(ProductTableDropdown);
