import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../store";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import { sentenceCase } from "../../../utils/string.utils";
import { observer } from "mobx-react-lite";
import TabbedContent from "../../../components/tabbed-content/TabbedContent";
import Information from "./detail-tabs/Information";
import Products from "./detail-tabs/Products";
// import Attributes from "../product-details/Attributes";
// import Addons from "../product-details/Addons";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";
import StoreSelector from "../../../components/store-selector/StoreSelector";

// TODO: NotFound when type does not exist
const ProductTypeDetails = () => {
  const { productStore, notificationStore, uiStateStore } = useStores();
  // const [tab, setTab] = useState(1);
  const { type } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (type !== productStore.currentProduct.type || productStore.currentProduct.name) {
      if (productStore.currentProduct.type) uiStateStore.setProductTypeDetailTab(1);
      productStore.getProductType(type);
    }
  }, [productStore.currentProduct.name, productStore.currentProduct.type, type]);

  const [Prompt, Banner, setDirty, setClean] = useUnsavedChanges(
    () => {
      productStore.currentToSnapshot();
      setClean();
    },
    async () => {
      notificationStore.createNotification(
        `Updating...`,
        `Update for ${productStore.currentProduct.type} pending...`,
        "info"
      );

      const err = await productStore.updateProductType();
      if (!err) {
        productStore.currentProduct.resetEdits();
        setClean();
        setIsSubmitted(true);
      }
    }
  );

  useEffect(() => {
    if (productStore.currentProduct.edits > 0) {
      setDirty();
    }

    if (isSubmitted) {
      setClean();
      setIsSubmitted(false);
    }
  }, [productStore.currentProduct.edits]);

  return !uiStateStore.fetchingCurrentProduct ? (
    <>
      {Prompt}
      {Banner}
      <div className="dashboard-container-2">
        <div className="horizontal-wrapper header">
          <PageBackButton redirectTo={`/product-types`} backText={"Back"} />
          <StoreSelector />
        </div>
        <DetailHeader
          title={sentenceCase(productStore.currentProduct.type)}
          subtitle={`ID# ${productStore.currentProduct._id}`}
        />
        <TabbedContent
          currentTab={uiStateStore.productTypeDetailTab}
          setTabIndex={(t) => uiStateStore.setProductTypeDetailTab(t)}
        >
          <Information label="Information" product={productStore.currentProduct} />
          {/* <Attributes label="Attributes" product={productStore.currentProduct} /> */}
          {/* <Addons label="Addons" product={productStore.currentProduct} /> */}
          <Products label="Products" product={productStore.currentProduct} />
        </TabbedContent>
      </div>
    </>
  ) : (
    <div className="dashboard-container-2">
      <DetailHeader title={`Loading Product Type...`} subtitle={`Give us a second...`} />
    </div>
  );
};

export default observer(ProductTypeDetails);
