import React, { useCallback, useMemo } from "react";
import ReactTooltip from "react-tooltip";

const MAX_PAGES = 10;

const Pagination = (props) => {
  let pageLinks = [];

  const startIndex = useMemo(() => (props.zeroBased ? 0 : 1), [props.zeroBased]);
  const useCurrent = useMemo(
    () => (props.zeroBased ? props.currentPage + 1 : props.currentPage),
    [props.zeroBased, props.currentPage]
  );

  const goToPage = useCallback(
    (p) => {
      console.log(`GO TO PAGE`, p);
      if (p <= props.pages) props.goToPage(p);
      // if (p >= props.currentPage && p <= props.pages) window.scroll(0, 0);
    },
    [props]
  );

  const changePage = useCallback(
    (val) => {
      if (val > 0) goToPage(props.zeroBased ? Number.parseInt(val) - 1 : Number.parseInt(val));
    },
    [goToPage, props.zeroBased]
  );

  if (!props.onlyArrows) {
    for (let i = startIndex; i < props.pages + startIndex; i++) {
      let active = props.currentPage === i ? "back" : "next";
      pageLinks.push(
        <div
          className={`${active} order_btn w-button`}
          key={i}
          onClick={() => (active === "next" ? goToPage(i) : null)}
        >
          {props.zeroBased ? i + 1 : i}
        </div>
      );
    }

    if (useCurrent >= MAX_PAGES) {
      pageLinks = pageLinks.slice(useCurrent - MAX_PAGES + 1);
      for (let i = props.pages - 1; i > useCurrent; i--) pageLinks.pop();
    } else pageLinks = pageLinks.slice(0, MAX_PAGES);
  }

  return props.pages > 0 ? (
    <div className={props.onlyArrows ? "w-col w-col-6 div-block-29 left" : ""}>
      {!props.onlyArrows && (
        <div
          onClick={() => goToPage(startIndex)}
          className={`order_btn w-button ${props.currentPage > startIndex ? "next" : "back"}`}
        >
          &lt;&lt;
        </div>
      )}
      {props.currentPage > startIndex ? (
        <div
          onClick={() => goToPage(props.currentPage - 1)}
          className={`order_btn w-button ${props.currentPage > startIndex ? "next" : "back"}`}
        >
          &lt;
        </div>
      ) : (
        ""
      )}
      {pageLinks}
      {props.currentPage < props.pages - (props.zeroBased ? 1 : 0) ? (
        <div onClick={() => goToPage(props.currentPage + 1)} className="order_btn next w-button">
          &gt;
        </div>
      ) : (
        ""
      )}
      {!props.onlyArrows && (
        <div
          onClick={() => goToPage(props.zeroBased ? props.pages - 1 : props.pages)}
          className={`order_btn w-button ${
            props.currentPage < props.pages - (props.zeroBased ? 1 : 0) ? "next" : "back"
          }`}
        >
          &gt;&gt;
        </div>
      )}
      <div>
        <div className="heading-64" style={{ marginBottom: "0px" }}>
          Page {props.zeroBased ? Number.parseInt(props.currentPage) + 1 : props.currentPage} of{" "}
          {props.pages} {props.pages > 1 && `| Go to page: `}
          {props.pages > 1 && (
            <>
              <input
                type={"number"}
                className={`w-input`}
                style={{
                  borderRadius: "5px",
                  width: "5vw",
                  display: "inline",
                  maxHeight: "20px",
                  padding: "0px 0px 0px 8px",
                }}
                onChange={(e) => (props.zeroBased ? changePage(e.target.value) : null)}
                onBlur={(e) => (!props.zeroBased ? changePage(e.target.value) : null)}
                min={1}
                defaultValue={!props.zeroBased ? props.currentPage : undefined}
                max={props.pages}
                onKeyDown={(e) =>
                  !props.zeroBased ? (e.key === "Enter" ? e.currentTarget.blur() : null) : null
                }
                data-tip={!props.zeroBased ? "Press enter to change pages" : ""}
              />
              {!props.zeroBased && <ReactTooltip />}
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={props.onlyArrows ? "w-col w-col-6 div-block-29 left" : ""}> </div>
  );
};

export default Pagination;
