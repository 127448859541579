import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStores } from "../../store";
import useTitle from "../../hooks/useTitle";

const ReactionContainer = () => {
  const { routeStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  useTitle();

  // TODO: Use Interval for loading Here, only load data when you aren't on that page

  useEffect(() => {
    const path = routeStore.redirectedTo;
    if (path) {
      navigate(path);
      window.scroll(0, 0);
    }
    routeStore.reset();
  }, [routeStore.redirectedTo]);

  return null;
};

export default observer(ReactionContainer);
