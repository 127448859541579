import { makeAutoObservable } from "mobx";
import { get, put } from "../utils/api";
import { titleCase } from "../utils/string.utils";
import { Account } from "./domain/account";

const USER_URL = "users/";
export class UserStore {
  users = [];

  totalPages = 0;

  currentPage = 1;

  totalUsers = 0;

  currentUser = new Account();
  userSnapshot = new Account();

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  updateUserSnapshot() {
    this.userSnapshot = new Account(this.currentUser);
  }

  currentUserToSnapshot() {
    this.currentUser = new Account(this.userSnapshot);
  }

  resetUsers() {
    this.currentUser = new Account();
    this.userSnapshot = new Account();
  }

  async loadUsers() {
    this.rootStore.uiStateStore.setFetchingUserContent(true);

    get(`${USER_URL}?page=${this.currentPage}`)
      .then((res) => {
        if (!this.rootStore.uiStateStore.fetchingUserContent) return;
        console.log(res);
        this.setProps(res);
      })
      .catch((err) => {
        // no op
        this.rootStore.uiStateStore.setFetchingUserContent(false);
      });
  }

  async getUser(id) {
    this.rootStore.uiStateStore.setFetchingCurrentUser(true);

    try {
      const res = await get(`${USER_URL}${id}`);
      console.log(res);
      this.currentUser.fromObject(res);
      this.userSnapshot.fromObject(res);
      await this.rootStore.serviceProStore.getServices(this.currentUser);
      this.userSnapshot.setFullServices(this.currentUser.fullServices.map((item) => item));
      this.rootStore.uiStateStore.setFetchingCurrentUser(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingCurrentUser(false);
    }
  }

  async getPro(id) {
    try {
      const res = await get(`pros/${id}`);
      this.currentUser.fromObject(res);
      console.log(res);
    } catch (err) {}
  }

  async updateUser() {
    const { edits, fullServices, services, ...obj } = this.currentUser;
    obj.services = fullServices.map((item) => {
      return { name: item?.name || item?.pservice_name, sku: item.sku || item?.pservice_sku };
    });
    let result = "";
    try {
      await put(`${USER_URL}${obj._id}`, obj);
      // if (this.currentUser.type === "pro" || this.currentUser?.partner_id) {
      //   this.currentUser.fullServices.map(async (data) => {
      //     if (data?.editable)
      //       await this.rootStore.serviceProStore.updateService(data, this.currentUser);
      //   });
      // }
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Updated user ${titleCase(this.userSnapshot.name)}`,
        "success"
      );
      this.updateUserSnapshot();
    } catch (err) {
      result = err;
    }

    return result;
  }

  setProps(res) {
    this.users = res;
    this.totalPages = 1; // temporary
    this.totalUsers = res.length;
    this.rootStore.uiStateStore.setFetchingUserContent(false);
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }
}
