import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../store";
import { COLLECTION_SEARCH_FIELDS } from "../../utils/select.utils";
import SearchTableDropdown from "../table/SearchTableDropdown";

const CollectionTableDropdown = () => {
  const { uiStateStore } = useStores();
  return (
    <SearchTableDropdown
      searchFields={COLLECTION_SEARCH_FIELDS}
      filterBy={uiStateStore.collectionTableFilterBy}
      setFilterBy={(val) => uiStateStore.setCollectionTableFilterBy(val)}
    />
  );
};

export default observer(CollectionTableDropdown);
