import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useStores } from "../../store";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import { DETAIL_TOOLTIP, NO_RESULTS_TEXT, NO_RESULTS_TEXT_INHERITS } from "../../utils/string.utils";
import AddDetailPopup from "../add-detail-popup/AddDetailPopup";
import CheckboxField from "../checkbox-field/CheckboxField";
import DeleteCell from "../table/DeleteCell";
import Table from "../table/Table";
import TextBoxField from "../text-box-field/TextBoxField";

const FaqTable = () => {
  const { metadataStore, productStore, shopStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [createOpen, setCreateOpen] = useState(false);
  const filterFn = c => c.store === shopStore.currentStore;
  const [fullDetails, setFullDetails] = useState([]);

  useEffect(() => {
    setFullDetails(() => {
      const { faq, fullFaq } = productStore.currentProduct;
      const detailsToUse = faq.filter(filterFn);
      console.log("Full FAQ", fullFaq);
      return fullFaq.filter(d => d.fresh || detailsToUse.find(p => p.detail === d._id));
    });
  }, [shopStore.currentStore, productStore.currentProduct.saveCounter, productStore.currentProduct.detailsEdited]);


  // FIXME: exact same as detailed desc columns
  const faqTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Question",
      accessor: "title",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.title}
                setField={(val) => {
                  e.cell.row.original.setTitle(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description Title"}
                style={{ minWidth: "90px", maxWidth: "250px" }}
                resize={"horizontal"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Answer",
      accessor: "description",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.description}
                setField={(val) => {
                  e.cell.row.original.setDescription(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description"}
                style={{ minWidth: "250px", width: "600px", maxWidth: "800px" }}
                resize={"both"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Visibility",
      accessor: "visibility",
      Cell: (e) => (
        <div style={{ display: "inline-block" }}>
          <CheckboxField
            name="add"
            label={"Shown"}
            labelOff={"Hidden"}
            isChecked={!productStore.currentProduct.getDetailVisibility(e.cell.row.original._id)}
            setField={(val) => {
              productStore.currentProduct.setDetailVisibility(e.cell.row.original._id, !val);
              console.log(`Changing Detail to:`, val);
            }}
          />
        </div>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (cell) => (
        <DeleteCell
          action={() => productStore.currentProduct.removeFullFaq(cell.row.original)}
          disabled={!cell.row.original.editable}
          tooltip={!cell.row.original.editable ? DETAIL_TOOLTIP : ""}
        />
      ),
    },
  ];

  return (
    <>
      <AddDetailPopup type={"FAQ"} show={createOpen} setShow={(e) => setCreateOpen(e)} />
      <Table
        tableName={`Frequently Asked Questions (${shopStore.currentStoreName})`}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        // hasSearch={false}
        createText={"Add"}
        createButtonClick={() => setCreateOpen(true)}
        COLUMNS={faqTableColumns}
        rowResults={results}
        DATA={fullDetails}
        noResultsText={shopStore.currentStore === "0" ? NO_RESULTS_TEXT : NO_RESULTS_TEXT_INHERITS}
        // draggable
        setData={() => null}
        noBottom={results >= fullDetails.length}
        hiddenColumns={productStore.currentProduct.name ? [] : ["visibility"]}
      />
    </>
  );
};

export default observer(FaqTable);
