export const SHOP_DETAILS = [
  {
    id: "-1",
    name: "Unknown Store",
    placeholder: true
  },
  {
    id: "0",
    name: "All Stores",
    placeholder: true
  },
  {
    id: "devel",
    name: "QTI Development Site"
  },
  {
    id: "ee6155d2-711d-11ed-a1eb-0242ac120002",
    name: "RealtyShop"
  },
  {
    id: "ff383655-506a-4deb-9113-5635da2e908c",
    name: "BizzShop"
  },
  {
    id: "28a2c276-ad3f-4e91-94e0-4084b8948775",
    name: "Zadegan Realty Shop"
  },
  {
    id: "1",
    name: "KWRC Shop"
  },
  
]