import _axios from "axios";
import { rootStore } from "../store";

export const isProd = process.env.NODE_ENV === "production";

export const axios = _axios.create({
  // baseURL: "http://localhost:8086",
  baseURL: process.env.REACT_APP_API_URL ?? "https://ccs.blwn.ca",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  },
});

export const fileUploadAxios = _axios.create({
  baseURL: "https://media.realtyshop.ca", // post to add-asset.php
  headers: { "X-Authorization": "Some key but for uploads TODO" }, // TODO: Proper Key
});

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let currentToken = undefined;

export function configureAxios() {
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.log("Request error");
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // if (isRefreshing) return;
      if (error?.response?.status) {
        const errStatus = error.response.status;
        const traceCode = error.response.data.id;
        if (errStatus === 418) {
          refreshAccessToken();
        }

        let notificationTitle = errStatus.toString();
        if (traceCode) {
          notificationTitle = `${notificationTitle} (Trace code ${traceCode})`;
        }
        if (errStatus !== 418) {
          console.log(notificationTitle);
          rootStore.notificationStore.createNotification(
            notificationTitle,
            error.response.data.message,
            "error"
          );
        }
        if (errStatus === 401 || errStatus === 403) {
          rootStore.routeStore.redirect("/");
          if (errStatus === 401) window.location.reload(true);
        }
      }

      return Promise.reject(error);
    }
  );
}

if (localStorage.getItem("authn")) {
  const authn = JSON.parse(localStorage.getItem("authn"));
  axios.defaults.headers["Authorization"] = `Bearer ${authn.accessToken}`;
  currentToken = authn.accessToken;
}

async function execute(options) {
  let retryCount = 0;
  while (retryCount++ < 2) {
    if (isRefreshing && options.url !== "/auth/refresh") {
      await sleep(500);
      retryCount--;
      continue;
    }
    try {
      const res = await axios({
        ...options,
        headers: { "Authorization": currentToken ? `Bearer ${currentToken}` : "" }
      });
      return res;
    } catch (e) {
      if (e?.response?.status !== 418) {
        return e.response;
        // break;
      }
      // if (res.status === 418) {
      //   await refreshAccessToken();
      //   continue;
      // }
    }
  }
}

let isRefreshing = false;
async function refreshAccessToken() {
  console.log("Will refresh access token");
  if (isRefreshing) return;
  try {
    isRefreshing = true;
    const authn = JSON.parse(localStorage.getItem("authn"));
    if (authn.refreshToken) {
      const res = await execute({ method: "post", url: "/auth/refresh", data: { token: authn.refreshToken }  });
      if (res.status === 200 && res.data.accessToken) {
        localStorage.setItem("authn", JSON.stringify(res.data));
        axios.defaults.headers["Authorization"] = `Bearer ${res.data.accessToken}`;
        currentToken = res.data.accessToken;
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    isRefreshing = false;
  }
}

/**
 * Login to CCS.
 * @param {String} email User's email.
 * @param {String} password User's password.
 */
export async function login(email, password) {
  // if (!axios.defaults.headers["Authorization"]) {
    const payload = { email, password };
    const res = await execute({ method: "post", url: "/auth/login", data: payload });
    if (res.status === 200 && res.data.accessToken) {
      localStorage.setItem("authn", JSON.stringify(res.data));
      axios.defaults.headers["Authorization"] = `Bearer ${res.data.accessToken}`;
      currentToken = res.data.accessToken;
    }
    return true;
  // }
}

/**
 * Logout of CCS.
 */
export async function logout() {
  if (axios.defaults.headers["Authorization"]) {
    localStorage.removeItem("authn");
  }
}

/**
 * GET data from the API.
 * @param {String} url The route to GET from.
 * @returns {any} The data returned from the API.
 */
export async function get(url) {
  const res = await execute({ method: "get", url });
  return res.data;
}

/**
 * POST data to the API.
 * @param {String} url The route to POST to.
 * @param {any} data The data to send.
 * @returns {any} The data returned from the API.
 */
export async function post(url, data) {
  const res = await execute({ method: "post", url, data });
  return res.data;
}

/**
 * PUT data to the API.
 * @param {String} url The route to PUT to.
 * @param {any} data The data to send.
 * @returns {any} The data returned from the API.
 */
export async function put(url, data) {
  const res = await execute({ method: "put", url, data });
  return res.data;
}

export async function deleteAt(url) {
  const res = await execute({ method: "delete", url });
  return res.data;
}

/**
 * Call an RPC function.
 * @param {String} fun The function to call.
 * @param {Object} data The data to use.
 */
export async function call(fun, data) {
  const res = await execute({ method: "post", url: "/rpc", data: { function: fun, data }});
}