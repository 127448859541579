import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { get } from "../utils/api";

const STORE_URL = `stores/`;
const ALL_STORES = { id: "0", name: "All Stores", url: undefined };

export class ShopStore {
  stores = [ALL_STORES];
  hasFetched = false; // should not change much if at all, so should only happen once
  currentStore = "0"; // All Stores by default
  currentStoreName = "All Stores";
  storeDetails = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    makePersistable(this, { name: `ShopStore`, properties: ["currentStore", "currentStoreName"] });
  }

  async loadStores() {
    if (this.rootStore.uiStateStore.fetchingStoreContent) return; // if something is already loading the stores, don't bother
    this.rootStore.uiStateStore.setFetchingStoreContent(true);
    try {
      if (!this.rootStore.uiStateStore.fetchingStoreContent) return;
      const res = await get(`${STORE_URL}?limit=100`);
      this.setProps([ALL_STORES, ...res]);
      // this.storeDetails = await Promise.all(
      //   res.map(async (store) => {
      //     const detail = await this.getStore(store.id);
      //     return detail;
      //   })
      // );
    } catch (err) {
    } finally {
      this.rootStore.uiStateStore.setFetchingStoreContent(false);
    }
  }

  async getStore(id) {
    try {
      const res = await get(`${STORE_URL}${id}`);
      console.log(res);
      this.currentStoreDetail = res;
    } catch (err) { }
  }

  setProps(res) {
    this.stores = res;
    this.rootStore.uiStateStore.setFetchingStoreContent(false);
    this.hasFetched = true;
  }

  setCurrentStore(store) {
    this.currentStore = store;
    this.currentStoreName = this.stores.find(s => s.id === store).name;
  }
}
