import React from "react";
import { STATUS_COLORS } from "../../utils/select.utils";

const StatusText = ({ color, text, classes }) => {
  return (
    <div className={classes || "status-2"}>
      <div className={`indication-color bg-primary-${color || "green"}`}></div>{" "}
      <div className="product__text">{text}</div>
    </div>
  );
};

export const determineColor = (status) => {
  const item = STATUS_COLORS.find((item) => item.status === status?.toLowerCase());
  return item?.statusColor || "grey";
};

export default StatusText;
