import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TempAvatar } from "../../../assets/images";
import { useStores } from "../../../store";
import TabbedContent from "../../../components/tabbed-content/TabbedContent";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import ProServices from "./ProServices";
import ServiceZone from "./ServiceZone";
import General from "./General";
import Security from "./Security";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";
import useTitle from "../../../hooks/useTitle";
import Absences from "./Absences";

const proOnlyTabs = [3, 4, 5];

const UserDetails = ({ isCurrentAccount = false }) => {
  const { user } = useParams();
  const { userStore, uiStateStore, accountStore, notificationStore } = useStores();

  const [Prompt, Banner, setDirty, setClean] = useUnsavedChanges(
    () => {
      userStore.currentUserToSnapshot();
      setClean();
    },
    async () => {
      notificationStore.createNotification(
        `Updating...`,
        `Update for ${userStore.currentUser.name} pending...`,
        "info"
      );
      const err = await userStore.updateUser();
      if (!err) {
        userStore.currentUser.resetEdits();
        setClean();
      }
    }
  );

  useEffect(() => {
    if (userStore.currentUser.edits > 0) {
      setDirty();
    }
  }, [userStore.currentUser.edits, setDirty]);

  useEffect(() => {
    if (isCurrentAccount) return;

    if (user !== userStore.currentUser._id) {
      if (userStore.currentUser._id) {
        uiStateStore.setUserDetailTab(1);
      }
      userStore.currentUser.setId(user); // for service pros that dont have id on response
      userStore.getUser(user);
    }
  }, [isCurrentAccount, user]);

  useEffect(() => {
    if (
      !uiStateStore.fetchingCurrentUser &&
      !userStore.currentUser.partner_id &&
      proOnlyTabs.find((val) => val === uiStateStore.userDetailTab)
    )
      uiStateStore.setUserDetailTab(1);
  }, [userStore.currentUser.partner_id, uiStateStore.fetchingCurrentUser]);

  useEffect(() => {
    if (isCurrentAccount) userStore.currentUser.fromObject(accountStore.user);
  }, [isCurrentAccount, userStore.currentUser, accountStore.user]);

  useTitle(isCurrentAccount ? "My Account" : `Details for ${userStore.currentUser.email}`);

  return !uiStateStore.fetchingCurrentUser ? (
    <div className="dashboard-container-2">
      {Prompt}
      {Banner}
      <div className="horizontal-wrapper header">
        <PageBackButton redirectTo={`/users`} backText={"Back"} />
      </div>
      <div className="profile-banner">
        <div className="profile-avatar">
          <img src={userStore.currentUser.avatarUrl} loading="lazy" alt="" className="profile-picture" />
          <div>
            <div className="caption text-white">Profile</div>
            <h2 className="no-space-bottom">{userStore.currentUser.name}</h2>
          </div>
        </div>
        <div className="fade-out"></div>
      </div>
      <TabbedContent
        currentTab={uiStateStore.userDetailTab}
        setTabIndex={(t) => uiStateStore.setUserDetailTab(t)}
      >
        <General label="General" />
        {isCurrentAccount && <Security label="Security" />}
        {userStore.currentUser.partner_id && <ServiceZone label="Service Zones" />}
        {userStore.currentUser.partner_id && <ProServices label="Pro Services" />}
        {userStore.currentUser.partner_id && <div label="Jobs" />}
        {userStore.currentUser.hasAbs() && <Absences label="Absences" />}
        {isCurrentAccount && userStore.currentUser.partner_id && <div label="Jobs" />}
      </TabbedContent>
    </div>
  ) : (
    <div className="dashboard-container-2">
      <DetailHeader title={"Loading User..."} subtitle={"Give us a second"} />
    </div>
  );
};

export default observer(UserDetails);
