import { observer } from "mobx-react-lite";
import React from "react";
import OrderItemTable from "../../../components/order-item-table/OrderItemTable";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import TextBoxField from "../../../components/text-box-field/TextBoxField";
import OrderTimelineTable from "../../../components/order-timeline-table/OrderTimelineTable";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import { removeKebab, sentenceCase } from "../../../utils/string.utils";
import { stringToFloat } from "../../../utils/number.utils";

const Items = ({ order }) => {
  console.log(order);
  return (
    <>
      <OrderItemTable order={order} />
      <BoxedContent title={"Customer Comments"}>
        <div style={{ marginBottom: "20px" }}></div>
        {/* TODO: actual field */}
        <TextBoxField
          placeholder={"Enter customer comments..."}
          field={order?.raw?.customer_note || "None"}
          disabled
        />
      </BoxedContent>
      {/* TODO: Order Total */}
      <BoxedContent>
        <div className="table-column table-column--title table-column--padding-none">
          <h2 className="heading-61">Total</h2>
          <div className="flex">
            <StatusText
              color={determineColor(order?.raw?.status)}
              text={sentenceCase(removeKebab(order?.raw?.status))}
              classes={"status-2 header"}
            />
          </div>
        </div>
        <div className="table-column table-column--content table-column--vertical">
          <div className="w-layout-grid grid-11">
            <div className="text-block-26">
              <div className="body-link-2">Subtotal</div>
            </div>
            <div className="body-link-2">
              {order?.lineItems?.length} Item{order?.lineItems?.length > 1 && "s"}<div className="body-link-2"></div>
            </div>
            <div className="body-link-2 right">
              <div className="body-link-2">
                {
                  `${order?.raw?.currency_symbol} 
                  ${(stringToFloat(order?.raw?.total) - stringToFloat(order?.raw?.total_tax)).toFixed(2)}`}
              </div>
            </div>
            <div className="body-link-2">
              <div className="body-link-2">Shipping</div>
            </div>
            <div className="body-link-2">{order?.shipping?.company || "N/A"}</div>
            <div className="body-link-2 right">
              <div className="body-link-2">{`${order?.raw?.currency_symbol} ${order?.raw?.shipping_total}`}</div>
            </div>
            <div className="body-link-2">Tax</div>
            {/* TODO: Dynamic Tax Line */}
            <div className="body-link-2">
              <div className="body-link-2">HST 13%</div>
            </div>
            <div className="body-link-2 right">
              <div className="body-link-2">
                {`${order?.raw?.currency_symbol} ${order?.raw?.total_tax}`}
              </div>
            </div>
            <div className="body-link-2">
              <div className="body-link-2">Refunded</div>
            </div>
            <div className="total-paid-box total-paid-box--bigger"></div>
            <div className="body-link-2 right">
              {/* TODO: Refunded Amount */}
              <div className="body-link-2" style={{ color: "#dc0004", fontWeight: "600" }}>
                -$ 0.00
              </div>
            </div>
            <div className="text-block-28">
              <div className="body-link-2">
                <strong className="bold-text-2">Total</strong>
              </div>
            </div>
            <div className="total-paid-box total-paid-box--bigger"></div>
            <div className="body-link-2 right bold-text" style={{ fontWeight: "700" }}>
              {`${order?.raw?.currency_symbol} ${order?.raw?.total}`}
            </div>
          </div>
        </div>
      </BoxedContent>
      <OrderTimelineTable order={order} />
    </>
  );
};

export default observer(Items);
