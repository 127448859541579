import React, { useCallback, useEffect, useState } from "react";
import SelectField from "../select-field/SelectField";
import { useStores } from "../../store";
import { PRODUCT_TYPES, PRODUCT_STATUSES } from "../../utils/select.utils";
import { observer } from "mobx-react-lite";

const CreateProduct = ({ product, show, setShow }) => {
  const { productStore, notificationStore, routeStore } = useStores();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [tag, setTag] = useState("");
  const [linkedSku, setLinkedSku] = useState(product ? product.linkedSku : "");
  const [creating, setCreating] = useState(false);
  // TODO: Value does not change when linked skus are fetched

  useEffect(() => {
    if (show) productStore.loadLinkedSkus();
    if (product) {
      setName(product ? product.name : "");
      setType(product.type !== "Sina" ? product.type : "Repackage");
      setTag(product.status);
      setLinkedSku(product.linkedSku);
    }
  }, [show]);

  useEffect(() => {
    if (type !== "Repackage" && linkedSku) {
      setLinkedSku("");
      console.log("Reset Linked Sku");
    }
  }, [type, linkedSku]);

  const handleDiscard = useCallback(() => {
    setName("");
    setType("");
    setTag("");
    setLinkedSku("");
    setShow(false);
  }, []);

  const handleCreate = useCallback(async () => {
    if (name && type && tag) {
      setCreating(true);
      notificationStore.createNotification(
        `Creating...`,
        `Product ${name} is being created!`,
        "info"
      );
      const err = await productStore.createProduct({
        name: name,
        type: type,
        status: tag,
        linkedSku: linkedSku,
      });
      setCreating(false);
      setShow(false);
      if (err._id) routeStore.redirect(`/products/${err._id}`);
      // handleDiscard();
    }
  }, [name, type, tag, linkedSku]);

  if (creating) {
    return (
      <div className="modal-wrapper">
        <div className="lds-hourglass"></div>
      </div>
    );
  }
  if (!show) return null;

  return (
    <div className="modal-wrapper">
      <div className="custom-modal">
        <div className="modal-title">
          <h1 className="title">{product ? `Create Product from ${name}` : `Create Product`}</h1>
        </div>
        <div className="form-block">
          <div className="field-item">
            <label htmlFor="product-name">Internal Name</label>
            <input
              type="text"
              className="field-input"
              name="product-name"
              placeholder="Enter internal name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            {!name && <h5 className="red small-text">Please enter an internal name for the product!</h5>}
          </div>
          <div className="field-item">
            <SelectField
              name={"product-type"}
              label="Product Type"
              options={PRODUCT_TYPES}
              selectClasses="field-input"
              setField={setType}
              value={type}
            />
            {!type && <h5 className="red small-text">Please select a product type!</h5>}
          </div>
          <div className="field-item">
            <SelectField
              name={"product-status"}
              label="Product Status"
              options={PRODUCT_STATUSES}
              selectClasses="field-input small"
              setField={setTag}
              value={tag}
            />
            {!tag && <h5 className="red small-text">Please select the status of the product!</h5>}
          </div>
          <div className="field-item">
            <SelectField
              name={"linked-product"}
              label="Linked Product"
              options={
                type === "Repackage" ? productStore.linkedSkus : [{ text: "N/A", value: "" }]
              }
              selectClasses="field-input small"
              setField={setLinkedSku}
              value={linkedSku}
              disabled={type !== "Repackage"}
            />
          </div>
          <div className="button-wrapper padding">
            <div className="form-button w-button button-2" onClick={() => handleDiscard()}>
              Discard
            </div>
            <div className="form-button w-button button-2" onClick={() => handleCreate()}>
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CreateProduct);
