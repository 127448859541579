import React, { useCallback, useMemo, useState } from "react";
import CheckboxField from "../checkbox-field/CheckboxField";
import Table from "../table/Table";
import BoxedContent from "../boxed-content/BoxedContent";
import { observer } from "mobx-react-lite";
import { useStores } from "../../store";
import SelectField from "../select-field/SelectField";
import { ATTRIBUTE_OPTION_TYPES } from "../../utils/select.utils";
import TextBoxField from "../text-box-field/TextBoxField";
import { genericNameSchema, markupOrPriceSchema } from "../../utils/validation.utils";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import DeleteCell from "../table/DeleteCell";
import CheckboxSelectField from "../checkbox-select-field/CheckboxSelectField";

const ENABLE_MARKUP = ["Repackage", "Sina"];

const AttributeDetailTable = ({ attribute }) => {
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [optCount, setOptCount] = useState(0);
  const { productStore } = useStores();

  const hasMarkup = useMemo(
    () => ENABLE_MARKUP.find((e) => e === productStore.currentProduct.type),
    [productStore.currentProduct.type]
  );

  const addClick = useCallback(() => {
    setOptCount((count) => count + 1);
    attribute?.addOption(`New Option ${optCount}`);
    productStore.currentProduct.edited();
  }, [productStore.currentProduct, optCount, attribute]);

  const attrOptionsTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Option name",
      accessor: "name",
      Cell: (e) => {
        return (
          <div style={{ display: "inline-block" }}>
            <TextBoxField
              field={e.value}
              setField={(val) => productStore.currentProduct.edited()}
              onBlur={(val) => {
                attribute?.setOption(e.row.original.name, val);
              }}
              placeholder={"Option name"}
              resize="None"
              style={{ maxWidth: "250px" }}
              disabled={
                productStore.currentProduct.type === "Repackage" ||
                productStore.currentProduct.type === "Sina"
              } // TODO: When should they be able to edit original names?
              useDefaultValue
              validationSchema={genericNameSchema}
            />
          </div>
        );
      },
    },
    {
      Header: "Display name",
      accessor: "displayName",
      Cell: (e) => {
        return (
          <div style={{ display: "inline-block" }}>
            <TextBoxField
              field={e.value}
              setField={(val) => {
                productStore.currentProduct.edited();
                attribute?.setOptionDisplayName(e.row.original.name, val);
              }}
              placeholder={"Override supplier name"}
              resize="None"
              style={{ maxWidth: "250px" }}
              useDefaultValue
            />
          </div>
        );
      },
    },
    {
      Header: hasMarkup ? "Markup (%)" : "Price ($)",
      accessor: "price",
      Cell: (e) => {
        return (
          <div style={{ display: "inline-block" }}>
            <TextBoxField
              field={e.value}
              setField={(val) => {
                productStore.currentProduct.edited();
                attribute?.setOptionPrice(e.row.original.name, val);
              }}
              placeholder={`Set option ${hasMarkup ? "markup (%)" : "price ($)"}`}
              resize="None"
              style={{ maxWidth: "250px" }}
              useDefaultValue
              validationSchema={markupOrPriceSchema}
            />
          </div>
        );
      },
    },
    {
      Header: "Next Attribute(s)",
      // accessor: "progressiveOptions.nextAttributes",
      // genericOnly: true,
      restrictTo: (product) => product.type === "Generic" || product.type === "BookingService" || product.type === "Package",
      Cell: (e) => {
        console.log("Next attrib", e.row.original.name, e.row.original.nextAttributes);
        return (
            <CheckboxSelectField
              name="next-attributes"
              values={e.row.original.nextAttributes?.map(a => a.name)}
              setField={(attribNames) => {
                if (
                  JSON.stringify(attribNames) === 
                    JSON.stringify(e.row.original.nextAttributes?.map(a => a.name))
                ) { return; }
                console.log("Attribs", attribNames);
                attribute?.setOptionNextAttributes(e.row.original.name, attribNames);
                productStore.currentProduct.edited();
              }}
              options={
                productStore.currentProduct.attributes
                  .filter(a => a.name !== attribute.name)
                  .map(a => { return { text: a.name, value: a.name }})
              }
            />
        );
      }
    },
    {
      Header: "Visibility",
      accessor: "visibility",
      Cell: (e) => {
        return (
          <div style={{ display: "inline-block" }}>
            <CheckboxField
              name={e.cell.row.original}
              label={"Shown"}
              labelOff={"Hidden"}
              isChecked={attribute?.getOptionVisibility(e.cell.row.original)}
              setField={(val) => {
                attribute?.setOptionHidden(e.cell.row.original, val);
                productStore.currentProduct.edited();
              }}
              disabled={productStore.currentProduct.type === "Sina"}
            />
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (e) => (
        <DeleteCell
          action={() => {
            attribute?.removeOption(e.cell.row.original);
            productStore.currentProduct.edited();
          }}
        />
      ),
    },
  ];

  return (
    <>
      <BoxedContent title="Attribute Type">
        <SelectField
          name="select-attribute-type"
          value={attribute?.type}
          options={ATTRIBUTE_OPTION_TYPES}
          setField={(t, initial) => {
            attribute?.setType(t);
            if (!initial) productStore.currentProduct.edited();
          }}
          disabled={hasMarkup}
        />
      </BoxedContent>
      {attribute?.type === "dropdown" && (
        <Table
          tableName={"Attribute Options"}
          loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
          hasSearch={false}
          COLUMNS={
            // TODO: Better way of filtering columns so check doesn't happen everytime
            attrOptionsTableColumns.filter(
              col => {
                const isRestricted = col.restrictTo ? col.restrictTo(productStore.currentProduct) : false;
                console.log(col.Header, col.restrictTo);
                return col.restrictTo ? col.restrictTo(productStore.currentProduct) : true
              }
            )
          }
          rowResults={results}
          DATA={attribute?.options}
          draggable
          setData={(data) => {
            attribute?.setOptions(data);
            productStore.currentProduct.edited();
          }}
          // Can only add options to generic products
          createText={productStore.currentProduct.type === "Generic" && "Add Option"}
          createButtonClick={() => addClick()}
          includeComponent={
            <>
              {hasMarkup && (
                <>
                  <h5 className="heading-62" style={{ marginTop: "25px" }}>
                    Master Attribute Markup (%)
                  </h5>
                  <TextBoxField
                    field={attribute?.markup}
                    setField={(e) => {
                      attribute?.setMasterMarkup(e);
                      productStore.currentProduct.edited();
                    }}
                    placeholder={`Set Master Markup (%)`}
                    resize="None"
                    style={{ maxWidth: "250px" }}
                  />
                </>
              )}
            </>
          }
          noBottom={results >= attribute?.options.length}
          hiddenColumns={hasMarkup ? [] : ["rename"]}
        />
      )}
      {attribute?.type === "file" && (
        <BoxedContent title="Upload options">
          <div className="with-border" style={{ marginBottom: "20px" }}>
            <CheckboxField
              name="multiple-files"
              label="Are multiple files allowed?"
              isChecked={attribute?.multiple === true}
              setField={(multiple) => {
                attribute?.setMultipleFiles(multiple);
                productStore.currentProduct.edited();
              }}
              useIsChecked
            />
          </div>
          <div style={{ marginBottom: "20px"}}>
            <h5 className="heading-62">List of file types (comma-separated)</h5>
            <TextBoxField
              field={attribute?.fileTypes}
              placeholder="Enter a list of file types, separated by commas (ex. pdf,jpg,jpeg,png)"
              setField={(f) => {
                attribute?.setFileTypes(f);
                productStore.currentProduct.edited();
              }}
            />
          </div>
        </BoxedContent>
      )}
    </>
  );
};

export default observer(AttributeDetailTable);
