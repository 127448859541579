import React from "react";
import { observer } from "mobx-react-lite";
import CustomerTable from "../../components/customer-table/CustomerTable";
import ContentModule from "../../components/content-module/ContentModule";
import { useStores } from "../../store";
import { DollarSign, TagPurple, TrendingUp, UsersYellow } from "../../assets/images";

const Customers = () => {
  const { orderStore, clientStore } = useStores();

  return (
    <>
      <div className="dashboard-content-3">
        <div className="dashboard-container">
          <div className="w-layout-grid main-grid">
            <div className="w-layout-grid _4-grid">
              <ContentModule image={DollarSign} caption={`Average Order Value`} value={`N/A`} />
              <ContentModule
                image={TagPurple}
                caption={`Average # of Orders`}
                extraStyles={`bg-primary-purple`}
                value={orderStore.totalOrders} // will be 0 if data is not loaded beforehand
              />
              <ContentModule
                image={TrendingUp}
                caption={`Returning Customer Rate`}
                extraStyles={`bg-primary-orange`}
                value={"N/A"}
              />
              <ContentModule
                image={UsersYellow}
                caption={`Customers`}
                extraStyles={`bg-primary-yellow`}
                value={clientStore.totalClients}
              />
            </div>
          </div>
        </div>
      </div>

      <CustomerTable />
    </>
  );
};

export default observer(Customers);
