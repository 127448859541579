import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      Copyright &copy; 2017 - {new Date().getFullYear()} &#8226; Powered by Quintessential
      Technologies Inc. &#8226;{" "}
      <a href="/" className="footer-link-2">
        Licensing
      </a>
    </div>
  );
};

export default Footer;
