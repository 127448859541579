import { observer } from "mobx-react-lite";
import React from "react";
// import { useStores } from "../../store";
import Table from "../table/Table";

// TODO: More Columns
const OrderJobsTable = ({ order }) => {
  // const { routeStore } = useStores();

  const columns = [
    { Header: "Job", accessor: "service" },
    { Header: "Job ID", accessor: "id" },
    { Header: "# of Steps", accessor: "steps.length" },
    // {
    //   Header: "Details",
    //   accessor: "details",
    //   Cell: (e) => (
    //     <strong
    //       className="in-text-link"
    //       onClick={() => routeStore.newTab(`jobs/${e.row.original.id}`)}
    //     >
    //       View Job
    //     </strong>
    //   ),
    // },
  ];

  return (
    <Table
      tableName={"Jobs"}
      DATA={order?.jobs}
      COLUMNS={columns}
      rowResults={order?.jobs?.length || 1}
    />
  );
};

export default observer(OrderJobsTable);
