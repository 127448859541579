import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Table from "../table/Table";
import { useStores } from "../../store";
import DeleteCell from "../table/DeleteCell";
import AddDetailPopup from "../add-detail-popup/AddDetailPopup";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import { DETAIL_TOOLTIP } from "../../utils/string.utils";
import ReactTooltip from "react-tooltip";
import TextBoxField from "../text-box-field/TextBoxField";

const CloserLookTable = ({ product }) => {
  const { productStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [createOpen, setCreateOpen] = useState(false);

  //FIXME: Title and Description logic same as FAQ, Detail and Customer Review
  const closerLookTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.title}
                setField={(val) => {
                  e.cell.row.original.setTitle(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description Title"}
                style={{ minWidth: "90px", maxWidth: "250px" }}
                resize={"horizontal"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.description}
                setField={(val) => {
                  e.cell.row.original.setDescription(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description"}
                style={{ minWidth: "250px", width: "600px", maxWidth: "800px" }}
                resize={"both"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (cell) => (
        <DeleteCell
          action={() => productStore.currentProduct.removeFullCloserLook(cell.row.original)}
          disabled={!cell.row.original.editable}
          tooltip={!cell.row.original.editable ? DETAIL_TOOLTIP : ""}
        />
      ),
    },
  ];

  return (
    <>
      <AddDetailPopup type={"CloserLook"} show={createOpen} setShow={(e) => setCreateOpen(e)} />
      <Table
        tableName={"A Closer Look at the Process"}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        createText={!productStore.currentProduct.name && "Add"}
        createButtonClick={() => setCreateOpen(true)}
        COLUMNS={closerLookTableColumns}
        rowResults={results}
        DATA={productStore.currentProduct.fullCloserLooks}
        noBottom={results >= productStore.currentProduct.fullCloserLooks.length}
      />
    </>
  );
};

export default observer(CloserLookTable);
