import { titleCase } from "../../utils/string.utils";
import { format } from "date-fns";
import StatusText, { determineColor } from "../status-text/StatusText";

export const orderTableColumns = [
  {
    Header: "ID",
    // accessor: "raw.id", // accessor is the "key" in the data
    accessor: "_id",
    Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "Store",
    accessor: "store", // need some kind of enum or something with stores to show name instead of number
  },
  {
    Header: "First Name",
    accessor: "raw.billing.first_name",
    // Cell: (e) => `${e.value.billing.first_name} ${e.value.billing.last_name}`,
    // It would seem rendering the cell like this causes search errors?
  },
  {
    Header: "Last Name",
    accessor: "raw.billing.last_name",
  },
  {
    Header: "Payment Status",
    accessor: "raw.status",
    Cell: (e) => {
      return (
        <StatusText
          text={titleCase(e.value?.replace(/-+/g, " "))}
          color={determineColor(e.value)}
        />
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (e) => {
      return (
        <StatusText
          text={titleCase(e.value?.replace(/-+/g, " ")) || "None"}
          color={determineColor(e.value)}
        />
      );
    },
  },
  {
    Header: "Total",
    accessor: `raw.total`,
    Cell: (e) => `${e.row.original.raw.currency_symbol}${e.value}`,
  },
  {
    Header: "Date",
    accessor: "raw.date_created_gmt",
    Cell: (e) => format(new Date(e.value + "Z"), "PPpp"),
  },
];
