import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import OrderTable from "../../components/order-table/OrderTable";
import AlertModule from "../../components/alert-module/AlertModule";
import ContentModule from "../../components/content-module/ContentModule";
import { useStores } from "../../store";
import { DollarSign, TagPurple, Truck, UsersYellow } from "../../assets/images";

const Orders = () => {
  const { orderStore, uiStateStore, clientStore } = useStores();

  useEffect(() => {
    clientStore.loadClients();
  }, []);

  return (
    <>
      {/* <ChangeBanner handleDiscard={() => uiStateStore.setShowChangeBanner(false)} /> */}
      <div className="dashboard-content-3">
        <div className="dashboard-container">
          <div className="w-layout-grid main-grid">
            {/* <AlertModule
              message={`${orderStore.totalOrders} to be dispatched.`}
              open={uiStateStore.orderAlertOpen}
              setOpen={(o) => uiStateStore.setOrderAlertOpen(o)}
            /> */}
            <div className="w-layout-grid _4-grid">
              <ContentModule image={DollarSign} caption={`Revenue`} value={`N/A`} />
              <ContentModule
                image={TagPurple}
                caption={`Orders`}
                extraStyles={`bg-primary-purple`}
                value={orderStore.totalOrders}
              />
              <ContentModule
                image={Truck}
                caption={`Deliveries`}
                extraStyles={`bg-primary-orange`}
                value={`N/A`}
              />
              <ContentModule
                image={UsersYellow}
                caption={`Customers`}
                extraStyles={`bg-primary-yellow`}
                value={clientStore.totalClients}
              />
            </div>
          </div>
        </div>
      </div>

      <OrderTable />
    </>
  );
};

export default observer(Orders);
