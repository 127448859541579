import React, { useState } from "react";
import CheckboxField from "../checkbox-field/CheckboxField";
import StoreVisibility from "../store-visibility/StoreVisibility";
import { useStores } from "../../store";
import Table from "../table/Table";
import ReplaceCell from "../table/ReplaceCell";
import DeleteCell from "../table/DeleteCell";
import FileUpload from "../file-upload-field/FileUpload";
import { getLastItemInUrl, isImage, NO_RESULTS_TEXT, NO_RESULTS_TEXT_INHERITS } from "../../utils/string.utils";
import { observer } from "mobx-react-lite";
import { MediaNotFound } from "../../assets/images";

const CoverMediaTable = ({ title, product }) => {
  const { shopStore } = useStores();
  const [replace, setReplace] = useState(false);
  const [add, setAdd] = useState(false);
  const filterFn = c => c.store === shopStore.currentStore;
  console.log("\"Product\"", product);

  const coverMediaTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Media",
      accessor: "url",
      Cell: (e) => {
        const image = isImage(e.value);
        return (
          <div className="div-block-57">
            {image && (
              <img
                loading="lazy"
                src={e.value}
                alt=""
                className="image-18"
                onError={(e) => (e.currentTarget.src = MediaNotFound)}
              />
            )}
            {!image && (
              <video
                src={e.value}
                className="image-18"
                controls
                onError={(e) => (e.currentTarget.poster = MediaNotFound)}
              >
                <h5 className="red small-text">Could not Load Media</h5>
              </video>
            )}
          </div>
        );
      },
      disableSortBy: true,
    },
    {
      Header: "Name",
      // TODO: Change this once image format is reshaped
      accessor: (data) => data.name ?? getLastItemInUrl(data.url),
    },
    {
      Header: "Media Visibility",
      accessor: "visible",
      Cell: (e) => {
        return (
          <div style={{ display: "inline-block" }}>
            <CheckboxField
              name="media-enabled"
              isChecked={e.value}
              setField={(s) =>
                product.setCover({
                  url: e.row.original.url,
                  store: e.row.original?.store,
                  visible: s,
                })
              }
              label={"Show"}
              labelOff={"Hide"}
              useIsChecked
            />
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "replace",
      disableSortBy: true,
      Cell: (e) => (
        <ReplaceCell
          action={() => {
            setReplace(true);
          }}
        />
      ),
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (e) => <DeleteCell action={() => product.removeCoverByUrl(e.row.values.url)} />,
    },
  ];

  return (
    <>
      <FileUpload
        name={"replace"}
        clicked={replace}
        resetClicked={() => setReplace(false)}
        handleUpload={(e) => product.setCover(e)}
      />
      <FileUpload
        name={"add"}
        clicked={add}
        resetClicked={() => setAdd(false)}
        handleUpload={(url) => product.setCover(url)}
      />

      <Table
        tableName={`${title || "Cover Media"} (${shopStore.currentStoreName})`}
        loadMore={() => null}
        hasSearch={false}
        // createText={"Create New Set"}
        // createButtonClick={() => null}
        COLUMNS={coverMediaTableColumns}
        // includeComponent={<StoreVisibility stores={[]} updateStores={(bool, store) => null} />} // TODO: Update Visibility
        rowResults={1}
        DATA={product.covers.filter(filterFn)}
        noResultsText={shopStore.currentStore === "0" ? NO_RESULTS_TEXT : NO_RESULTS_TEXT_INHERITS}
        draggable
        setData={(data) => null}
        buttons={[
          {
            text: "Add Media",
            onClick: () => setAdd(true),
            disabled: product.covers.filter(filterFn).length > 0,
          },
        ]}
        noBottom
      />
    </>
  );
};

export default observer(CoverMediaTable);
