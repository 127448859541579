import React, { useEffect, useMemo } from "react";
import BoxedContent from "../boxed-content/BoxedContent";
import {
  Home,
  Mail,
  MapPin,
  Smartphone,
  TempAvatar,
  TrendingUp,
  UsersPurple,
} from "../../assets/images";
import { useStores } from "../../store";
import { observer } from "mobx-react-lite";
import { formatPhoneNumber } from "../../utils/string.utils";
import TextBoxField from "../text-box-field/TextBoxField";
import Button from "../button/Button";
import SelectField from "../select-field/SelectField";
import { CUSTOMER_TYPES } from "../../utils/select.utils";

const AddressSection = ({ type, info }) => {
  const { routeStore } = useStores();
  const isBilling = useMemo(() => type === "billing", [type]);

  return isBilling && info?.shipping?.compare(info?.raw?.billing) ? (
    <div
      className="table-column table-column--title table-column--padding-none padding-16px no-border"
      style={{ border: "1px none" }}
    >
      <div className="customer-content">
        <h4 className="heading-60">Billing Address</h4>
        <div className="horizontal-wrapper">
          <div className="large-text">Same as Shipping Address</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="table-column table-column--title table-column--padding-none padding-16px">
      <div className="customer-content">
        <h4 className="heading-60">{`${isBilling ? "Billing" : "Shipping"} Address`}</h4>
        <div className="horizontal-wrapper _4px">
          <div className="large-text">
            {isBilling ? info?.raw?.billing?.customerFullName : info?.shipping?.customerFullName}
          </div>
        </div>
        <div className="horizontal-wrapper _4px">
          <div className="large-text">
            {isBilling
              ? formatPhoneNumber(info?.raw?.billing?.phone)
              : formatPhoneNumber(info?.shipping?.phone)}
          </div>
        </div>
        <div className="horizontal-wrapper _4px">
          <div className="large-text">
            {isBilling ? info?.raw?.billing?.company || "N/A" : info?.shipping?.company || "N/A"}
          </div>
        </div>
        <div className="horizontal-wrapper _4px">
          <div className="large-text">
            {isBilling ? info?.raw?.billing?.address : info?.shipping?.address}
          </div>
        </div>
        <div className="horizontal-wrapper _4px">
          <div className="large-text">
            {isBilling ? info?.raw?.billing?.location : info?.shipping?.location}
          </div>
        </div>
        {(info?.raw || info?.shipping) && (
          <div className="horizontal-wrapper _4px">
            <div className="large-text">
              <div
                className="in-text-link"
                onClick={(e) =>
                  routeStore.newTab(
                    `https://maps.google.com/?q=${
                      isBilling
                        ? info?.raw?.billing?.fullAddressLine
                        : info?.shipping?.fullAddressLine
                    }`
                  )
                }
              >
                View on map
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CustomerInformation = ({ info, client }) => {
  const { orderStore, routeStore } = useStores();
  // useEffect(() => {
  //   if (info?.client?._id)
  //     orderStore.loadOrders("", `limit=100&search[client]=${orderStore.currentOrder.client._id}`);
  // }, []);

  return (
    <>
      <BoxedContent title={"Account Information"}>
        <div style={{ marginBottom: "20px" }}></div>
        {/* TODO: actual field from client info */}
        <h3 className="heading-65 underline">Account Notes</h3>
        <TextBoxField
          placeholder={"Enter account notes..."}
          field={info?.raw?.customer_note || "Not implemented"}
          disabled
        />

        { client && <>
            <h3 className="heading-65 underline">Account Type</h3>
            <SelectField
              name={"account-type"}
              options={CUSTOMER_TYPES}
              setField={(e) => client?.setType(e)}
              value={client?.type}
              disabled={client?.hasSigned}
            />
            <div style={{ marginBottom: "20px" }}></div>
          </> }

        {
          (client?.type === "pro" || client?.type === "team") && <>
            <h3 className="heading-65 underline">
              Service Commitment
              {client?.hasSigned && " (Client has already signed)"}
            </h3>
            <TextBoxField
              /** TODO: Update on focus lost */
              placeholder={"Enter service commitment..."}
              field={client?.commitment}
              setField={(val) => client?.setCommitment(val)}
              onBlur={(val) => client?.setCommitment(val, true)}
              disabled={client?.hasSigned}
              tooltip={client?.hasSigned ? "Client has already signed." : undefined}
            />
          </>
        }
        {
          (client?.type === "pro" || client?.type === "team") && <>
            <h3 className="heading-65 underline">
              Term
            </h3>
            <TextBoxField
              placeholder={"Enter term..."}
              field={client?.term}
              setField={(val) => client?.setTerm(val)}
              onBlur={(val) => client?.setTerm(val, true)}
              disabled={client?.hasSigned}
              tooltip={client?.hasSigned ? "Client has already signed." : undefined}
            />
          </>
        }

      </BoxedContent>
      <BoxedContent>
        {/* Customer Avatar and Buttons */}
        <div className="table-column table-column--title table-column--padding-none">
          <div className="vertical-wrapper">
            <h2 className="no-space-bottom-2 padding">Customer Information</h2>
            { /* Information may be incorrect since it uses the current order details - which may not be the customer's order. */ }
            <h5 style={{ color: "red" }}>Developer notice: information may or may not be correct!</h5>
            <div style={{ marginBottom: "20px" }}></div>

            <div className="horizontal-wrapper">
              <div className="user-details">
                {/* TODO: Actual Avatar */}
                {/* <img loading="lazy" src={TempAvatar} alt="" className="user-avatar" /> */}
                <div>
                  <h5 className="heading-65 underline">
                    {info?.shipping?.customerFullName || info?.fullName}
                  </h5>
                  {/* TODO: Dynamic Order Number */}
                  <div className="small-text text-grey-3 underline">{`${orderStore.totalOrders} Order(s)`}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {/* TODO: Button Clicks */}
            <Button
              text="View Profile"
              action={() => null}
              classes="button-2 not-center w-button"
              disabled
            />
            <Button
              text="View Google Drive"
              action={() => routeStore.newTab(client?.contentUrl) }
              classes="button-2 not-center w-button"
              disabled={client?.contentUrl !== undefined}
            />
          </div>
        </div>
        {/* Contact Info */}
        <div className="table-column table-column--title table-column--padding-none padding-16px">
          <div className="customer-content">
            <h4 className="heading-60">Contact Information</h4>
            <div className="horizontal-wrapper _4px">
              <img width="20" loading="lazy" src={Mail} alt="" className="icon-right-space" />
              <div className="large-text">{info?.raw?.billing?.email || info?.email}</div>
            </div>
            <div className="horizontal-wrapper _4px">
              <img width="20" loading="lazy" src={Smartphone} alt="" className="icon-right-space" />
              <div className="large-text">
                {formatPhoneNumber(info?.raw?.billing?.phone || info?.phoneNumber)}
              </div>
            </div>
            {/* <div className="horizontal-wrapper _4px">
              <img width="20" loading="lazy" src={PhoneCall} alt="" className="icon-right-space" />
              <div className="large-text">333-444-5555</div>
            </div> */}
            <div className="horizontal-wrapper _4px">
              <img
                width="20"
                loading="lazy"
                src={UsersPurple}
                alt=""
                className="icon-right-space"
              />
              <div className="large-text">{info?.raw?.billing?.company || "N/A"}</div>
            </div>
            <div className="horizontal-wrapper _4px">
              <img width="20" loading="lazy" src={MapPin} alt="" className="icon-right-space" />
              {/* TODO: Map Pin and Trending Up */}
              <div className="large-text">{"N/A"}</div>
            </div>
            <div className="horizontal-wrapper _4px">
              <img width="20" loading="lazy" src={TrendingUp} alt="" className="icon-right-space" />
              <div className="large-text">{"N/A"}</div>
            </div>
          </div>
        </div>
        <AddressSection heading={"shipping"} info={info} />
        <AddressSection type={"billing"} info={info} />
      </BoxedContent>
    </>
  );
};

export default observer(CustomerInformation);
