import React from "react";
import SelectField from "../select-field/SelectField";

const SearchTableDropdown = ({ searchFields, filterBy, setFilterBy }) => {
  return (
    <div className="w-dropdown">
      <SelectField
        name="product-table-dropdown"
        selectClasses={"dropdown-toggle-6 search w-dropdown-toggle"}
        options={searchFields}
        value={filterBy}
        setField={(val) => setFilterBy(val)}
      />
    </div>
  );
};

export default SearchTableDropdown;
