import React, { useEffect, useState } from "react";
import "./styles.css";
import CheckboxField from "../checkbox-field/CheckboxField";
import SelectField from "../select-field/SelectField";

// Ref: https://stackoverflow.com/a/27547021
/** A dropdown with checkboxes for selecting multiple options. */
const CheckboxSelectField = ({
  name,
  label,
  values = [],
  options,
  selectClasses,
  setField,
  disabled = false,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ internalValues, setInternalValues ] = useState(values);
  const [ valueCount, setValueCount ] = useState(values.length);
  useEffect(() => {
    setField(internalValues);
  }, [internalValues]);
  return (
    <div className="dropdown-container checkbox-select-container">
      {label && (
        <label htmlFor={name} className="field-label">
          {label}
        </label>
      )}
      <div class="select-container" onClick={() => setIsOpen(o => !o)}>
        <SelectField
          options={[{ text: `${internalValues.length} value${internalValues.length !== 1 ? "s" : ""}` }]}
          onMouseDown={(e) => e.preventDefault()}
        />
        {/* <div class="over-select"></div> */}
      </div>
      
        <div
          data-name={name}
          id={name}
          className={selectClasses || "dropdown-toggle-7 header w-dropdown-toggle checkbox-select"}
          style={{ display: isOpen ? "block" : "none", ...(disabled && { cursor: "not-allowed" }) }}
        >
          {options.map(o => {
            return (
              <CheckboxField
                name={o.value}
                label={o.text}
                setField={(checked) => {
                  setInternalValues(a => {
                    const valueExists = a.indexOf(o.value);
                    if (checked) {
                      a.push(o.value);
                    } else if (!checked && valueExists !== -1) {
                      a.splice(valueExists, 1);
                    }
                    a = Array.from(new Set(a));
                    console.log("Internal", a);
                    // setField(a);
                    return a;
                  });
                  // let newValues = [ ...internalValues ];
                  // const valueIdx = newValues.indexOf(o.value);
                  // if (checked) {
                  //   newValues.push(o.value);
                  // } else if (!checked && valueIdx !== -1) {
                  //   newValues.splice(valueIdx, 1);
                  // }
                  // newValues = Array.from(new Set(newValues));
                  // setField(internalValues);
                }}
                // setField={}
                isChecked={values.find(v => {
                  return v === o.value
                })}
              />
            );
          })}
        </div>
      
    </div>
  );
};

export default CheckboxSelectField;
