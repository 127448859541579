import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Table from "../table/Table";
import { useStores } from "../../store";
import { userTableColumns } from "./userTableColumns";
import { RouteStore } from "../../store/routeStore";

const UserTable = () => {
  const { uiStateStore, userStore, routeStore } = useStores();
  const rowResults = 25;

  const tabs = [
    { heading: "All Users" },
    // { heading: "Staff" },
    // { heading: "Admin" },
    { heading: "Service Pro" },
    // { heading: "Partner" },
  ];

  function nextPage(pageNumber) {
    userStore.setCurrentPage(pageNumber);
  }

  useEffect(() => {
    userStore.loadUsers();
  }, []);

  // FIXME: Temporary service pro filter
  return (
    <div className="dashboard-container-2">
      <Table
        tabNames={tabs}
        tableName={"Users"}
        COLUMNS={userTableColumns}
        DATA={
          uiStateStore.userTableTab === 2
            ? userStore.users.filter((user) => user?.type === "ServicePro")
            : userStore.users
        }
        // pages={userStore.totalPages}
        // nextPage={nextPage}
        // currentPage={userStore.currentPage}
        rowResults={rowResults}
        setTabIndex={(e) => uiStateStore.setUserTableTab(e)}
        currentTab={uiStateStore.userTableTab}
        loading={uiStateStore.fetchingUserContent}
        // createText={"Add New"}
        rowClick={(cells) => {
          routeStore.redirect(`/users/${cells[1].value}`);
        }}
      />
    </div>
  );
};

export default observer(UserTable);
