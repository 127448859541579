export const DETAIL_TOOLTIP = "Cannot Edit: Inherited from product type";
export const NO_RESULTS_TEXT_INHERITS = "No rows found - will use content from \"All Stores\" instead"
export const NO_RESULTS_TEXT = "No rows found";

export const titleCase = (str) => {
  if (!str) return "";
  return str
    ?.toLowerCase()
    ?.split(" ")
    ?.map(function (word) {
      return word?.replace(word[0], word[0]?.toUpperCase());
    })
    ?.join(" ");
};

export const createSkuFromName = (str) => {
  if (!str) return "";
  return str?.trimStart()?.toLowerCase()?.replace(/\s+/g, "-");
};

export const getLastItemInUrl = (str) => {
  if (!str) return "";
  if (str.url) str = str.url;
  return str?.substring(str?.lastIndexOf("/") + 1);
};

export const sentenceCase = (str) => {
  if (!str) return "";
  return titleCase(str?.replace(/([A-Z]+)/g, " $1")?.replace(/([A-Z][a-z])/g, " $1"));
};

export const removeKebab = (str) => {
  if (!str) return "";
  return str?.replace(/(-|_)+/g, " ");
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};

export const cleanRegex = (string) => {
  if (!string) return "";
  return string?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const isImage = (str) => {
  if (!str) return false;
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(str);
};
