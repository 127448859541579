import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useBlocker from "../../hooks/useBlocker";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import { useStores } from "../../store";

const ChangeDialog = ({ isBlocking, discardChanges }) => {
  const title = "Unsaved Changes";
  const message = "You have unsaved changes. Are you sure you want to leave?";
  const { routeStore } = useStores();

  function useCallbackPrompt(when) {
    const location = useLocation();
    const navigate = useNavigate();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
      setShowPrompt(false);
    }, []);

    const handleBlockedNavigation = useCallback(
      (nextLocation) => {
        if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
          setShowPrompt(true);
          setLastLocation(nextLocation);
          return false;
        }
        return true;
      },
      [confirmedNavigation]
    );

    const confirmNavigation = useCallback(() => {
      discardChanges();
      setShowPrompt(false);
      setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        navigate(lastLocation.location.pathname);
      }
    }, [confirmedNavigation, lastLocation]);

    useBlocker(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
  }

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isBlocking);

  return (
    <ConfirmModal
      open={showPrompt}
      title={title}
      message={message}
      cancelText={"Cancel"}
      confirmText={"Leave"}
      cancelAction={cancelNavigation}
      confirmAction={confirmNavigation}
    />
  );
};

export default ChangeDialog;
