import React from "react";
import "./styles.css";

const AGENT_DATABASE_URL = "https://realtystore.info";

const AgentDatabase = () => {
  return (
    <iframe className="agent-db-window" src={`${AGENT_DATABASE_URL}/`} title="Agent Database">
      Your Browser does not support iframes.
    </iframe>
  );
};

export default AgentDatabase;
