import React, { useEffect, useState } from "react";
import Table from "../../../../components/table/Table";
import StatusText, { determineColor } from "../../../../components/status-text/StatusText";
import { useStores } from "../../../../store";
import { observer } from "mobx-react-lite";
import { sentenceCase } from "../../../../utils/string.utils";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../../../utils/number.utils";

const Products = ({ product }) => {
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const { productStore, routeStore } = useStores();
  useEffect(() => {
    productStore.loadProducts(product.type, `search[type]=^${product.type}$&limit=${results}`);
  }, [product.type, results]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => <StatusText color={determineColor(e.value)} text={e.value} />,
    },
  ];
  return (
    <>
      <Table
        tableName={`${sentenceCase(product.type)} Products`}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        hasSearch={false}
        COLUMNS={columns}
        rowResults={results}
        DATA={productStore.products}
        rowClick={(cells) => routeStore.newTab(`/products/${encodeURIComponent(cells[0].value)}`)}
        noBottom={results >= productStore.totalProducts}
      />
    </>
  );
};

export default observer(Products);
