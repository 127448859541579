import { observer } from "mobx-react-lite";
import React from "react";
import BoxedContent from "../../../../components/boxed-content/BoxedContent";
import CloserLookTable from "../../../../components/closer-look-table/CloserLookTable";
import CoverMediaTable from "../../../../components/cover-media-table/CoverMediaTable";
import CustomerReviewTable from "../../../../components/customer-review-table/CustomerReviewTable";
import DetailedDescriptionTable from "../../../../components/detailed-description-table/DetailedDescriptionTable";
import FaqTable from "../../../../components/faq-table/FaqTable";
import InspiredTrendsTable from "../../../../components/inspired-trends-table/InspiredTrendsTable";
import TextBoxField from "../../../../components/text-box-field/TextBoxField";

const Information = ({ product }) => {
  return (
    <>
      <BoxedContent title={"Internal Notes"}>
        <TextBoxField
          field={product.notes}
          setField={(e) => product.setNotes(e)}
          placeholder={"Enter product internal notes..."}
        />
      </BoxedContent>

      <BoxedContent title={"Product Information"}>
        <TextBoxField
          title={"Product Type name"}
          field={product.type}
          style={{ maxWidth: "300px" }}
          disabled
          resize="none"
        />
      </BoxedContent>

      <DetailedDescriptionTable product={product} />

      <InspiredTrendsTable product={product} />

      <CloserLookTable product={product} />

      {/* FIXME: Only one that doesn't prop drill lol */}
      <FaqTable />

      <CustomerReviewTable product={product} />

      <CoverMediaTable title="Default Cover Media" product={product} />
    </>
  );
};

export default observer(Information);
