import { observer } from "mobx-react-lite";
import React from "react";
import CoverMediaTable from "../../../components/cover-media-table/CoverMediaTable";
import MediaSetTable from "../../../components/cover-media-table/MediaSetTable";

const Webpage = ({ product }) => {
  return (
    <>
      {/* Having two HTML5Backends at the same time causes critical error. Just change to react-beautiful-dnd :D */}
      <CoverMediaTable product={product} />
      <MediaSetTable product={product} />
    </>
  );
};

export default observer(Webpage);
