export const PRODUCT_STATUSES = [
  { text: "Select Product Status", value: "", placeholder: true },
  { text: "Active", value: "Active" },
  { text: "Inactive", value: "Inactive" },
];

export const PRODUCT_TYPES = [
  { text: "Select Product Type", value: "", placeholder: true },
  { text: "Repackaged", value: "Repackage" },
  { text: "Generic", value: "Generic" },
  // { text: "Supplier", value: "Sina" }, // Sina products cannot be created
  { text: "Booking", value: "BookingService" },
  { text: "Design", value: "DesignService" },
  { text: "Quote", value: "Quote" },
  { text: "Package", value: "Package" },
  { text: "Add-on Service", value: "AddonService" },
  { text: "Package Set", value: "PackageSet" },
];

export const CUSTOMER_TYPES = [
  { text: "Free", value: "free" },
  { text: "Pro", value: "pro" },
  { text: "Team", value: "team" },
];

export const ATTRIBUTE_STATUSES = [
  { text: "Select Status", value: "", placeholder: true },
  { text: "Active", value: "true" },
  { text: "Inactive", value: "false" },
];

export const ATTRIBUTE_OPTION_TYPES = [
  { text: "Dropdown", value: "dropdown" },
  { text: "Text", value: "text" },
  { text: "Number", value: "number" },
  { text: "Email", value: "email" },
  { text: "File", value: "file" },
  { text: "Long text", value: "textarea" },
  { text: "Address", value: "address" }
];

export const STATUS_COLORS = [
  { status: "on-hold", statusColor: "grey" },
  { status: "active", statusColor: "green" },
  { status: "in-progress", statusColor: "yellow" },
  { status: "inactive", statusColor: "rose" },
  { status: "paid", statusColor: "green" },
  { status: "refunded", statusColor: "orange" },
  { status: "processing", statusColor: "blue" },
];

export const PRODUCT_SEARCH_FIELDS = [
  { text: "Name", value: "name" },
  { text: "Status", value: "status" },
];

export const USER_STATUS_FIELDS = [
  { text: "Active", value: "active" },
  { text: "Inactive", value: "inactive" },
];

export const COLLECTION_SEARCH_FIELDS = [{ text: "Name", value: "name" }];

export const ORDER_SEARCH_FIELDS = [
  { text: "First Name", value: "raw.billing.first_name" },
  { text: "Last Name", value: "raw.billing.last_name" },
  { text: "Store", value: "store" },
  { text: "Payment Status", value: "raw.status" },
  { text: "Order Status", value: "status" },
];

export const CUSTOMER_SEARCH_FIELDS = [
  { text: "First Name", value: "firstName" },
  { text: "Last Name", value: "lastName" },
  { text: "Email", value: "email" },
  { text: "Phone", value: "phoneNumber" },
];

export const SORT_TYPE = {
  sortBy: "",
  sortDirection: "",
};
