import { SHOP_DETAILS } from "../../utils/shops.utils";
import { formatPhoneNumber } from "../../utils/string.utils";

export const customerTableColumns = [
  {
    Header: "ID",
    accessor: "_id", // accessor is the "key" in the data
  },
  {
    Header: "Store",
    accessor: "store",
    Cell: (id) => SHOP_DETAILS.find(s => s.id === id.value)?.name ?? SHOP_DETAILS[0].name
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phoneNumber",
    Cell: (data) => formatPhoneNumber(data.value),
  },
  // {
  //   Header: "Location",
  //   accessor: "location",
  // },
  // {
  //   Header: "Account Status",
  //   accessor: "status",
  // },
  // {
  //   Header: "# of Orders",
  //   accessor: "totalOrders",
  // },
  // {
  //   Header: "Spend",
  //   accessor: "amountSpent",
  // },
];
