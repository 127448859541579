import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import DetailHeader from "../../components/detail-header/DetailHeader";
import TabbedContent from "../../components/tabbed-content/TabbedContent";
import Booking from "./settings-tabs/Booking";

const Settings = () => {
  const [tab, setTab] = useState(1);

  return (
    <div className="dashboard-container-2">
      <DetailHeader title={"Settings"} />
      <div className="dashboard-container-2">
        <TabbedContent currentTab={tab} setTabIndex={(t) => setTab(t)}>
          <Booking label="Booking" />
        </TabbedContent>
      </div>
    </div>
  );
};

export default observer(Settings);
