import StatusText, { determineColor } from "../status-text/StatusText";

export const collectionTableColumns = [
  {
    Header: "ID",
    accessor: "_id", // accessor is the "key" in the data
    Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "# Of Products",
    accessor: "products.length",
    clientSideSort: true, // TODO: Not sortable on server-side, only sorts on page
  },
  {
    Header: "Status",
    accessor: "enabled",
    Cell: (e) => {
      return (
        <StatusText
          text={e.value ? "Active" : "Inactive"}
          color={determineColor(e.value ? "Active" : "Inactive")}
        />
      );
    },
  },
];
