import { makeAutoObservable } from "mobx";
import { get } from "../utils/api";
import { SORT_TYPE } from "../utils/select.utils";
import { Order } from "./domain/order";

const ORDER_URL = "orders/";
const STATUS_URL = "&search[status]=^";

export class OrderStore {
  orders = [];

  totalPages = 0;

  currentPage = 1;

  ordersStatus = "";

  totalOrders = 0;

  currentOrder = new Order();

  // orderSnapshot = new Order();

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadOrders(status = "", rawSearch, sort = SORT_TYPE) {
    this.rootStore.uiStateStore.setFetchingOrderContent(true);

    if (this.ordersStatus !== status) this.currentPage = 1;

    this.ordersStatus = status;

    const baseUrl = `${ORDER_URL}?page=${this.currentPage}${
      sort.sortBy ? `&sort[${sort.sortBy}]=${sort.sortDirection}` : ""
    }`;
    const fetchUrl = rawSearch
      ? `${baseUrl}&${rawSearch}`
      : status === ""
      ? baseUrl + "&limit=25"
      : baseUrl + STATUS_URL + this.ordersStatus + "$&limit=25";

    try {
      const res = await get(fetchUrl);
      if (!this.rootStore.uiStateStore.fetchingOrderContent) return;
      console.log(res);
      this.setProps(res);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingOrderContent(false);
      this.rootStore.notificationStore.createNotification(
        `ERROR`,
        "Could not load orders!",
        "error"
      );
    }
  }

  resetOrders() {
    this.currentOrder = new Order();
    this.orderSnapshot = new Order();
  }

  async getOrder(store, id) {
    this.rootStore.uiStateStore.setFetchingCurrentOrder(true);
    this.resetOrders();
    try {
      const res = await get(`${ORDER_URL}${store}/${id}`);
      // console.log(res);
      this.currentOrder.fromObject(res);
      // this.orderSnapshot.fromObject(res);
      console.log(this.currentOrder);
      this.rootStore.uiStateStore.setFetchingCurrentOrder(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingCurrentOrder(false);
      this.rootStore.routeStore.redirect(`/orders`);
    }
  }

  setProps(res) {
    this.orders = res.orders;
    this.totalPages = res.totalPages;
    this.totalOrders = res.totalOrders;
    this.rootStore.uiStateStore.setFetchingOrderContent(false);
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }
}
