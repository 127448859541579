import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";
import { addOnTableColumns } from "./addOnTableColumns";

const AddOnTable = () => {
  const { metadataStore, routeStore, uiStateStore } = useStores();

  useEffect(() => {
    metadataStore.loadAddons();
  }, []);

  return (
    <div className="dashboard-container-2">
      <Table
        tableName={"Add-ons"}
        COLUMNS={addOnTableColumns}
        DATA={metadataStore.addons}
        rowResults={25}
        loading={uiStateStore.fetchingAddonContent}
        createText={"Add New"}
        createButtonClick={() => routeStore.redirect(`/product-add-ons/new`)}
        rowClick={(cells) => routeStore.redirect(`/product-add-ons/${cells[0].row.original._id}`)}
      />
    </div>
  );
};

export default observer(AddOnTable);
