import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";
import { collectionTableColumns } from "./collectionTableColumns";
import CollectionTableDropdown from "./CollectionTableDropdown";
import { v4 as uuidv4 } from "uuid";

const CollectionTable = () => {
  const { collectionStore, routeStore, uiStateStore } = useStores();
  const rowResults = 25;

  function nextPage(pageNumber) {
    collectionStore.setCurrentPage(pageNumber);
  }

  useEffect(() => {
    collectionStore.loadCollections(
      uiStateStore.collectionTableFilterText
        ? `search[${uiStateStore.collectionTableFilterBy}]=${uiStateStore.collectionTableFilterText}`
        : "",
      uiStateStore.collectionTableSort
    );
  }, [
    uiStateStore.collectionTableFilterBy,
    uiStateStore.collectionTableFilterText,
    collectionStore.currentPage,
    uiStateStore.collectionTableSort,
  ]);

  return (
    <div className="dashboard-container-2">
      <Table
        tableName={"Collections"}
        COLUMNS={collectionTableColumns}
        DATA={collectionStore.collections}
        pages={collectionStore.totalPages}
        nextPage={nextPage}
        currentPage={collectionStore.currentPage}
        rowResults={rowResults}
        setTabIndex={(e) => {
          return e;
        }}
        loading={uiStateStore.fetchingCollectionContent}
        createText={"Add New"}
        createButtonClick={() => routeStore.redirect(`/collections/${uuidv4()}?new=true`)}
        rowClick={(cells) => routeStore.redirect(`/collections/${cells[1].value}`)}
        selectField={<CollectionTableDropdown />}
        filter={uiStateStore.collectionTableFilterText}
        setFilter={(text) => uiStateStore.setCollectionTableFilterText(text)}
        sort={uiStateStore.collectionTableSort}
        setSort={(sort) => uiStateStore.setCollectionTableSort(sort)}
      />
    </div>
  );
};

export default observer(CollectionTable);
