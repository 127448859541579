export const propertyTableColumns = [
  {
    Header: "ID",
    accessor: "id", // accessor is the "key" in the data
    Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Client",
    accessor: "client",
  },
  {
    Header: "Date Created",
    accessor: "date",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
