import { observer } from "mobx-react-lite";
import React, { useState, useMemo } from "react";
import CheckboxField from "../checkbox-field/CheckboxField";
import Table from "../table/Table";
import { useStores } from "../../store";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import { Attribute } from "../../store/domain/product-metadata/attribute";
import DeleteCell from "../table/DeleteCell";

const ProductAttributeTable = ({ product, isDuplicate }) => {
  const { productStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  // const productUrl = useMemo(
  //   () => `products/${encodeURIComponent(productStore.currentProduct.name)}`,
  //   [productStore.currentProduct.name]
  // );
  // const productTypeUrl = useMemo(
  //   () => `product-types/${encodeURIComponent(productStore.currentProduct.type)}`,
  //   [productStore.currentProduct.type]
  // );

  const prodAttributeTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Internal Name",
      accessor: "name",
      Cell: (e) =>
        // !isDuplicate ? (
        //   <span
        //     className="cell-link"
        //     onClick={() =>
        //       routeStore.redirect(
        //         `${
        //           productStore.currentProduct.name ? productUrl : productTypeUrl
        //         }/${encodeURIComponent(e.value)}`
        //       )
        //     }
        //   >
        //     <strong className="in-text-link">{e.value || "NOT SET"}</strong>
        //   </span>
        // ) : (
        e.value || "NOT SET",
      // ),
    },
    {
      Header: "Display Name",
      accessor: "displayName",
      Cell: (e) => e.value || "None",
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: (e) => e.value || "None",
    },
    {
      Header: "Visibility",
      accessor: "hidden",
      Cell: (e) => {
        // console.log(cell);
        return (
          <div style={{ display: "inline-block" }}>
            <CheckboxField
              name="add"
              label={"Shown"}
              labelOff={"Hidden"}
              isChecked={!productStore.currentProduct.getAttributeVisibility(e.cell.row.original)}
              setField={(val) => {
                productStore.currentProduct.setAttributeVisibility(e.cell.row.original, !val);
              }}
              disabled={e.cell.row.original?.locked || productStore.currentProduct.type === "Sina"}
              useIsChecked
            />
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (e) => (
        <DeleteCell
          action={() =>
            productStore.currentProduct.setAttributes(
              productStore.currentProduct.attributes.filter((attr) => attr !== e.row.original)
            )
          }
        />
      ),
    },
  ];

  return (
    <Table
      tableName={"Attributes"}
      loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
      hasSearch={false}
      COLUMNS={prodAttributeTableColumns}
      rowResults={results}
      DATA={productStore.currentProduct.attributes}
      createText={
        (productStore.currentProduct.type === "Generic" ||
          productStore.currentProduct.type === "AddonService") &&
        "Add Attribute"
      }
      createButtonClick={() =>
        // routeStore.redirect(
        //   `${productStore.currentProduct?.name ? productUrl : productTypeUrl}/${uuidv4()}`
        // )
        productStore.currentProduct.setAttributes([
          ...productStore.currentProduct.attributes,
          new Attribute({ name: "" }),
        ])
      }
      draggable
      setData={(data) => productStore.currentProduct.setAttributes(data)}
      noBottom={results >= productStore.currentProduct.attributes.length}
    />
  );
};

export default observer(ProductAttributeTable);
