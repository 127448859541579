import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../store";
import Table from "../table/Table";
import { orderTableColumns } from "./orderTableColumns";
import OrderTableDropdown from "./OrderTableDropdown";

const OrderTable = ({ isClient = false }) => {
  const { orderStore, uiStateStore, routeStore } = useStores();
  const rowResults = 25;

  const tabs = !isClient
    ? [
        { heading: "All Orders", status: "" },
        { heading: "Errors", status: "errors" },
        { heading: "In Progress", status: "in-progress" },
        { heading: "Completed", status: "complete" },
      ]
    : [];

  function nextPage(pageNumber) {
    orderStore.setCurrentPage(pageNumber);
  }

  // TODO: Conditionally show alert
  // useEffect(() => {
  //   uiStateStore.setOrderAlertOpen(true);
  // }, []);

  useEffect(() => {
    const status = tabs.find((t, idx) => uiStateStore.orderTableTab - 1 === idx)?.status;

    if (isClient) return;
    orderStore.loadOrders(
      status,
      uiStateStore.orderTableFilterText
        ? `search[${uiStateStore.orderTableFilterBy}]=${uiStateStore.orderTableFilterText}${
            status ? `&search[status]=${status}` : ""
          }`
        : "",
      uiStateStore.orderTableSort
    );
  }, [
    uiStateStore.orderTableTab,
    orderStore.currentPage,
    uiStateStore.orderTableFilterBy,
    uiStateStore.orderTableFilterText,
    uiStateStore.orderTableSort,
    isClient,
  ]);

  return (
    <div className="dashboard-container-2">
      <Table
        tabNames={tabs}
        tableName={"Orders"}
        COLUMNS={orderTableColumns}
        DATA={orderStore.orders}
        pages={orderStore.totalPages}
        nextPage={nextPage}
        currentPage={orderStore.currentPage}
        rowResults={rowResults}
        setTabIndex={(index) => uiStateStore.setOrderTableTab(index)}
        currentTab={uiStateStore.orderTableTab}
        loading={uiStateStore.fetchingOrderContent}
        // createText={!isClient ? "Create Order" : ""}
        rowClick={(cells) => {
          const link = `/orders/${cells[0].value}`;
          if (isClient) routeStore.newTab(link);
          else routeStore.redirect(link);
        }}
        selectField={<OrderTableDropdown />}
        filter={uiStateStore.orderTableFilterText}
        setFilter={(text) => uiStateStore.setOrderTableFilterText(text)}
        sort={uiStateStore.orderTableSort}
        setSort={(sort) => uiStateStore.setOrderTableSort(sort)}
      />
    </div>
  );
};

export default observer(OrderTable);
