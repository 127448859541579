import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";
import { productTypeColumns } from "./productTypeColumns";

const ProductTypeTable = () => {
  const rowResults = 25;
  const { productStore, uiStateStore, routeStore } = useStores();

  useEffect(() => {
    productStore.loadProductTypes();
  }, []);

  return (
    <div className="dashboard-container-2">
      <Table
        tableName={"Product Types"}
        COLUMNS={productTypeColumns}
        DATA={productStore.typeList}
        rowResults={rowResults}
        loading={uiStateStore.fetchingProductTypes}
        rowClick={(cells) => {
          routeStore.redirect(`product-types/${encodeURIComponent(cells[1].value)}`);
        }}
      />
    </div>
  );
};

export default observer(ProductTypeTable);
