import React from "react";
import { ToastContainer } from "react-toastify";

const ToastWrapper = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      pauseOnHover
      rtl={false}
      draggable={false}
    />
  );
};

export default ToastWrapper;
