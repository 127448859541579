import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import "./styles.css";

const Button = ({ text, style, action, tooltip, disabled = false, classes = "" }) => {
  const classNames = useMemo(() => {
    const base = "w-button base-button";
    const defaultButton = `${base} btn`;
    return `${classes ? `${classes} ${base}` : defaultButton} ${
      disabled ? "button-disabled disabled" : ""
    }`;
  }, [classes, disabled]);

  return (
    <div className="generic-button">
      <div
        onClick={!disabled ? () => action() : () => null}
        className={classNames}
        style={style || {}}
        data-tip={tooltip}
      >
        {text}
      </div>
      {tooltip && <ReactTooltip />}
    </div>
  );
};

export default Button;
