import React, { useEffect, useState, useMemo } from "react";
import { useStores } from "../../../store";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import { observer } from "mobx-react-lite";
import Button from "../../../components/button/Button";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import SelectField from "../../../components/select-field/SelectField";
import { ATTRIBUTE_OPTION_TYPES, ATTRIBUTE_STATUSES } from "../../../utils/select.utils";
import StoreVisibility from "../../../components/store-visibility/StoreVisibility";
import CheckboxField from "../../../components/checkbox-field/CheckboxField";
import TextBoxField from "../../../components/text-box-field/TextBoxField";
import AttributeDetailTable from "../../../components/attribute-detail-table/AttributeDetailTable";
import { internalNameSchema } from "../../../utils/validation.utils";

const AttributeDetails = () => {
  const { productStore } = useStores();
  const [tab, setTab] = useState(1);

  const updateAttributes = () => {
    productStore.currentProduct.setAttributes(
      productStore.currentProduct.attributes.map((attr) => attr)
    );
  };

  const tabs = useMemo(
    () =>
      productStore.currentProduct.attributes.map((attr) => {
        return { heading: attr.name || "New Attribute", attribute: attr };
      }),
    // .sort((a, b) => a.heading.toLowerCase().localeCompare(b.heading.toLowerCase()))
    [productStore.currentProduct.attributes]
  );

  const attribute = useMemo(
    () =>
      productStore.currentProduct?.attributes?.find((attr) => attr === tabs[tab - 1]?.attribute),
    [productStore.currentProduct.attributes, tab, tabs]
  );

  const isNewAttribute = useMemo(() => !attribute?.name, [attribute]);

  useEffect(() => {
    if (tabs.length && tab > tabs.length) {
      console.log("Reset Tab");
      if (tab === tabs.length + 1 && tabs.length > 1) setTab(() => tab - 1);
      else setTab(1);
    }
  }, [tab, tabs]);

  if (!tabs.length)
    return (
      <BoxedContent>
        <h2 className="no-space-bottom-2 no-padding">No Attributes to Show</h2>
      </BoxedContent>
    );

  return (
    <>
      {tabs.length > 0 && (
        <div className="in-page-menu w-tab-menu">
          {tabs.map((item, index) => (
            <div
              key={index}
              onClick={() => setTab(index + 1)}
              data-w-tab={`Tab ${index + 1}`}
              className={
                tab === index + 1
                  ? "in-page-menu-link-2 w-inline-block w-tab-link w--current"
                  : "in-page-menu-link-2 w-inline-block w-tab-link tab-link-not-active"
              }
            >
              <div className="inpage_menu_text">{item.heading}</div>
            </div>
          ))}
        </div>
      )}
      <div>
        {/* Heading Title */}
        <DetailHeader
          title={attribute?.displayName || attribute?.name || "New Attribute"}
          tags={[
            {
              component: (
                <StatusText
                  color={determineColor(attribute?.status || "None")}
                  text={attribute?.status || "None"}
                  classes="status-2 header"
                />
              ),
            },
            {
              text: attribute?.displayName ? attribute?.name : "",
            },
            { text: ATTRIBUTE_OPTION_TYPES.find(o => o.value === attribute?.type)?.text || "" },
          ]}
        />

        <BoxedContent title={"Information"}>
          <div className="with-border" style={{ marginBottom: "20px" }}>
            <h5 className="heading-62">Status</h5>
            <SelectField
              name="attribute-status"
              options={ATTRIBUTE_STATUSES}
              setField={(e) => {
                attribute?.setStatus(e);
                updateAttributes();
              }}
              value={attribute?.enabled}
            />
          </div>
          {/*<StoreVisibility
            stores={attribute?.stores}
            updateStores={(bool, store) => {
              attribute?.updateStoreVisibility(bool, store);
              updateAttributes();
            }}
          />*/}
          {/*productStore.currentProduct.type !== "Sina" && (
            <div style={{ marginBottom: "15px" }}>
              <h5 className="heading-62">Lock Attribute Visibility (not implemented)</h5>
              <CheckboxField
                name="attr-auto-lock-attribute"
                setField={(e) => {
                  attribute?.setLocked(e);
                  updateAttributes();
                }}
                label="Yes"
                labelOff="No"
                isChecked={attribute?.locked}
                useIsChecked
              />
            </div>
            )*/}
          <TextBoxField
            title="Internal Name"
            placeholder={"Enter attribute internal Name... (cannot be changed once set)"}
            field={attribute?.name}
            setField={(n) => {
              attribute?.setName(n);
              updateAttributes();
            }}
            disabled={!isNewAttribute}
            resize="none"
            validationSchema={internalNameSchema}
          />
          <div style={{ marginBottom: "20px" }}>
            <h5 className="heading-62">Required</h5>
            <CheckboxField
              label="Yes"
              labelOff="No"
              setField={(required) => {
                attribute?.setRequired(required);
                productStore.currentProduct.edited();
              }}
              isChecked={attribute?.required}
              useIsChecked
            />
          </div>
          
          <TextBoxField
            title={"Display Name"}
            field={attribute?.displayName}
            setField={(e) => {
              attribute?.setDisplayName(e);
              updateAttributes();
            }}
            placeholder={
              "Enter attribute external Name...   (This will override the internal name)"
            }
            resize="none"
          />
          <TextBoxField
            title={"Location / Step"}
            field={attribute?.location}
            setField={(e) => {
              attribute?.setLocation(e);
              updateAttributes();
            }}
            disabled={true} // TODO: This will be editable during the booking form overhaul
            placeholder={"N/A - pending new booking flow..."}
            style={{ width: "250px" }}
            resize="none"
          />
        </BoxedContent>

        {/*<BoxedContent title={"Internal Notes"}>
          <TextBoxField
            field={attribute?.notes}
            setField={(e) => {
              attribute?.setNotes(e);
              updateAttributes();
            }}
            placeholder={"Enter product internal notes..."}
          />
        </BoxedContent>*/}

        <AttributeDetailTable attribute={attribute} />

        {!isNewAttribute && (
          <Button
            text={`Delete ${attribute?.name || "Attribute"}`}
            action={() => {
              productStore.currentProduct.removeAttribute(attribute);
              updateAttributes();
            }}
          />
        )}
      </div>
    </>
  );
};

export default observer(AttributeDetails);
