import { sentenceCase } from "../../utils/string.utils";

export const productTypeColumns = [
  {
    Header: "ID",
    accessor: "_id", // accessor is the "key" in the data
  },
  {
    Header: "Name",
    accessor: "type",
    Cell: (e) => sentenceCase(e.value),
  },
  // {
  //   Header: "# of Attributes",
  //   accessor: "attributes.length",
  // },
];
