import React, { useCallback, useEffect } from "react";
import CheckboxField from "../checkbox-field/CheckboxField";
import { useStores } from "../../store";
import { observer } from "mobx-react-lite";

import "./styles.css";
// Dynamically get list of stores and make checkboxes
const StoreVisibility = ({ stores, updateStores }) => {
  const { shopStore } = useStores();
  // const [checked, setChecked] = useState(false); // temporary for only one store

  useEffect(() => {
    if (!shopStore.hasFetched) shopStore.loadStores();
  }, []);

  const isChecked = useCallback(
    (id) => {
      return stores?.find((s) => id === s);
    },
    [stores]
  );

  return shopStore.stores ? (
    <div className="store-visibility-title">
      <h5 className="heading-62">Store Visibility</h5>
      <div className="style-guide-form-grid-2">
        {shopStore.stores.map((store, idx) => {
          return (shopStore.currentStore === "0" || shopStore.currentStore === store.id) && store.id !== "0" ? (
            <CheckboxField
              key={`${store.domain}`}
              name={store.domain}
              label={store.name}
              isChecked={isChecked(store.id)}
              setField={(bool) => {
                updateStores(bool, store.id);
              }}
              useIsChecked
            />
          ) : null;
        })}
      </div>
    </div>
  ) : null;
};

export default observer(StoreVisibility);
