import { observer } from "mobx-react-lite";
import React from "react";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
// import AttributeOptionTable from "../../../components/product-attribute-table/AttributeOptionTable";
import ProductAttributeTable from "../../../components/product-attribute-table/ProductAttributeTable";
import AttributeDetails from "../attributes/AttributeDetails";

const Attributes = ({ product, isDuplicate }) => {
  // const [tab, setTab] = useState(1);
  return (
    <>
      <ProductAttributeTable product={product} isDuplicate={isDuplicate} />
      {product?.name && product?.attributes.length > 1 && (
        <BoxedContent>
          <h2 className="no-space-bottom-2 no-padding">Select Attribute To Edit</h2>
        </BoxedContent>
      )}
      {/* {product?.name && <AttributeOptionTable product={product} tab={tab} setTab={setTab} />}
       */}
      <AttributeDetails />
    </>
  );
};

export default observer(Attributes);
