import { makeAutoObservable, runInAction } from "mobx";
import { deleteAt, get, post, put } from "../utils/api";
import { SORT_TYPE } from "../utils/select.utils";
import { titleCase } from "../utils/string.utils";
import { PRODUCT_BASE_URL } from "./productStore";
import { Quote } from "./domain/quote";

const QUOTES_URL = "quotes/";

export class QuoteStore {
  quotes = [];
  totalQuotes = 0;
  totalPages = 1;
  currentPage = 1;
  currentQuote = new Quote();
  // quoteSnapshot = new Quote();
  
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  async loadQuotes(rawSearch, sort = SORT_TYPE) {
    this.rootStore.uiStateStore.setFetchingQuoteContent(true);;
    const baseUrl = `${QUOTES_URL}?page=${this.currentPage}${sort.sortBy ? `&sort[${sort.sortBy}]=${sort.sortDirection}` : ""
      }`;
    const fetchUrl = rawSearch ? `${baseUrl}&${rawSearch}` : baseUrl + `&limit=25`;

    try {
      const res = await get(fetchUrl);
      if (!this.rootStore.uiStateStore.fetchingQuoteContent) return;
      console.log(res);
      this.setProps(res);
      this.rootStore.uiStateStore.setFetchingQuoteContent(false);
    } catch (err) {
      // noop
    }
  }

  setProps(res) {
    this.quotes = res.quotes;
    this.totalPages = res.totalPages;
    this.totalQuotes = res.totalQuotes;
  }

  async getQuote(id) {
    this.resetQuote();
    this.rootStore.uiStateStore.setFetchingCurrentQuote(true);

    try {
      const res = await get(`${QUOTES_URL}${id}`);
      console.log(res);
      this.currentQuote.fromObject(res);
      // this.quoteSnapshot.fromObject(this.currentQuote);
    } catch (err) {
      console.error(err);
      this.rootStore.routeStore.redirect(`/quotes`);
    } finally {
      this.rootStore.uiStateStore.setFetchingCurrentQuote(false);
    }
  }

  resetQuote() {
    this.currentQuote = new Quote();
    // this.quoteSnapshot = new Quote();
  }

  async updateQuote() {
    try {
      const message = this.currentQuote.archived ? "Unarchiv" : "Archiv";
      const res = await put(`${QUOTES_URL}${this.currentQuote._id}`, this.currentQuote);
      this.currentQuote.fromObject(res);
    } catch (err) {
      console.error(err);
    }
  }
}