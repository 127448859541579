import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sentenceCase } from "../utils/string.utils";

const BASE_TITLE = document.title;

const useTitle = (title = "") => {
  const location = useLocation();

  useEffect(() => {
    const components = location.pathname.split("/");

    if (components[components.length - 1] === "") document.title = "Home - " + BASE_TITLE;
    else if (title) document.title = `${title} - ${BASE_TITLE}`;
    else
      document.title = `${sentenceCase(
        decodeURIComponent(components[components.length - 1]).replace(/-+/g, " ")
      )} - ${BASE_TITLE}`;
  }, [location.pathname]);
};

export default useTitle;
