import { observer } from "mobx-react-lite";
import React from "react";
import Table from "../table/Table";
import { propertyTableColumns } from "./propertyTableColumns";

const PropertyWebpagesTable = () => {
  const rowResults = 25;

  const tabs = [];

  function nextPage(pageNumber) {
    return;
  }

  return (
    <div className="dashboard-container-2">
      <Table
        tabNames={tabs}
        tableName={"Property Webpages"}
        COLUMNS={propertyTableColumns}
        DATA={[]}
        pages={0}
        nextPage={nextPage}
        currentPage={0}
        rowResults={rowResults}
        setTabIndex={(e) => {
          return e;
        }}
        currentTab={1}
        loading={false}
        createText={"Add New"}
      />
    </div>
  );
};

export default observer(PropertyWebpagesTable);
