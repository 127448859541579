import { makeAutoObservable } from "mobx";
import { Account } from "./domain/account";

export class AccountStore {
  user = new Account();
  uid;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setUser(user) {
    this.user.fromObject(user);
    // This should only happen if user has access to all pages
    // Could cause problems with race conditions
    // this.rootStore.initialFetch();
  }
}
