import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import CheckboxField from "../checkbox-field/CheckboxField";
import Table from "../table/Table";
import AddonPopup from "../addon-popup/AddonPopup";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import DeleteCell from "../table/DeleteCell";
import { useStores } from "../../store";

const ProductAddOnTable = ({ product }) => {
  const { uiStateStore, routeStore } = useStores();
  const [showSelect, setShowSelect] = useState(false);
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const prodAddOnTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Add-On",
      accessor: "name",
      Cell: (data) => (
        <span
          className="cell-link"
          onClick={() => routeStore.newTab(`/product-add-ons/${data.row.original._id}`)}
        >
          <strong className="in-text-link">{data.value}</strong>
        </span>
      ),
    },
    {
      Header: "ID",
      accessor: "addon",
    },
    // {
    //   Header: "Add-On Visibility",
    //   accessor: "enabled",
    //   Cell: (e) => (
    //     <div style={{ display: "inline-block" }}>
    //       <CheckboxField name="add" label={"Enabled"} labelOff={"Disabled"} />
    //     </div>
    //   ),
    // },
    {
      Header: "",
      accessor: "delete",
      Cell: (e) => (
        <DeleteCell
          action={() =>
            product.setAddOns(product.addons.filter((addon) => e.row.original._id !== addon._id))
          }
        />
      ),
    },
  ];

  useEffect(() => {
    setResults(LOAD_MORE_TABLE_RESULTS);
  }, []);

  return (
    <>
      <AddonPopup
        show={showSelect}
        title={`Add Addons`}
        setShow={(s) => setShowSelect(s)}
        rowClick={(cells) => {
          cells[0].row.toggleRowSelected();
        }}
        hasCheckbox
        buttons={[
          {
            text: "Add",
            onClick: (rows) => {
              rows.map((row, idx) => {
                if (row.isSelected) product.addAddon(row.original);
                return null;
              });
              setShowSelect(false);
            },
            style: { minWidth: "100px", marginLeft: "0px" },
          },
        ]}
      />
      <Table
        tableName={"Add-Ons"}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        hasSearch={false}
        COLUMNS={prodAddOnTableColumns}
        rowResults={results}
        DATA={product.addons}
        loading={uiStateStore.fetchingProductAddons}
        createText={"Add"}
        createButtonClick={() => setShowSelect(true)}
        draggable
        setData={(data) => product.setAddOns(data)} // TODO: Add methods in product domain class
        noBottom={results >= product.addons.length}
      />
    </>
  );
};

export default observer(ProductAddOnTable);
