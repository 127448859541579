import React from "react";
import ReactTooltip from "react-tooltip";
import { Trash } from "../../assets/images";
import "./styles.css";

const DeleteCell = ({ action, disabled = false, tooltip }) => {
  return (
    <div
      className={`delete-cell${disabled ? " del-disabled" : ""}`}
      onClick={() => (disabled ? null : action())}
      data-tip={tooltip}
    >
      <img loading="lazy" height={20} src={Trash} alt="" />
      {tooltip && <ReactTooltip />}
    </div>
  );
};

export default DeleteCell;
