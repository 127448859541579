import { makeAutoObservable, runInAction } from "mobx";
import { get } from "../utils/api";

const JOB_URL = `jobs/`;
export class JobStore {
  jobs = [];

  get totalJobs() {
    return this.jobs.length;
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async loadJobs() {
    try {
      this.rootStore.uiStateStore.setFetchingJobContent(true);
      const res = await get(`${JOB_URL}`);
      console.log(res);
      runInAction(() => (this.jobs = res.jobs));
    } catch (err) {
    } finally {
      this.rootStore.uiStateStore.setFetchingJobContent(false);
    }
  }

  async getJob(id) {
    try {
      const res = await get(`${JOB_URL}${id}`);
      console.log(res);
    } catch (err) {}
  }
}
