// Import Packages
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { useEffect } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";

// Import Routes and Components
import routes from "./routes";
import Notifier from "./components/notifier/Notifier";
import ReactionContainer from "./components/reaction-container/ReactionContainer";
import ToastWrapper from "./components/toast-wrapper/ToastWrapper";

// Import Global Styles
import "./assets/css/webflow.css";
import "./assets/css/webflow-global-styles.css";
import "./assets/css/custom-global-styles.css";
import "react-toastify/dist/ReactToastify.css";

function AppRoutes() {
  let appRoutes = useRoutes(routes);

  return appRoutes;
}

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    const preload = document.getElementById("preload");
    if (preload) preload.remove();
  }, []);



  return (
    // <DndProvider backend={HTML5Backend}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <AppRoutes />
          <ToastWrapper />
          <Notifier />
          <ReactionContainer />
        </div>
      </Router>
    </QueryClientProvider>
    // </DndProvider>
  );
}

export default App;
