import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import Table from "../table/Table";
import { useStores } from "../../store";
import DeleteCell from "../table/DeleteCell";
import AddDetailPopup from "../add-detail-popup/AddDetailPopup";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import FileUpload from "../file-upload-field/FileUpload";
import ReactTooltip from "react-tooltip";
import TextBoxField from "../text-box-field/TextBoxField";
import { DETAIL_TOOLTIP, NO_RESULTS_TEXT_INHERITS, NO_RESULTS_TEXT } from "../../utils/string.utils";

const CustomerReviewTable = ({ product }) => {
  const { productStore, shopStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [upload, setUpload] = useState(false);
  const [revIndex, setRevIndex] = useState(0);
  const [createOpen, setCreateOpen] = useState(false);
  const filterFn = c => c.store === shopStore.currentStore;
  const [fullDetails, setFullDetails] = useState([]);

  useEffect(() => {
    setFullDetails(() => {
      const { reviews, fullReviews } = productStore.currentProduct;
      const detailsToUse = reviews.filter(filterFn);
      return fullReviews.filter(d => d.fresh || detailsToUse.find(p => p.detail === d._id));
    });
  }, [shopStore.currentStore, productStore.currentProduct.saveCounter, productStore.currentProduct.detailsEdited]);

  const custReviewTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Picture",
      accessor: "picture",
      Cell: (e) => (
        <div className="div-block-57">
          <img
            loading="lazy"
            src={e.value}
            alt=""
            className="image-18"
            style={{ cursor: "pointer" }}
            data-tip="Click to Replace"
            onClick={() => {
              setRevIndex(e.row.index);
              setUpload(true);
            }}
          />
          <ReactTooltip />
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "title",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.title}
                setField={(val) => {
                  e.cell.row.original.setTitle(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description Title"}
                style={{ minWidth: "90px", maxWidth: "250px" }}
                resize={"horizontal"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Title",
      accessor: "title2",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.title2}
                setField={(val) => {
                  e.cell.row.original.setTitle2(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description Title"}
                style={{ minWidth: "90px", maxWidth: "250px" }}
                resize={"horizontal"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "Review",
      accessor: "description",
      Cell: (e) => {
        return (
          <>
            <div
              style={{ display: "inline-block" }}
              data-tip={!e.cell.row.original.editable ? DETAIL_TOOLTIP : ""}
            >
              <TextBoxField
                field={e.cell.row.original.description}
                setField={(val) => {
                  e.cell.row.original.setDescription(val);
                  productStore.currentProduct.edited();
                }}
                placeholder={"Description"}
                style={{ minWidth: "250px", width: "600px", maxWidth: "800px" }}
                resize={"both"}
                useDefaultValue
                disabled={!e.cell.row.original.editable}
              />
            </div>
            {!e.cell.row.original.editable && <ReactTooltip />}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (cell) => (
        <DeleteCell
          action={() => productStore.currentProduct.removeFullReview(cell.row.original)}
          disabled={!cell.row.original.editable}
          tooltip={!cell.row.original.editable ? DETAIL_TOOLTIP : ""}
        />
      ),
    },
  ];

  return (
    <>
      <FileUpload
        name={"customer-pic-upload"}
        clicked={upload}
        resetClicked={() => setUpload(false)}
        handleUpload={(e) => {
          console.log(`UPLOADED`, e);
          console.log(productStore.currentProduct.fullReviews[revIndex]);
          productStore.currentProduct.fullReviews[revIndex].setPicture(e);
          // HACK: TO RELOAD TABLE
          const temp = productStore.currentProduct.fullReviews;
          productStore.currentProduct.setFullReviews([]);
          productStore.currentProduct.setFullReviews(temp);
        }}
      />
      <AddDetailPopup type={"Review"} show={createOpen} setShow={(e) => setCreateOpen(e)} />
      <Table
        tableName={`Customer Reviews (${shopStore.currentStoreName})`}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        createText={!productStore.currentProduct.name && "Add"}
        createButtonClick={() => setCreateOpen(true)}
        COLUMNS={custReviewTableColumns}
        rowResults={results}
        DATA={fullDetails}
        noResultsText={shopStore.currentStore === "0" ? NO_RESULTS_TEXT : NO_RESULTS_TEXT_INHERITS}
        // draggable
        // setData={(d) => product.setFullReviews(d)} // TODO: same for faq, will overwrite all need to simplify
        noBottom={results >= productStore.currentProduct.fullReviews.length}
      />
    </>
  );
};

export default observer(CustomerReviewTable);
