import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";

const checkboxClasses = "w-checkbox-input w-checkbox-input--inputType-custom switch-input";

const CheckboxField = ({
  name,
  setField,
  label = "Enabled",
  labelOff = "",
  disabled = false,
  isChecked = false,
  useIsChecked = false,
}) => {
  const [checked, setChecked] = useState(isChecked);
  const updateField = useCallback(
    (val) => {
      setChecked(val);
      setField && setField(val);
    },
    [setField]
  );

  useEffect(() => {
    if (useIsChecked) setChecked(isChecked);
  }, [isChecked]);

  return (
    <>
      <label className="w-checkbox switch-field">
        <div
          className={
            disabled && !checked // if disabled and not checked
              ? checkboxClasses + " disabled" // disable the checkbox and have it in off position
              : disabled && checked // else if disabled and checked
              ? checkboxClasses + " w--redirected-checked disabled" // disable the checkbox and have it in on position
              : checked // else if just checked
              ? checkboxClasses + " w--redirected-checked" // just have it in on
              : checkboxClasses // else just show the normal checkbox
          }
        ></div>
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={(e) => updateField(!checked)}
          className={"no-show-checkbox"}
          disabled={disabled}
        />
        <span className="checkbox-label w-form-label">{`${checked ? label : labelOff || label}${
          disabled ? ` (Locked)` : ""
        }`}</span>
      </label>
    </>
  );
};

export default CheckboxField;
