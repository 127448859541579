import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./styles.css";

const TabbedContent = ({ setTabIndex, currentTab, children }) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(!inProp);
  }, [currentTab]);

  return React.Children.count(children) > 1 ? (
    <div className="w-tabs tabs">
      <div className="in-page-menu w-tab-menu">
        {children.map(
          (item, index) =>
            item?.props?.label && (
              <div
                key={index}
                onClick={() => setTabIndex(index + 1)}
                data-w-tab={`Tab ${index + 1}`}
                className={
                  currentTab === index + 1
                    ? "in-page-menu-link-2 w-inline-block w-tab-link w--current"
                    : "in-page-menu-link-2 w-inline-block w-tab-link tab-link-not-active"
                }
              >
                <div className="inpage_menu_text">{item.props.label}</div>
              </div>
            )
        )}
      </div>
      <CSSTransition in={inProp} timeout={400} classNames="tabbed-transition">
        <div className="tabs-content w-tab-content">
          {children.map((child, index) => {
            if (currentTab !== index + 1) return null;
            return child;
          })}
        </div>
      </CSSTransition>
    </div>
  ) : (
    React.Children.only(children)
  );
};

export default TabbedContent;
