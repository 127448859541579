import { makeAutoObservable, toJS } from "mobx";

export class Addon {
  bio = "";
  images = [];
  learnMore = [{}];
  name = "";
  _id = "";
  notes = "";
  price = "";
  link = {};
  edits = 0;
  stores = [];
  addon = "";
  progressTo = {};

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  resetEdits() {
    this.edits = 0;
  }

  edited() {
    this.edits++;
  }

  fromObject(obj) {
    Object.assign(this, toJS(obj));
    if (this._id) this.addon = this._id;
  }

  setName(name) {
    this.name = name;
    this.edits++;
  }

  setNotes(notes) {
    this.notes = notes;
    this.edits++;
  }

  setBio(bio) {
    this.bio = bio;
    this.edits++;
  }

  setPrice(price) {
    this.price = price;
    this.edits++;
  }

  removeImage(url) {
    this.images.splice(
      this.images.findIndex((img) => img.url === url),
      1
    );
    this.edits++;
  }

  addImage(url) {
    this.images.push(url);
    this.edits++;
  }

  replaceImage(old, url) {
    if (!old) return;
    const temp = this.images;
    temp[this.images.findIndex((e) => e.url === old)] = url;
    this.images = temp;
    this.edits++;
  }

  setLink(link) {
    this.link = link;
    this.edits++;
  }

  updateStoreVisibility(bool, store) {
    if (bool) this.stores.push(store);
    else this.stores.splice(this.stores.indexOf(store), 1);
    this.edits++;
  }

  setProgressTo(addon) {
    this.progressTo = addon;
    this.edits++;
  }
}
