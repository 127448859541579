import { get, logout } from "../utils/api";
import { rootStore } from "../store";
import useLocalStorage from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";

const useAuth = () => {
  const [authn] = useLocalStorage("authn");
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("storage", () => {
      if (!localStorage.getItem("authn")) {
        setAuth(false);
        logout();
      }
    });
  }, []);

  useEffect(() => {
    if (authn) {
      setLoading(true);
      get("/auth/whoami")
        .then((res) => {
          setAuth(true);
          setLoading(false);
          rootStore.accountStore.setUser(res);
        })
        .catch((err) => {
          setAuth(false);
          setLoading(false);
          localStorage.removeItem("authn");
          rootStore.notificationStore.createNotification(
            "Authentication error",
            "Could not authenticate you! Your login may have expired!",
            "error"
          );
        });
    } else {
      setLoading(false);
    }
  }, [authn]);

  return [auth, loading];
};

export default useAuth;
