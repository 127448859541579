import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { configure } from "mobx";
import { configureAxios, isProd } from "./utils/api";

// Configure Mobx
configure({ enforceActions: "observed" });

// Configure Axios
configureAxios();

// Disable logging in prod
if (isProd) console.log = function () {};

const renderApp = () => {
  Promise.all([
    // hydrate stores here
  ]).then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
};

renderApp();
