import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/dashboard/Footer";
import Header from "../components/dashboard/Header";
import Sidebar from "../components/sidebar/Sidebar";
import Login from "./login/Login";
import LogoutWrapper from "../components/logout-wrapper/LogoutWrapper";
import useAuth from "../hooks/useAuth";

const ProtectedRoutes = () => {
  const [isAuth, authenticating] = useAuth();

  // Change to some kind of spinner
  if (authenticating) return <div id="preload" className="lds-hourglass"></div>;

  return isAuth ? (
    <>
      <Header />
      <div className="dashboard-content-2">
        <LogoutWrapper />
        <Sidebar />
        <Outlet /> {/* The Route to be rendered */}
        <Footer />
      </div>
    </>
  ) : (
    <Login />
  );
};

export default observer(ProtectedRoutes);
