import React from "react";
import { observer } from "mobx-react-lite";
import OrderJobsTable from "../../../components/order-jobs-table/OrderJobsTable";
import JobDetails from "../../jobs/JobDetails";

const Jobs = ({ order }) => {
  return (
    <>
      <OrderJobsTable order={order} />
      <JobDetails />
    </>
  );
};

export default observer(Jobs);
