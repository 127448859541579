import React from "react";
import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import routes from "../../routes";
// import { useStores } from "../../store";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  // const { routeStore } = useStores();

  return (
    <div className="breadcrumb-wrapper">
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <div key={match.pathname} className="breadcrumb-item">
          <NavLink
            className="breadcrumb-text w-inline-block"
            to={match.pathname}
            // onClick={() => routeStore.redirect(match.pathname)}
            end
          >
            {breadcrumb}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;
