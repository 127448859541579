import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import Table from "../table/Table";

// TODO: More Columns
const OrderTimelineTable = ({ order }) => {
  const columns = [
    { Header: "Date", accessor: "when", Cell: (e) => format(new Date(e.value), "PPpp") },
    {
      Header: "Action",
      accessor: "comment",
      Cell: (e) => `${e.value}${e.row.original?.who ? ` by ${e.row.original?.who}` : ""}`,
    },
  ];
  return (
    <Table
      tableName={"Timeline"}
      DATA={order?.timeline}
      COLUMNS={columns}
      rowResults={order?.timeline?.length || 1}
      noBottom
    />
  );
};

export default observer(OrderTimelineTable);
