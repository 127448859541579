import equal from "fast-deep-equal/es6";
import { makeAutoObservable, toJS } from "mobx";

// TODO: Verify options do not have the same name, and there are no empty options
export class Attribute {
  name = ""; // Original Attribute name assigned by supplier
  displayName = ""; // Displayed as Attribute Name
  options = []; // Array of the attributes options as strings
  preSelected = false; // whether the attribute is already applied to a product by default
  location = ""; // Where you can find the attribute
  hidden = false; // Whether this attribute is visible on the store
  enabled = true; // Whether this attribute is enabled on the store
  type = ""; // Type of the attribute
  notes = "";
  hasCustomSize = false;
  locked = false;
  markup = null;
  multiple = false;
  required = true;
  fileTypes = [];
  stores = [];
  _options = [];
  option;
  options = [];

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  setName(name) {
    this.name = name;
  }

  fromObject(obj) {
    Object.assign(this, toJS(obj));
    // this._options = this.options.map((val) => val);
  }

  setHidden(val) {
    this.hidden = val;
  }

  getOption(opt) {
    // this is becoming a mess
    if (typeof opt === "string") {
      return this.options.find(o => o.name === opt);
    } else if (opt.name) {
      return opt;
    } else {
      throw new Error();
    }
  }

  setOption(old, val) {
    // this.options[old] = val;
    const opt = this.getOption(old);
    if (opt) {
      opt.name = val;
    }
  }

  removeOption(option) {
    console.log("Remove", option)
    this.options.splice(
      this.options.findIndex(o => o.name === option.name),
      1
    );
  }

  setOptions(options) {
    this.options = options;
  }

  getOptionVisibility(option) {
    const opt = this.getOption(option);
    return opt ? opt.visible : false;
  }

  setOptionHidden(option, value) {
    console.log("Opt visiblility", option, value);
    const opt = this.getOption(option);
    if (opt) {
      opt.visible = value;
    }
  }

  getOptionOverride(option) {
    const opt = this.getOption(option);
    return opt ? opt.displayName : "ERROR";
  }

  setOptionOverride(old, newName) {
    console.log("Override", old);
    throw new Error();
    // const opt = this.getOption(option);
    // if (opt) {
    //   opt.displayName = newName;
    // }
  }

  setOptionDisplayName(name, displayName) {
    const opt = this.getOption(name);
    if (opt) {
      opt.displayName = displayName;
    }
  }

  getOptionPrice(option) {
    const opt = this.getOption(option);
    return opt ? opt.price : "ERROR";
  }

  setOptionPrice(option, val) {
    const opt = this.getOption(option);
    if (opt) {
      opt.price = val;
    }
  }

  setOptionNextAttributes(option, attribNames) {
    const opt = this.getOption(option);
    if (opt) {
      opt.nextAttributes = attribNames.map(name => {
        return { name };
      });
    }
  }

  setMasterMarkup(markup) {
    this.markup = markup;
  }

  setStatus(status) {
    this.enabled = status === "true"; // TODO: revisit status
  }

  setDisplayName(name) {
    this.displayName = name;
  }

  setNotes(notes) {
    this.notes = notes;
  }

  setHasCustomSize(has) {
    this.hasCustomSize = has;
  }

  setLocked(locked) {
    this.locked = locked;
  }

  setRequired(required) {
    this.required = required;
  }

  setType(type) {
    this.type = type;
  }

  setMultipleFiles(multiple) {
    this.multiple = multiple;
  }

  setFileTypes(fileTypes) {
    this.fileTypes = fileTypes.split(",");
  }

  compare(obj) {
    const js = toJS(this);
    const right = toJS(obj);
    return equal(js, right);
  }

  updateStoreVisibility(bool, store) {
    if (bool) this.stores.push(store);
    else this.stores.splice(this.stores.indexOf(store), 1);
  }

  addOption(option) {
    this.options.push({
      name: option
    });
  }

  setLocation(location) {
    this.location = location;
  }

  get hasOnlyOneOption() {
    let count = 0;

    for (const opt of this.options) {
      if (opt.visible) count++;
    }

    return count <= 1;
  }

  get status() {
    return this.enabled ? "Active" : "Inactive";
  }
}
