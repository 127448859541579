import React, { useEffect, useState } from "react";
import Table from "../table/Table";
import { observer } from "mobx-react-lite";
import { useStores } from "../../store";
import { productTableColumns } from "./productTableColumns";
import CreateProduct from "../create-product/CreateProduct";
import ProductTableDropdown from "./ProductTableDropdown";

const ProductTable = () => {
  const { productStore, uiStateStore, routeStore } = useStores();
  const [createOpen, setCreateOpen] = useState(false);
  const rowResults = 25;

  const tabs = [
    { heading: "All", type: "All" },
    { heading: "Repackaged", type: "Repackage" },
    { heading: "Supplier", type: "Sina" },
    { heading: "Generic", type: "Generic" },
    { heading: "Booking", type: "BookingService" },
    { heading: "Design", type: "DesignService" },
    { heading: "Quote", type: "Quote" },
    { heading: "Package", type: "Package" },
    { heading: "Package Set", type: "PackageSet" },
    { heading: "Add-on Service", type: "AddonService" },
  ];

  function nextPage(pageNumber) {
    productStore.setCurrentPage(pageNumber);
  }

  useEffect(() => {
    const type = tabs.find((t, idx) => uiStateStore.productTableTab - 1 === idx).type;

    productStore.loadProducts(
      type,
      uiStateStore.productTableFilterText
        ? `search[${uiStateStore.productTableFilterBy}]=${uiStateStore.productTableFilterText}${
            type !== "All" ? `&search[type]=${type}` : ""
          }`
        : "",
      uiStateStore.productTableSort
    );
  }, [
    uiStateStore.productTableTab,
    productStore.currentPage,
    uiStateStore.productTableFilterBy,
    uiStateStore.productTableFilterText,
    uiStateStore.productTableSort,
  ]);

  // TODO: Bug with pagination and quick tab switching
  return (
    <div className="dashboard-container-2">
      <CreateProduct show={createOpen} setShow={setCreateOpen} />
      <Table
        tabNames={tabs}
        tableName={"Products"}
        COLUMNS={productTableColumns}
        DATA={productStore.products}
        pages={productStore.totalPages}
        nextPage={nextPage}
        currentPage={productStore.currentPage}
        rowResults={rowResults}
        setTabIndex={(index) => uiStateStore.setProductTableTab(index)}
        currentTab={uiStateStore.productTableTab}
        loading={uiStateStore.fetchingProductContent}
        createText={"Create Product"}
        createButtonClick={() => setCreateOpen(true)}
        rowClick={(cells) => {
          routeStore.redirect(`products/${encodeURIComponent(cells[0].row.id)}`);
        }}
        selectField={<ProductTableDropdown />}
        filter={uiStateStore.productTableFilterText}
        setFilter={(text) => uiStateStore.setProductTableFilterText(text)}
        sort={uiStateStore.productTableSort}
        setSort={(sort) => uiStateStore.setProductTableSort(sort)}
        hiddenColumns={["_id"]}
      />
    </div>
  );
};

export default observer(ProductTable);
