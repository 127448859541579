import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState, useEffect } from "react";
import BoxedContent from "../../components/boxed-content/BoxedContent";
import DetailHeader from "../../components/detail-header/DetailHeader";
import Table from "../../components/table/Table";
import { useStores } from "../../store";
import { Job } from "../../store/domain/order-metadata";
import { removeKebab, sentenceCase } from "../../utils/string.utils";

const JobDetails = () => {
  const { orderStore, jobStore } = useStores();
  const [tab, setTab] = useState(1);
  const tabs = useMemo(
    () =>
      orderStore.currentOrder.jobs.map((job) => {
        return { heading: job.id, job: new Job(job) };
      }),
    [orderStore.currentOrder.jobs]
  );
  const job = useMemo(
    // () => new Job(orderStore.currentOrder?.jobs?.find((job) => job.id === tabs[tab - 1]?.job.id)),
    () => tabs[tab - 1]?.job,
    [tab, tabs]
  );

  

  // useEffect(() => {
  //   console.log(job);
  //   // if (job?.id) jobStore.getJob(job.id);
  // }, [job]);

  const columns = [
    { Header: "Date", accessor: "at", Cell: (e) => format(new Date(e.value), "PPpp") },
    {
      Header: "Action",
      accessor: "name",
      Cell: (e) => `${e.value} - ${e.row.original?.state}`,
    },
  ];

  if (!tabs.length)
    return (
      <BoxedContent>
        <h2 className="no-space-bottom-2 no-padding">No Jobs to Show</h2>
      </BoxedContent>
    );

  // TODO: Kludge
  const jobFields = job?.metadata?.find((data) => data.key === "fields").value;
  const fieldsRows = [];
  if (jobFields) {
    for (const key in jobFields) {
      if (jobFields[key] !== "" && jobFields[key] !== "-- Select Option --")  {
        fieldsRows.push({ key, value: jobFields[key] });
      }
    }
  }
  for (const key in jobFields) {
    console.log(key, jobFields[key]);
  }

  return (
    <>
      {tabs.length >= 1 && (
        <div className="in-page-menu w-tab-menu">
          {tabs.map((item, index) => (
            <div
              key={index}
              onClick={() => setTab(index + 1)}
              data-w-tab={`Tab ${index + 1}`}
              className={
                tab === index + 1
                  ? "in-page-menu-link-2 w-inline-block w-tab-link w--current"
                  : "in-page-menu-link-2 w-inline-block w-tab-link tab-link-not-active"
              }
            >
              <div className="inpage_menu_text">{item.heading}</div>
            </div>
          ))}
        </div>
      )}
      <div>
        <DetailHeader
          title={`Job #${job.id}`}
          subtitle={`${sentenceCase(removeKebab(job.service))}`}
          // betweenTitles={
          //   <>
          //     <div className="large-text">Complete Job By:</div>
          //   </>
          // }
          buttons={[
            {
              text: "Submit Job",
              onClick: () => null,
              tooltip: "WIP",
              disabled: true,
            },
            {
              text: "Edit Job",
              onClick: () => null,
              tooltip: "WIP",
              disabled: true,
            },
          ]}
        />
        <BoxedContent>
          <h2 className="no-space-bottom-2">Job Details</h2>

          <div className="table-content-2">
            <div className="table-list-2">
              <div className="table-row-link-2 no-shadow w-inline-block">
                { fieldsRows.map(({ key, value }) => (
                  <div className="_2-grid job add-prod-table-row">
                    <strong>{key}</strong>
                    <div>{value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </BoxedContent>
        <Table
          tableName={"Job Timeline"}
          DATA={job?.steps}
          COLUMNS={columns}
          rowResults={job?.steps?.length || 1}
          noBottom
        />
      </div>
    </>
  );
};

export default observer(JobDetails);
