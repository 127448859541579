import React from "react";
import { ArrowChevronLeft1024 } from "../../assets/images";
import { useStores } from "../../store";
import "./styles.css";

const PageBackButton = ({ redirectTo, backText = redirectTo }) => {
  const { routeStore } = useStores();
  return (
    <div className="horizontal-wrapper">
      <div
        onClick={() => routeStore.redirect(redirectTo)}
        className="button icon-button back w-inline-block"
      >
        <img
          sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
          // srcset="images/1024px-Feather-arrows-chevron-left.svg-p-500.png 500w, images/1024px-Feather-arrows-chevron-left.svg.png 1024w"
          src={ArrowChevronLeft1024}
          alt=""
          className="icon-button-icon"
        />
        <span>{backText}</span>
      </div>
    </div>
  );
};

export default PageBackButton;
