import { formatDistanceToNow } from "date-fns";
import { useEffect, useState } from "react";

const useDateFromNow = (timestamp) => {
  const [fromNowString, setFromNowString] = useState(
    formatDistanceToNow(timestamp, { includeSeconds: true })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDateFromNow hook.
      setFromNowString(formatDistanceToNow(timestamp, { includeSeconds: true }));
    }, 15 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  return {
    fromNowString,
  };
};

export default useDateFromNow;
