import { makeAutoObservable } from "mobx";
import { post, put } from "../../utils/api";

export class Ajaxable {
  /** System name (ex. qts, abs) */
  system;

  /** Service name (ex. products, clients) */
  service;

  constructor(system, service) {
    this.system = system;
    this.service = service;
  }

  /** Update an existing item. */
  async update(data) {
    const url = `/${this.service}s/${(data._id ?? data.sku)}`;
    return await put(url, data);
  };

  /** Create a new item. */
  // async create(data) {
  //   const url = `/${this.service}s`;
  //   return await post(url, data);
  // };
}