// import { observer } from "mobx-react-lite";
import React, { useCallback, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

const TextBoxField = ({
  title,
  field,
  setField = (val) => null,
  placeholder,
  maxLength,
  disabled = false,
  resize = "vertical",
  style = {},
  wrapperStyle = {},
  useDefaultValue = false,
  onBlur,
  validationSchema, // Yup validation schema
  tooltip
}) => {
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState("");
  const validate = useCallback(
    async (value, initial = false) => {
      if (!initial) setField(value);
      if (validationSchema) {
        try {
          await validationSchema.validate(value);
        } catch (err) {
          // throws validation error
          setMessage(err.errors[0]); // only get the first error, might change
        } finally {
          setValid(await validationSchema.isValid(value));
        }
      }
    },
    [validationSchema, setField]
  );

  useEffect(() => {
    if (validationSchema) {
      validate(field, true);
    }

    return () => {
      setValid(true);
      setMessage("");
    };
  }, [field, validate, validationSchema]);

  // useEffect(() => {

  // }, [valid, message]);

  return (
    <div className="form-block-9" style={wrapperStyle}>
      {title && <h5 className="heading-62">{title}</h5>}
      {useDefaultValue ? (
        <textarea
          name={title ? title : ""}
          placeholder={placeholder}
          className={`textarea w-input ${!valid ? "error" : ""}`}
          style={{ ...style, resize: resize, minHeight: "40px" }}
          defaultValue={field}
          disabled={disabled}
          onChange={(e) => validate(e.target.value)}
          onBlur={(e) => (onBlur ? onBlur(e.target.value) : null)}
          maxLength={maxLength}
          data-tip={tooltip}
        ></textarea>
      ) : (
        <textarea
          name={title ? title : ""}
          placeholder={placeholder}
          className={`textarea w-input ${!valid ? "error" : ""}`}
          style={{ ...style, resize: resize, minHeight: "40px" }}
          value={field}
          disabled={disabled}
          onChange={(e) => validate(e.target.value)}
          onBlur={(e) => (onBlur ? onBlur(e.target.value) : null)}
          maxLength={maxLength}
          data-tip={tooltip}
        ></textarea>
      )}
      {!valid && message && <h6 className="heading-64 error">{message}</h6>}
      {maxLength && (
        <h6 className="heading-64">
          {field.length} of {maxLength} characters used
        </h6>
      )}
      {tooltip && <ReactTooltip />}
    </div>
  );
};

export default TextBoxField;
