import React, { useEffect, useMemo, useCallback } from "react";
import { useRowSelect, useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import Pagination from "./Pagination";
import FilterTable from "./FilterTable";
import { CSSTransition } from "react-transition-group";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import DraggableRow from "./DraggableRow";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./styles.css";
import { ChevronUp } from "../../assets/images";
import Button from "../button/Button";

// TODO: 32 props and counting FeelsGoodMan (make related objects?)
const Table = ({
  DATA,
  COLUMNS,
  tableName,
  tabNames,
  pages,
  nextPage,
  currentPage,
  rowResults,
  setTabIndex,
  currentTab,
  loading,
  createText,
  hasSearch = true,
  includeComponent = null,
  hasCheckboxColumn = false,
  loadMore = null,
  createButtonClick = () => null,
  rowClick,
  buttons = [],
  draggable = false,
  setData = () => { },
  noBottom = false,
  showTabNames = true,
  onlyArrows = false,
  buttonsBottom = false,
  hiddenColumns = [],
  filter,
  setFilter,
  selectField,
  setSort = (column) => { },
  disableTransition = false,
  sort,
  noResultsText = "No rows found..."
}) => {
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [DATA]);
  const getRowId = useCallback((row, index, parent) => {
    return row._id || row.id || index;
  }, []);

  const createButton = { text: createText, onClick: createButtonClick };

  if (!buttons.some((e) => e.text === createText) && createText) {
    buttons.push(createButton);
  }

  const removePropagation = (e) => {
    if (!e) return;
    if (e.stopPropagation) e.stopPropagation();
    // console.log(e.target.checked);
  };

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" onClick={(e) => removePropagation(e)} ref={resolvedRef} {...rest} />
      </>
    );
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setPageSize,
    prepareRow,
    setGlobalFilter,
    page,
    pageCount,
    gotoPage,
    selectedFlatRows,
    state: { pageIndex, globalFilter, selectedRowIds, ...state },
  } = useTable(
    {
      columns,
      data,
      useGlobalFilter,
      disableMultiSort: !!sort, // TODO: Could implement this
      initialState: {
        hiddenColumns: hiddenColumns,
        pageSize: rowResults,
        sortBy: sort?.sortBy
          ? [
            {
              id: sort?.sortBy,
              desc: sort?.sortDirection === "desc",
            },
          ]
          : [],
      },
      getRowId,
      autoResetSelectedRows: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!hasCheckboxColumn) return;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          canSort: false,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="table-header-checkbox">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div onClick={(e) => removePropagation(e)} className="table-checkbox">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const moveRow = (dragIndex, hoverIndex) => {
    // console.log(`Dragged Index: ${dragIndex}`);
    // console.log(`Hovered Index: ${hoverIndex}`);
    // console.log(`--------------------------`);
    const newData = [...data];
    // const [movedRow] = newData.splice(dragIndex, 1);
    // newData.splice(hoverIndex, 0, movedRow);
    [newData[dragIndex], newData[hoverIndex]] = [newData[hoverIndex], newData[dragIndex]];
    // console.log(newData);
    setData(newData);
  };

  // const handleDragEnd = (result) => {
  //   const { source, destination } = result;
  //   // console.log(source);
  //   // console.log(destination);
  //   // console.log(`--------------------------`);
  //   if (!destination) return;
  //   if (source.index === destination.index) return;
  //   moveRow(source.index, destination.index);
  // };

  useEffect(() => {
    setPageSize(rowResults);
  }, [rowResults]);

  return (
    // May have to remove dc2 and wlg divs
    // <div className="w-layout-grid main-grid">
    <div className="w-tabs table-container">
      {/* Refactor to use TabbedContent component where tables are used */}
      {tabNames && tabNames.length > 0 && (
        <div className="in-page-menu w-tab-menu">
          {tabNames.map((item, index) => (
            <div
              key={index}
              onClick={() => setTabIndex(index + 1)}
              data-w-tab={`Tab ${index + 1}`}
              className={
                currentTab === index + 1
                  ? "in-page-menu-link-2 w-inline-block w-tab-link w--current"
                  : "in-page-menu-link-2 w-inline-block w-tab-link tab-link-not-active"
              }
            >
              <div className="inpage_menu_text">{item.heading}</div>
            </div>
          ))}
        </div>
      )}
      {/* TODO: prob better with a debounce for the transition */}
      <CSSTransition in={loading && !disableTransition} timeout={400} classNames="transition">
        <div className="tabs-content">
          <div data-w-tab={`Tab ${currentTab}`}>
            <div className="table-module-2">
              <div className="table-header-2 orderspage full-width">
                <div className="collection__title--wrraper space-between">
                  <h2 className="no-space-bottom-2">{`${tableName} ${tabNames && tabNames.length && showTabNames
                    ? `(${tabNames[currentTab - 1].heading})`
                    : ""
                    }`}</h2>
                  <div className="div-block-43 table-button-group flex">
                    {buttons &&
                      !buttonsBottom &&
                      buttons.map((b, index) => {
                        return (
                          <Button
                            key={index}
                            text={b.text}
                            classes="button-2 size__btn not-center create-button _8px"
                            action={() => b.onClick(rows, selectedFlatRows, selectedRowIds, state)}
                            disabled={b.disabled}
                          />
                        );
                      })}
                  </div>
                </div>
                {includeComponent && <>{includeComponent}</>}
                {hasSearch && (
                  <FilterTable
                    tableName={tableName}
                    filter={filter || globalFilter}
                    setFilter={setFilter || setGlobalFilter}
                    dropdown={selectField}
                  />
                )}
              </div>
              <div
                className="table-content-2"
                style={
                  draggable
                    ? { paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px" }
                    : {}
                }
              >
                <div
                  className="table-list-2"
                  style={
                    draggable
                      ? {
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#e1e6f0",
                        borderRadius: "10px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }
                      : {}
                  }
                >
                  {/* <DndProvider backend={HTML5Backend}> */}
                  {/* <DragDropContext onDragEnd={handleDragEnd}> */}
                  <table {...getTableProps()}>
                    <thead className="custom-header table-headers-2">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className="caption-large-2"
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              onClick={async () => {
                                await column.toggleSortBy();
                                console.log(column);
                                if (column?.clientSideSort) return; // TODO: Kinda dumb

                                if (!!sort && column.isSorted)
                                  setSort({
                                    sortBy: column.id,
                                    sortDirection: column.isSortedDesc ? "desc" : "asc",
                                  });
                                else setSort();
                              }}
                            >
                              {column.render("Header")}
                              <span className="material-icons md-16">
                                {/* {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""} */}
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? "arrow_downward"
                                    : "arrow_upward"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    {/* <Droppable droppableId={tableName}>
                        {(provided, snapshot) => ( */}
                    <tbody
                      // ref={provided.innerRef}
                      // {...provided.droppableProps}
                      {...getTableBodyProps()}
                    >
                      {/* TODO: Change from page to rows if not using pagination */}
                      {page.map((row, index) => {
                        prepareRow(row);
                        // console.log(row);
                        return draggable ? (
                          // <Draggable
                          //   draggableId={row.index.toString()}
                          //   key={row.index}
                          //   index={row.index}
                          // >
                          //   {(provided, snapshot) => {
                          //     return (
                          <tr
                            {...row.getRowProps()}
                            // {...provided.draggableProps}
                            className="table-row-2 coll-product-tab info table-row-link draggable-row"
                          // ref={provided.innerRef}
                          >
                            <td
                              className="draggable-cell"
                            // {...provided.dragHandleProps}
                            >
                              {/* <img src={Drag} alt="" height={30} /> */}
                              <div className="chevron-container">
                                <img
                                  src={ChevronUp}
                                  alt=""
                                  height={15}
                                  className={`${index - 1 >= 0 ? "chevron" : "chevron-disabled"}`}
                                  onClick={() => {
                                    if (index - 1 >= 0) moveRow(index, index - 1);
                                  }}
                                />
                                <img
                                  src={ChevronUp}
                                  className={`rotate-180 ${index + 1 < page.length ? "chevron" : "chevron-disabled"
                                    }`}
                                  alt=""
                                  onClick={() => {
                                    if (index + 1 < page.length) moveRow(index, index + 1);
                                  }}
                                  height={15}
                                />
                              </div>
                            </td>
                            {row.cells.map((cell) => {
                              if (cell.column.id === "drag") return null;
                              return (
                                <td
                                  className="product__text table-text draggable-row-td"
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        ) : (
                          // );
                          //   }}
                          // </Draggable>
                          <tr
                            className={rowClick ? "table-row-link" : "table-row-link unclickable"}
                            onClick={() => (rowClick ? rowClick(row.cells) : null)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td className="table-text product__text" {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                      {/* {provided.placeholder} */}
                    </tbody>
                    {/* )}
                      </Droppable> */}
                  </table>
                  {page.length === 0 && !loading && <h5 className="no-rows">{noResultsText}</h5>}
                  {page.length === 0 && loading && <h5 className="no-rows">Loading...</h5>}
                  {/* </DragDropContext> */}
                  {/* </DndProvider> */}
                </div>
              </div>
              <div className="table-bottom-caption-2">
                {!noBottom && !(page.length === 0 && !loading) && (
                  <>
                    {loadMore ? (
                      <div className="div-block-29">
                        <div className="div-block-54">
                          <div
                            onClick={() => loadMore()}
                            className="order_btn w-button load-more-button"
                          >
                            Load More...
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Pagination
                        pages={pages || pageCount}
                        goToPage={nextPage || gotoPage}
                        currentPage={currentPage || pageIndex}
                        onlyArrows={onlyArrows}
                        zeroBased={currentPage ? false : true}
                      />
                    )}
                  </>
                )}
                {buttonsBottom && (
                  <div className="w-col w-col-6 div-block-29 buttons">
                    {buttons.map((b, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            b.disabled
                              ? "button-2 size__btn not-center create-button _8px disabled"
                              : "button-2 size__btn not-center create-button _8px"
                          }
                          style={{ ...b.style }}
                          onClick={
                            !b.disabled
                              ? () => b.onClick(rows, selectedFlatRows, selectedRowIds, state)
                              : () => null
                          }
                        >
                          {b.text}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
    // </div>
  );
};

export default Table;
