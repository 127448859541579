import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import { useStores } from "../../../store";
import { sentenceCase, titleCase } from "../../../utils/string.utils";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import SelectField from "../../../components/select-field/SelectField";
import Table from "../../../components/table/Table";
import DeleteCell from "../../../components/table/DeleteCell";
import AddProductPopup from "../../../components/add-product-popup/AddProductPopup";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import StoreVisibility from "../../../components/store-visibility/StoreVisibility";
import TextBoxField from "../../../components/text-box-field/TextBoxField";
import CoverMediaTable from "../../../components/cover-media-table/CoverMediaTable";
import Button from "../../../components/button/Button";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import CheckboxField from "../../../components/checkbox-field/CheckboxField";
import StoreSelector from "../../../components/store-selector/StoreSelector";
import { genericNameSchema } from "../../../utils/validation.utils";
import {
  LOAD_MORE_INCREMENT,
  LOAD_MORE_TABLE_RESULTS,
  META_DESC_LENGTH,
  SEO_TITLE_LENGTH,
} from "../../../utils/number.utils";
import { get } from "../../../utils/api";
import { PRODUCT_BASE_URL } from "../../../store/productStore";

const CollectionDetails = () => {
  const { collection } = useParams();
  const { collectionStore, productStore, shopStore, uiStateStore, notificationStore, routeStore, accountStore } = useStores();
  const [searchParams] = useSearchParams();
  const isDuplicate = useMemo(() => searchParams?.get("duplicate"), [searchParams]);
  const isNew = useMemo(() => searchParams?.get("new"), [searchParams]);
  const isDuplicateOrNew = useMemo(() => isDuplicate || isNew, [isDuplicate, isNew]);
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [showCollection, setShowCollection] = useState(false);
  const [showPopular, setShowPopular] = useState(false);
  const [showFeature, setShowFeature] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [featured, setFeatured] = useState(undefined);

  const fetchFeatured = () => {
    if (uiStateStore.fetchingCurrentCollection) return;
    const featuredId = collectionStore.currentCollection.getFeatured(shopStore.currentStore);
    // const featuredId = collectionStore.currentCollection.currentFeatured;
    if (featuredId) {
      productStore.getProductById(featuredId).then(product => setFeatured(product));
    } else if (featuredId === undefined) {
      setFeatured(undefined);
    }
  }

  useEffect(
    fetchFeatured, 
    [
      shopStore.currentStore, 
      collectionStore.currentCollection, 
      uiStateStore.fetchingCurrentCollection,
      collectionStore.currentCollection.currentFeatured
    ]
  );

  useEffect(() => {
    if (!isNew) collectionStore.getCollection(collection);
  }, [isNew, collection]);

  useEffect(() => {
    if (collectionStore.currentCollection.products.length > 0 && !isNew) {
      collectionStore.loadCollectionProducts(collectionStore.currentCollection.products);
    }
  }, [collectionStore.currentCollection.products, isNew]);

  useEffect(() => {
    if (isDuplicate && !uiStateStore.fetchingCurrentCollection) {
      collectionStore.currentCollection.setName(
        `Duplicate of ${collectionStore.currentCollection.name}`
      );
      collectionStore.collectionSnapshot.setName(
        `Duplicate of ${collectionStore.currentCollection.name}`
      );
    }
  }, [collectionStore.currentCollection, isDuplicate, uiStateStore.fetchingCurrentCollection]);

  useEffect(() => {
    if (isNew && !uiStateStore.fetchingCurrentCollection) {
      collectionStore.resetCollections();
      collectionStore.currentCollection.resetEdits();
    }
  }, [isNew, uiStateStore.fetchingCurrentCollection]);

  useEffect(() => {
    if (collectionStore.currentCollection.edits > 0) {
      setDirty();
    }

    if (isSubmitted) {
      setClean();
      setIsSubmitted(false);
    }
  }, [collectionStore.currentCollection.edits]);

  const [Prompt, Banner, setDirty, setClean] = useUnsavedChanges(
    () => {
      collectionStore.currentToSnapshot();
      setClean();
    },
    async () => {
      if (isDuplicateOrNew) {
        notificationStore.createNotification(
          `Creating...`,
          `Collection ${collectionStore.currentCollection.name} is being created...`,
          "info"
        );
        const err = await collectionStore.createCollection();
        if (!err) {
          setClean();
          collectionStore.resetCollections();
          routeStore.redirect(`/collections`);
        }
      } else {
        notificationStore.createNotification(
          `Updating...`,
          `Update for ${collectionStore.currentCollection.name} pending...`,
          "info"
        );
        const err = await collectionStore.updateCollection();
        if (!err) {
          collectionStore.currentCollection.resetEdits();
          setClean();
          setIsSubmitted(true);
        }
      }
    }
  );

  const baseColumns = [
    {
      Header: "",
      accessor: "drag",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (e) =>
        (
          <em className="in-text-link" onClick={() => routeStore.newTab(`/products/${e.value}`)}>
            {e.value}
          </em>
        ) || <strong>DELETED PRODUCT</strong>,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => (
        <StatusText color={determineColor(e.value || "Deleted")} text={e.value || "Deleted"} />
      ),
    },
  ];

  const collectionColumns = [
    ...baseColumns,
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (e) => (
        <DeleteCell action={() => collectionStore.removeProduct(e.cell.row.values.name)} />
      ),
    },
  ];

  const popularColumns = [
    ...baseColumns,
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (e) => (
        <DeleteCell
          action={() => collectionStore.currentCollection.removeFullPopular(e.cell.row.values.name)}
        />
      ),
    },
  ];

  const storeButtons = useMemo(() => {
    const btnArray = [];
    const duplicateBtn = {
      onClick: () => {
        if (uiStateStore.hasDirtyData) setDuplicating(true);
        else {
          routeStore.newTab(
            `/collections/${collectionStore.currentCollection.name}?duplicate=true`
          );
        }
      },
      text: "Duplicate",
    };

    const viewLiveBtn = {
      onClick: () => {
        // TODO:
        const store = shopStore.stores.find(({ id }) => id === shopStore.currentStore);
        if (store.url === undefined) return;

        const collectionSlug = collectionStore.currentCollection.name.replace(/( |\/)/g, "-").toLowerCase();
        routeStore.newTab(`${store.url}/product-category/${collectionSlug}`);
      },
      text: "View Live",
      // disabled: shopStore.currentStore === "0"
    };

    const forceSaveBtn = {
      onClick: async () => {
        notificationStore.createNotification(
          `(DEBUG) Pushing product...`,
          `Pushing ${collectionStore.currentCollection.name} to all stores...`,
          "info"
        );

        await collectionStore.pushProduct();
      },
      text: "Force Push",
      disabled: accountStore.user.type !== "Developer" && !accountStore.user.type.endsWith("Administrator"),
      tooltip: "Push the product state as-is and force a store push"
    }

    if (!isDuplicateOrNew) {
      btnArray.push(duplicateBtn);
      btnArray.push(viewLiveBtn);
      btnArray.push(forceSaveBtn);
    }

    return btnArray;
  }, [
    routeStore,
    collectionStore.currentCollection.name,
    uiStateStore.hasDirtyData,
    isDuplicateOrNew,
  ]);

  // FIXME: Use currentProducts array, then update the products after
  return !uiStateStore.fetchingCurrentCollection ? (
    <>
      {Prompt}
      {Banner}
      <ConfirmModal
        open={duplicating}
        title="Duplicate Collection"
        message={`Open in a new tab and Duplicate without saving current changes?`}
        cancelText={"Cancel"}
        confirmText="Confirm"
        cancelAction={() => setDuplicating(false)}
        confirmAction={() => {
          routeStore.newTab(
            `collections/${encodeURIComponent(
              collectionStore.currentCollection.name
            )}?duplicate=true`
          );
        }}
      />
      <ConfirmModal
        open={isDeleting}
        title="Delete Collection"
        message={`Delete ${collectionStore.currentCollection.name}? This action cannot be undone.`}
        cancelText={"Cancel"}
        confirmText="Delete"
        cancelAction={() => setIsDeleting(false)}
        confirmAction={() => {
          collectionStore.deleteCollection();
          setIsDeleting(false);
          setClean();
          routeStore.redirect(`/collections`);
        }}
      />
      <div className="dashboard-container-2">
        <div className="horizontal-wrapper header">
          <PageBackButton redirectTo={`/collections`} backText={"Back"} />
          <StoreSelector />
        </div>
        <DetailHeader
          title={titleCase(collectionStore.currentCollection.name) || "New Collection"}
          subtitle={`ID# ${!isDuplicateOrNew ? collectionStore.currentCollection._id : "Generated on Save"
            }`}
          tags={[
            {
              component: (
                <StatusText
                  color={determineColor(
                    collectionStore.currentCollection.enabled ? "active" : "inactive"
                  )}
                  text={sentenceCase(
                    collectionStore.currentCollection.enabled ? "active" : "inactive"
                  )}
                  classes="status-2 header"
                />
              ),
            },
          ]}
          buttons={storeButtons}
        />
        {/* Featured Product Popup */}
        <AddProductPopup
          show={showFeature}
          title="Select Featured Product"
          setShow={(s) => setShowFeature(s)}
          rowClick={(row) => {
            collectionStore.currentCollection.setFullFeatured(row[0].row.original, false, shopStore.currentStore);
            setShowFeature(false);
          }}
        />
        {/* Collection Information */}
        <BoxedContent title={"Information"}>
          <StoreVisibility
            stores={collectionStore.currentCollection.stores}
            updateStores={(bool, store) =>
              collectionStore.currentCollection.updateStoreVisibility(bool, store)
            }
          />
          <div style={{ marginBottom: "20px" }}>
            <h5 className="heading-62">Collection Status</h5>
            <CheckboxField
              name={`collection-status`}
              label={`Active`}
              labelOff={`Inactive`}
              isChecked={collectionStore.currentCollection.enabled}
              setField={(t) => collectionStore.currentCollection.setEnabled(t)}
              useIsChecked
            />
          </div>
          <TextBoxField
            title={"Collection Name"}
            field={collectionStore.currentCollection.name}
            placeholder={"Enter collection name...    (cannot be changed once set)"}
            setField={(f) => collectionStore.currentCollection.setName(f)}
            disabled={!isDuplicateOrNew}
            validationSchema={genericNameSchema}
          />
          <>
            <h5 className="heading-62">
              Featured Product ({shopStore.currentStoreName})
            </h5>
            {
              !collectionStore.currentCollection.getFeatured(shopStore.currentStore) 
                && <h5 className="grey-sub-title">Tip: Select a product to be featured!</h5>
            }
            <div>
              <em
                className="in-text-link"
                onClick={() => routeStore.newTab(`/products/${featured._id}`)}
              >
                {featured?.name}
              </em>
              {!collectionStore.currentCollection.hasOverlaidFeatured(shopStore.currentStore) && " (inherited)"}
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button
                text={collectionStore.currentCollection.fullFeatured?.name ? "Change" : "Select"}
                style={{ marginBottom: "20px", backgroundColor: "#1d94d2" }}
                action={() => setShowFeature(true)}
              />
              {
                shopStore.currentStore !== "0" && collectionStore.currentCollection.hasOverlaidFeatured(shopStore.currentStore) && 
                <Button 
                  text="Remove" 
                  style={{ marginBottom: "20px" }}
                  action={() => {
                    collectionStore.currentCollection.setFeatured(undefined, shopStore.currentStore)
                    // TODO: Should be a better way to do this (specifically)
                    collectionStore.currentCollection.edited(); 
                  }}
                />
              }
            </div>
          </>
        </BoxedContent>
        <BoxedContent title={"Internal Notes"}>
          <TextBoxField
            field={collectionStore.currentCollection.notes}
            placeholder={"Enter internal notes..."}
            setField={(n) => collectionStore.currentCollection.setNotes(n)}
          />
        </BoxedContent>
        {/* Collection Products Table */}
        <AddProductPopup
          show={showCollection}
          title={`Add Collection Products`}
          setShow={(s) => setShowCollection(s)}
          rowClick={(cells) => {
            cells[0].row.toggleRowSelected();
          }}
          hasCheckbox
          buttons={[
            {
              text: "Add",
              onClick: async (_, __, rowIds) => {
                // HACK: React Table v7 does not sync selectedFlatRows with Row Ids
                // FIXME: Should probably implement custom select instead
                // Apparently fixed in v8, which is not ready for production yet
                Object.keys(rowIds)?.map(async (id, idx) => {
                  const res = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
                  collectionStore.addProduct(res.products[0]);
                  return null;
                });
                setShowCollection(false);
              },
              style: { minWidth: "100px", marginLeft: "0px" },
            },
          ]}
        />
        <Table
          tableName={"Collection Products"}
          DATA={collectionStore.currentProducts}
          COLUMNS={collectionColumns}
          loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
          hasSearch={false}
          createText={"Add"}
          rowResults={results}
          draggable
          loading={uiStateStore.fetchingCollectionProducts}
          setData={(d) => collectionStore.setCurrentProducts(d)}
          createButtonClick={() => setShowCollection(true)}
          noBottom={results >= collectionStore.currentProducts.length}
        />
        {/* Popular Products Table */}
        <AddProductPopup
          show={showPopular}
          title={`Add Popular Products`}
          setShow={(s) => setShowPopular(s)}
          rowClick={(cells) => {
            cells[0].row.toggleRowSelected();
          }}
          hasCheckbox
          buttons={[
            {
              text: "Add",
              onClick: async (_, __, rowIds) => {
                // HACK: React Table v7 does not sync selectedFlatRows with Row Ids
                // FIXME: Should probably implement custom select instead
                // Apparently fixed in v8, which is not ready for production yet
                Object.keys(rowIds)?.map(async (id, idx) => {
                  const res = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
                  collectionStore.currentCollection.addFullPopular(res.products[0]);
                  return null;
                });
                setShowPopular(false);
              },
              style: { minWidth: "100px", marginLeft: "0px" },
            },
          ]}
        />
        <Table
          tableName={"Popular Products"}
          DATA={collectionStore.currentCollection.fullPopular}
          COLUMNS={popularColumns}
          loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
          hasSearch={false}
          createText={"Add"}
          rowResults={results}
          draggable
          loading={uiStateStore.fetchingCurrentCollection}
          setData={(d) => collectionStore.currentCollection.setFullPopular(d)}
          createButtonClick={() => setShowPopular(true)}
          noBottom={results >= collectionStore.currentCollection.fullPopular.length}
        />
        <CoverMediaTable product={collectionStore.currentCollection} />
        <BoxedContent title={"Search Engine Listing (not implemented)"}>
          {/* <div className="div-block-38"> */}
          <div style={{ marginTop: "20px" }}>
            <h5 className="heading-62">Page Title</h5>
            <TextBoxField
              field={collectionStore.currentCollection.seoTitle}
              placeholder={"Enter page title..."}
              maxLength={SEO_TITLE_LENGTH}
              setField={(data) => collectionStore.currentCollection.setSeoTitle(data)}
            />
          </div>
          <>
            <h5 className="heading-62">Meta Description</h5>
            <TextBoxField
              field={collectionStore.currentCollection.seoDescription}
              placeholder={"Enter meta description..."}
              maxLength={META_DESC_LENGTH}
              setField={(data) => collectionStore.currentCollection.setSeoDescription(data)}
            />
          </>
          <>
            <h5 className="heading-62">URL Handle</h5>
            <TextBoxField
              field={collectionStore.currentCollection.seoSlug}
              placeholder={"Enter URL handle..."}
              setField={(data) => collectionStore.currentCollection.setSeoSlug(data)}
            />
          </>
          {/* </div> */}
        </BoxedContent>
        {!isDuplicateOrNew && (
          <Button text={`Delete Collection`} action={() => setIsDeleting(true)} />
        )}
      </div>
    </>
  ) : (
    <div className="dashboard-container-2">
      <DetailHeader title={"Loading Collection..."} subtitle={"Give us a second..."} />
    </div>
  );
};

export default observer(CollectionDetails);
