import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "../../../store";
import { useEffect, useState } from "react";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import BoxedContent from "../../../components/boxed-content/BoxedContent";

function QuoteDetails() {
  const { id } = useParams();
  const { quoteStore, uiStateStore, notificationStore } = useStores();
  const [ updating, setIsUpdating ] = useState(false);

  useEffect(() => {
    quoteStore.getQuote(id);
  }, [id]);

  async function archiveQuote() {
    if (updating) return;
    setIsUpdating(true);
    quoteStore.currentQuote.setArchived(!quoteStore.currentQuote.archived);
    await quoteStore.updateQuote();
    notificationStore.createNotification(
      "Success!",
      `Successfully ${quoteStore.currentQuote.archived ? "archiv" : "unarchiv"}ed quote.`,
      "success"
    )
    setIsUpdating(false);
  }

  return !uiStateStore.fetchingCurrentQuote ? (
    <div>
      <DetailHeader
        title={`Quote request`}
        subtitle={`from ${quoteStore.currentQuote.store}`}
        buttons={[{ 
          onClick: archiveQuote,
          text: `${quoteStore.currentQuote.archived ? "Unarchive" : "Archive"} Quote` 
        }]}
      />
      <BoxedContent>
        <h2>Contact information</h2>

        <div className="table-content-2">
          <div className="table-list-2">
            <div className="table-row-link-2 no-shadow w-inline-block">

              <div className="_2-grid job add-prod-table-row">
                <strong>Name</strong>
                <div>{quoteStore.currentQuote.name}</div>
              </div>

              <div className="_2-grid job add-prod-table-row">
                <strong>Email address</strong>
                <div>{quoteStore.currentQuote.email || "<none>"}</div>
              </div>

              <div className="_2-grid job add-prod-table-row">
                <strong>Phone number</strong>
                <div>{quoteStore.currentQuote.phone || "<none>"}</div>
              </div>
              
            </div>
          </div>
        </div>
      </BoxedContent>
      <BoxedContent>
        <h2>Quote details</h2>

        <div className="table-content-2">
          <div className="table-list-2">
            <div className="table-row-link-2 no-shadow w-inline-block">
              {Object.keys(quoteStore.currentQuote.fields).map(k => {
                return (
                  <div className="_2-grid job add-prod-table-row">
                    <strong>{k}</strong>
                    <div>{quoteStore.currentQuote.fields[k]}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </BoxedContent>
    </div>
  ) : (
    <div className="dashboard-container-2">
      <DetailHeader title={"Loading quote..."} subtitle={"Give us a second..."} />
    </div>
  );
}

export default observer(QuoteDetails);