import { makeAutoObservable, toJS } from "mobx";
import { getLastItemInUrl } from "../../utils/string.utils";

export class Quote {
  _id = "";
  store = "";
  name = "";
  email = "";
  phone = "";
  fields = {};
  archived = false;
  completed = false;

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, toJS(obj));
  }

  setArchived(v) {
    this.archived = v;
  }
  // TODO: Add notes, see client.js for reference on partial updates
  
}