import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useStores } from "../../store";
import { ProductDetail } from "../../store/domain/product-metadata/detail";
import { getLastItemInUrl, sentenceCase } from "../../utils/string.utils";
import Button from "../button/Button";
import FileUpload from "../file-upload-field/FileUpload";

const AddDetailPopup = ({ type, show, setShow }) => {
  const { metadataStore, notificationStore, productStore } = useStores();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [title2, setTitle2] = useState("");
  const [picture, setPicture] = useState("");
  const [upload, setUpload] = useState(false);

  const isReview = useMemo(() => type === "Review", [type]);

  const handleDiscard = useCallback(() => {
    setTitle("");
    setDesc("");
    setTitle2("");
    setPicture("");
    setShow(false);
  }, [setShow]);

  const handleCreate = useCallback(async () => {
    const valid = isReview ? title && desc && picture : title && desc;
    if (valid) {
      const res = isReview
        ? await metadataStore.createDetail({
          title: title,
          description: desc,
          type: type,
          title2: title2,
          picture: picture,
        })
        : await metadataStore.createDetail({ title: title, description: desc, type: type });
      res.fresh = true;
      switch (type) {
        case "Review":
          productStore.currentProduct.addFullReview(new ProductDetail(res));
          break;
        case "CloserLook":
          productStore.currentProduct.addFullCloserLook(new ProductDetail(res));
          break;
        case "Detail":
          productStore.currentProduct.addFullDetail(new ProductDetail(res));
          break;
        case "FAQ":
          productStore.currentProduct.addFullFaq(new ProductDetail(res));
          break;
        default:
          break;
      }
      productStore.currentProduct.detailsEdited++;
      handleDiscard();
    } else if (!title) {
      notificationStore.createNotification(`INVALID`, "Title is required!", "warning");
    } else if (!desc) {
      notificationStore.createNotification(`INVALID`, "Description is required!", "warning");
    } else {
      notificationStore.createNotification(`INVALID`, "Invalid Fields!", "warning");
    }
  }, [isReview, title, type, desc, title2, picture, handleDiscard, productStore.currentProduct]);

  if (!show) return null;

  return (
    <div className="modal-wrapper">
      <div className="custom-modal">
        <div className="modal-title">
          <h1 className="title">{`Add ${sentenceCase(type)}`}</h1>
        </div>
        <div className="form-block">
          <div className="field-item">
            <label htmlFor="detail-desc">{`${isReview ? "Reviewer Name" : sentenceCase(type) + " Title"
              }`}</label>
            <input
              type="text"
              className="field-input"
              name="detail-title"
              placeholder={`Enter ${isReview ? "Reviewer" : type} ${isReview ? "name" : "title"
                }...`}
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {isReview && (
            <div className="field-item">
              <label htmlFor="reviewer-title">Reviewer Title</label>

              <input
                type="text"
                className="field-input"
                name="reviewer-title"
                placeholder={`Enter Reviewer title...`}
                defaultValue={title2}
                onChange={(e) => setTitle2(e.target.value)}
              />
            </div>
          )}
          <div className="field-item">
            <label htmlFor="detail-desc">{`${sentenceCase(type)} Description`}</label>
            <textarea
              className="field-input"
              name="detail-desc"
              placeholder={`Enter ${type} description...`}
              defaultValue={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          {/* TODO: File Upload for review picture */}
          {isReview && (
            <div style={{ display: "flex", justifyContent: "flex-start", padding: "10px" }}>
              <Button
                text={"Upload Picture"}
                action={() => setUpload(true)}
                classes={`form-button w-button button-2`}
              />
              <span
                style={{ justifyContent: "center", marginLeft: "8px", marginTop: "3px" }}
              >{`Picture: ${getLastItemInUrl(picture) || "None"}`}</span>

              <FileUpload
                name={"pic-upload"}
                clicked={upload}
                resetClicked={() => setUpload(false)}
                handleUpload={(e) => setPicture(e.url)}
              />
            </div>
          )}
          <div className="button-wrapper padding">
            <div className="form-button w-button button-2" onClick={() => handleDiscard()}>
              Discard
            </div>
            <div className="form-button w-button button-2" onClick={() => handleCreate()}>
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddDetailPopup);
