import { post } from "../../../utils/api";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import Button from "../../../components/button/Button";
import { useState } from "react";
import { useStores } from "../../../store";
import TextBoxField from "../../../components/text-box-field/TextBoxField";

const Scraper = () => {
  const relativeTime = new Intl.RelativeTimeFormat("en-US", { numeric: "auto" });
  const { notificationStore, routeStore } = useStores();
  const [ url, setUrl ] = useState("");
  const [ debounce, setDebounce ] = useState(false);
  const [ res, setRes ] = useState(undefined);

  async function addToQueue() {
    if (debounce) return;
    setDebounce(true);
    try {
      if (url.trim().length === 0) {
        throw new Error("URL cannot be empty.");
      }
      setRes(undefined);
      console.log(`Sending ${url} to queue.`);
      const res = await post("/scraper", { url });
      console.log("Scraper API res", res);

      let nextScrapeRelative = undefined;
      if (res.nextScrape) {
        nextScrapeRelative = relativeTime.format(
          (new Date(res.nextScrape) - new Date()) / 1000 / 60,
          "minutes"
        );
      }

      notificationStore.createNotification(
        "Success!",
        ("Added link to scraper queue."
        + (nextScrapeRelative ? ` Next scrape is at ${nextScrapeRelative}.` : "")),
        "success"
      );
      setRes(res);
    } catch (e) {
      notificationStore.createNotification(
        "Error while adding to queue", 
        e.message ?? "Please contact the administrator.",
        "error",
        true
      )
    } finally {
      setDebounce(false);
    }
  }

  return (
    <div className="dashboard-container-2">
      <BoxedContent title="Scraper">
        <TextBoxField
          field={url}
          placeholder="Enter URL..."
          setField={(val) => setUrl(val)}
        />
        <Button
          text="Add to Queue"
          action={addToQueue}
          style={{ backgroundColor: "#1d94d2" }}
        />
        {( res !== undefined 
          && <Button 
            text={`View ${res.name ?? "spreadsheet"}`}
            action={() => routeStore.newTab(res.sheetUrl)}
            style={{ backgroundColor: "#4CAF50" }}
          />
        )}
      </BoxedContent>
    </div>
  )
}

export default Scraper;