import React from "react";
import "./styles.css";

const ConfirmModal = ({
  open,
  title,
  message,
  cancelText,
  confirmText,
  cancelAction,
  confirmAction,
}) => {
  if (!open) return null;

  return (
    <div className="modal-wrapper">
      <div className="table-module-2-copy confirm-action">
        <div className="table-header-2 confirm">
          <h2 className="no-space-bottom-2">{title}</h2>
          <div className="confirm-message">{message}</div>
          <div className="button-grid">
            <div onClick={() => cancelAction()} className="cancel-btn w-button">
              {cancelText}
            </div>
            <div onClick={() => confirmAction()} className="button-6 delete w-button">
              {confirmText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
