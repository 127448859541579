import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../store";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import format from "date-fns/format";
import TabbedContent from "../../../components/tabbed-content/TabbedContent";
import CustomerInformation from "../../../components/customer-information/CustomerInformation";
import OrderTable from "../../../components/order-table/OrderTable";
import { stringToFloat } from "../../../utils/number.utils";

const CustomerDetails = () => {
  const { client } = useParams();
  const { clientStore, shopStore, orderStore, uiStateStore } = useStores();

  useEffect(() => {
    clientStore.getClient(client);
  }, [client]);

  useEffect(() => {
    if (clientStore.currentClient._id === client) {
      orderStore
        .loadOrders("", `limit=100&search[client]=${clientStore.currentClient._id}`)
        .then(() => {
          let total = 0;
          const mostRecentDate = new Date(
            Math.max.apply(
              null,
              orderStore.orders.map((e) => {
                total += stringToFloat(e?.raw?.total);
                return new Date(e.createdAt);
              })
            )
          );
          clientStore.currentClient.totalSpend = total;
          const order = orderStore.orders.filter((e) => {
            var d = new Date(e.createdAt);
            return d.getTime() === mostRecentDate.getTime();
          })[0];

          if (order?.store && order?.raw?.id) {
            orderStore.getOrder(order?.store, order?.raw?.id);
            console.log(`CURRENT ORDER:`, order);
          }
        });
    }
  }, [clientStore.currentClient._id, client]);

  return !uiStateStore.fetchingCurrentClient ? (
    <div className="dashboard-container-2">
      <div className="horizontal-wrapper header">
        <PageBackButton redirectTo={`/customers`} backText={"Back"} />
      </div>
      <DetailHeader
        title={clientStore.currentClient.fullName}
        betweenTitles={
          <div style={{ display: "flex" }}>
            <div className="large-text" style={{ paddingRight: "20px" }}>
              {uiStateStore.fetchingOrderContent ? `Loading` : `${orderStore.totalOrders} order(s)`}
            </div>
            <div class="large-text">{`$${clientStore.currentClient.totalSpend.toFixed(2)}`}</div>
          </div>
        }
        subtitle={`Customer Since: ${format(
          new Date(clientStore.currentClient.createdAt || null),
          "PPPP"
        )}`}
        tags={[
          {
            component: (
              <span className="status-2 header product__text">
                <strong>Store:&nbsp;</strong>
                {`${
                  clientStore.currentClient.storeName
                  // || shopStore.stores.find((e) => e.id === clientStore.currentClient.store?.toString())?.name
                  // || "N/A"
                }`}
              </span>
            ),
          },
        ]}
        buttons={[
          { text: "Reset Password", onClick: () => null, disabled: true },
          { text: "Unsuspend Customer", onClick: () => null, disabled: true },
        ]}
      />
      <TabbedContent
        currentTab={uiStateStore.clientDetailTab}
        setTabIndex={(t) => uiStateStore.setClientDetailTab(t)}
      >
        <CustomerInformation
          label="Information"
          info={orderStore.currentOrder._id ? orderStore.currentOrder : clientStore.currentClient}
          client={clientStore.currentClient}
        />
        <OrderTable label="Orders" isClient />
      </TabbedContent>
    </div>
  ) : (
    <div className="dashboard-container-2">
      <DetailHeader title={`Loading Customer...`} subtitle={`Give us a second...`} />
    </div>
  );
};

export default observer(CustomerDetails);
