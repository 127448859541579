import React from "react";
import { RootStore } from "./rootStore";
import localforage from "localforage";
import { configurePersistable } from "mobx-persist-store";
import { isProd } from "../utils/api";

// Configure Local Forage for Stores
localforage.config({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
  name: "admin-dashboard-db",
});

// Configure mobx-persist-store
configurePersistable({
  storage: localforage,
  expireIn: 1000 * 60 * 60 * 24 * 7, // ms -> s -> h -> d* -> w -> y = 7 days,
  removeOnExpiration: true,
  stringify: false,
  debugMode: !isProd,
});

export const rootStore = new RootStore();

const RootStoreContext = React.createContext(rootStore);

/**
 * Allows components to access app-wide state.
 * @returns The root store as a context object that can be deconstructed for specific stores.
 */
export function useStores() {
  return React.useContext(RootStoreContext);
}
