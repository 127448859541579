import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import { useStores } from "../../../store";
import DeleteCell from "../../../components/table/DeleteCell";
import { useQuery } from "@tanstack/react-query";
import { get, put } from "../../../utils/api";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ONE_HOUR_MS = 60 * 60 * 1000;

const AddAbsencePopup = ({ show, setShow, onCreate }) => {
  const [start, setStart] = useState(undefined);
  const [end, setEnd] = useState(undefined);

  const handleDiscard = () => {
    setStart(undefined);
    setEnd(undefined);
    setShow(false);
  }

  const handleCreate = () => {
    onCreate(start.getTime(), end.getTime())
    setStart(undefined);
    setEnd(undefined);
    setShow(false);
  }

  if (!show) return <></>;


  return (
    <div className="modal-wrapper">
      <div className="custom-modal">
        
        <div className="modal-title">
          <h1 className="title">Add absence</h1>
        </div>

        <div className="form-block">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="field-item">
              <label htmlFor="start" style={{ paddingRight: "1em", verticalAlign: "middle" }}>Start:</label>
              <DateTimePicker 
                id="start" 
                // placeholder="Start date and time" 
                label="Start date and time"
                slotProps={{ popper: { disablePortal: true }}}
                minDate={new Date()}
                // showNow 
                onChange={value => setStart(value)}
                // use12Hours
                // showHour
                // showMinute
              />
            </div>

            <div className="field-item" style={{ verticalAlign: "middle", paddingTop: "1em" }}>
              <label htmlFor="end" style={{ paddingRight: "1.5em", verticalAlign: "middle" }}>End:</label>
              <DateTimePicker 
                id="end" 
                label="End date and time"
                slotProps={{ popper: { disablePortal: true }}}
                minDateTime={start && new Date(start.getTime() + ONE_HOUR_MS)}
                disabled={start === undefined}
                // showNow 
                onChange={value => setEnd(value)}
                // use12Hours
                // showHour
                // showMinute
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className="button-wrapper padding">
          <div className="form-button w-button button-2" onClick={() => handleDiscard()}>
            Discard
          </div>
          <div className="form-button w-button button-2" onClick={() => handleCreate()}>
            Create
          </div>
        </div>

      </div>
        
    </div>
  )
}

const Absences = () => {
  const { userStore, notificationStore } = useStores();
  const [show, setShow] = useState(false);
  
  const absences = useQuery({
    queryKey: [`absences@${userStore.currentUser._id}`],
    queryFn: () => get(`/abs/absences/${userStore.currentUser._id}`)
  });

  const onCreate = (start, end) => {
    notificationStore.createNotification("ADDING", "Adding absence...", "info");
    put(`/abs/absences/${userStore.currentUser._id}`, { start, end })
      .then(() => absences.refetch())
      .finally(() => notificationStore.createNotification("SUCCESS", "Absence successfully added!", "success"));
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id"
    },
    {
      Header: "Start",
      accessor: "start",
      Cell: e => (new Date(e.value))?.toLocaleString(),
    },
    {
      Header: "End",
      accessor: "end",
      Cell: e => (new Date(e.value))?.toLocaleString(),
    },
    // {
    //   Header: "",
    //   accessor: "delete",
    //   Cell: (e) => <DeleteCell action={() => userStore.currentUser.removeZone(e.row.original)} />,
    // },
  ];

  if (absences.isPending) {
    return <>Loading...</>
  } else if (absences.isError) {
    return <>Error loading absences</>
  }

  return (
    <>
      <AddAbsencePopup show={show} setShow={setShow} onCreate={onCreate} />
      <Table
        tableName={`Absences`}
        DATA={absences.data instanceof Array ? absences.data : []}
        COLUMNS={columns}
        rowResults={25}
        createText={"Add absence"}
        createButtonClick={() => setShow(true)}
      />
    </>
  );
};

export default observer(Absences);
