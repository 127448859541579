import React from "react";

const ContentModule = ({ image, caption, extraStyles, value, error = false }) => {
  return (
    <div className={error ? "module error" : "module"}>
      <div className="content-chart">
        <div className="chart-square">
          <img src={image} loading="lazy" alt="" className="icon" />
          <div className={`light-fill ${extraStyles}`}></div>
        </div>
        <div className="div-block">
          <div className="caption">{caption}</div>
          <div className="numbers-wrapper">
            <h3 className="number">{value}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentModule;
