import React from "react";
import Button from "../button/Button";
import "./styles.css";

const DetailHeader = ({ title, betweenTitles, subtitle, tags, buttons }) => {
  return (
    <div className="border-block header">
      <div className="_50-perblock width50-per">
        <h1 className="no-margin">{title}</h1>
        {betweenTitles && <div>{betweenTitles}</div>}
        <div className="large-text text-grey-3 _35px-margin">{subtitle}</div>
        {tags && (
          <div className="horizontal-wrapper from-left">
            {tags.map((t, idx) => {
              return t.component ? (
                <div key={idx}>{t.component}</div>
              ) : t.text ? (
                <div key={t.text} className="status-2 header">
                  <div className="product__text">{t.text}</div>
                </div>
              ) : null;
            })}
          </div>
        )}
      </div>
      {buttons && (
        <div className="horizontal-wrapper from-right header-button-container">
          {buttons.map((b, idx) => {
            return (
              <Button
                key={`${b.text}${idx}`}
                text={b.text}
                classes={"button-2 not-center size__btn large w-button header-button"}
                action={() => b.onClick()}
                disabled={b.disabled}
                tooltip={b.tooltip}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DetailHeader;
