import React, { useEffect, useState } from "react";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import { logout } from "../../utils/api";
import { observer } from "mobx-react-lite";
import { useStores } from "../../store";

const LogoutWrapper = () => {
  const { uiStateStore } = useStores();
  const messageBase = "Are you sure you want to logout?";
  const [message, setMessage] = useState(messageBase);

  useEffect(() => {
    uiStateStore.hasDirtyData
      ? setMessage(message + " You have unsaved changes!")
      : setMessage(messageBase);
  }, [uiStateStore.hasDirtyData]);

  return (
    <ConfirmModal
      title={"Confirm Logout"}
      message={message}
      cancelText="Cancel"
      confirmText={"Logout"}
      open={uiStateStore.logoutModalOpen}
      cancelAction={() => uiStateStore.setLogoutModal(false)}
      confirmAction={() => {
        uiStateStore.setDirtyData(false);
        uiStateStore.setLogoutModal(false);
        logout().then(() => {
          window.location.assign("/");
        });
      }}
    />
  );
};

export default observer(LogoutWrapper);
