export const userTableColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "User ID",
    accessor: "_id", // accessor is the "key" in the data
  },
  {
    Header: "Email",
    accessor: "email",
  },
  // {
  //   Header: "User Type",
  //   accessor: "type",
  // },
  // {
  //   Header: "User Status",
  //   accessor: "status",
  // },
];
