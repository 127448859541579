import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../utils/api";
import LoginAlert from "./LoginAlert";

import "./styles.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const checkboxClasses = "w-checkbox-input w-checkbox-input--inputType-custom checkbox text-right";

  const handleSubmit = (evt) => {
    evt.preventDefault();
    login(email, password)
      .then(() => {
        navigate(window.location.pathname, { replace: true });
        window.location.reload(true);
      })
      .catch(() => {
        setShowAlert(true);
      });
  };

  useEffect(() => {
    document.body.classList.add("authentication-page");

    return () => document.body.classList.remove("authentication-page");
  }, []);

  return (
    <>
      <div className="authentication-module">
        <h2>Sign in</h2>
        <div className="authentication-form-block w-form">
          <form
            name="wf-form-Form"
            className="authentication-form"
            aria-label="Form"
            onSubmit={handleSubmit}
          >
            <div className="w-layout-grid authentication-grid">
              <div>
                <input
                  type="email"
                  className="authentication-field w-input"
                  maxLength="256"
                  name="Email"
                  data-name="Email"
                  placeholder="Email"
                  id="Email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="password"
                  className="authentication-field w-input"
                  maxLength="256"
                  name="Password"
                  data-name="Password"
                  placeholder="Password"
                  id="Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <input
                type="submit"
                value="Sign In"
                data-wait="Please wait..."
                className="button button-small w-button"
              />
              {/* <label className="w-checkbox remember-me-field">
                <div
                  onClick={(e) => setRememberMe(!rememberMe)}
                  className={rememberMe ? checkboxClasses + " checked" : checkboxClasses}
                ></div>
                <input
                  type="checkbox"
                  id="checkbox-2"
                  name="checkbox-2"
                  className="dummy-checkbox"
                />
                <span
                  onClick={(e) => setRememberMe(!rememberMe)}
                  className="checkbox-label w-form-label"
                  htmlFor="checkbox-2"
                >
                  Remember me
                </span>
              </label> */}
              {/* <a href="../authentication/password-reset.html" class="small-link">Forgot your password?</a> */}
            </div>
          </form>
        </div>
      </div>
      <LoginAlert show={showAlert} setShow={setShowAlert} />
    </>
  );
};

export default observer(Login);
