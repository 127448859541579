import equal from "fast-deep-equal/es6";
import { makeAutoObservable, toJS } from "mobx";

export class AddressInfo {
  address_1 = "";
  address_2 = "";
  city = "";
  company = "";
  country = "";
  email = "";
  first_name = "";
  last_name = "";
  phone = "";
  postcode = "";
  state = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }

  compare(obj) {
    const item = toJS(obj);
    const js = toJS(this);
    return equal(js, item);
  }

  get customerFullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  get address() {
    return `${this.address_1}${this.address_2 ? `, ${this.address_2}` : ""}`;
  }

  get location() {
    return `${this.city}, ${this.state}, ${this.postcode}`;
  }

  get fullAddressLine() {
    return `${this.address}, ${this.location}`;
  }
}
