import Orders from "./orders/Orders";
import Products from "./products/Products";
import NotFound from "../components/not-found/NotFound";
import Customers from "./customers/Customers";
import Users from "./users/Users";
import Collections from "./collections/Collections";
import ProductAddOns from "./products/add-ons/ProductAddOns";
import ProductAttributes from "./products/attributes/ProductAttributes";
import ProductTypes from "./products/types/ProductTypes";
import ProtectedRoutes from "./ProtectedRoutes";
import AgentDatabase from "./customers/agent-database/AgentDatabase";
import PropertyWebpages from "./products/property-webpages/PropertyWebpages";
import ProductDetails from "./products/product-details/ProductDetails";
import DecodedBreadcrumb from "../components/breadcrumbs/DecodedBreadcrumb";
import AddOnDetails from "./products/add-ons/AddOnDetails";
import ProductTypeDetails from "./products/types/ProductTypeDetails";
import Stores from "./stores/Stores";
import Discounts from "./discounts/Discounts";
import Analytics from "./analytics/Analytics";
import Jobs from "./jobs/Jobs";
import OrderDetails from "./orders/order-details/OrderDetails";
import CustomerDetails from "./customers/customer-details/CustomerDetails";
import CollectionDetails from "./collections/collection-details/CollectionDetails";
import UserDetails from "./users/user-details/UserDetails";
import Settings from "./settings/Settings";
import Scraper from "./applications/scraper/Scraper";
import Quotes from "./quotes/Quotes";
import QuoteDetails from "./quotes/quotes-details/QuoteDetails";

const routes = [
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      { index: true, element: <Orders />, breadcrumb: "Home" },
      { path: "/orders", element: <Orders /> },
      {
        path: "/orders/:orderId",
        element: <OrderDetails />,
        breadcrumb: DecodedBreadcrumb, // TODO: Breadcrumb for Orders
        props: { value: "orderId" },
      },
      { path: "/products", element: <Products /> },
      {
        path: "/products/:name",
        element: <ProductDetails />,
        breadcrumb: DecodedBreadcrumb,
        props: { value: "name" },
      },
      // {
      //   path: "/products/:name/:attribute",
      //   element: <AttributeDetails />,
      //   breadcrumb: DecodedBreadcrumb,
      //   props: { value: "attribute" },
      // },
      { path: "/customers", element: <Customers /> },
      {
        path: "/customers/:client",
        element: <CustomerDetails />,
        breadcrumb: DecodedBreadcrumb,
        props: { value: "client" },
      }, // TODO: Breadcrumb for Customers
      { path: "/agent-database", element: <AgentDatabase />, breadcrumb: "Agent Database" },
      { path: "/users", element: <Users /> },
      {
        path: "/users/:user",
        element: <UserDetails />,
        breadcrumb: DecodedBreadcrumb,
        props: { value: "user" },
      }, // TODO: Breadcrumb for users
      { path: "/collections", element: <Collections /> },
      {
        path: "/collections/:collection",
        element: <CollectionDetails />,
        breadcrumb: DecodedBreadcrumb,
        props: { value: "collection" },
      },
      { path: "/product-add-ons", element: <ProductAddOns />, breadcrumb: "Product Add-Ons" },
      {
        path: "/product-add-ons/:addon",
        element: <AddOnDetails />,
        breadcrumb: DecodedBreadcrumb,
        props: { value: "addon" },
      }, // TODO: Breadcrumb for addons
      {
        path: "/product-attributes",
        element: <ProductAttributes />,
        breadcrumb: "Product Attributes",
      },
      { path: "/product-types", element: <ProductTypes />, breadcrumb: "Product Types" },
      {
        path: "/product-types/:type",
        element: <ProductTypeDetails />,
        breadcrumb: DecodedBreadcrumb,
        props: { value: "type" },
      },
      // {
      //   path: "/product-types/:type/:attribute",
      //   element: <AttributeDetails />,
      //   breadcrumb: DecodedBreadcrumb,
      //   props: { value: "attribute" },
      // },
      {
        path: "/property-webpages",
        element: <PropertyWebpages />,
        breadcrumb: "Property Webpages",
      },
      { path: "/stores", element: <Stores /> },
      { path: "/discounts", element: <Discounts /> },
      { path: "/analytics", element: <Analytics /> },
      { path: "/jobs", element: <Jobs /> },
      { path: "/account", element: <UserDetails isCurrentAccount /> },
      { path: "/scraper", element: <Scraper /> },
      { path: "/admin-settings", element: <Settings />, breadcrumb: "Admin Settings" },
      { path: "/quotes", element: <Quotes /> },
      { path: "/quotes/:id", element: <QuoteDetails /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];

export default routes;
