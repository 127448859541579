import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import AddProductPopup from "../../../components/add-product-popup/AddProductPopup";
import DeleteCell from "../../../components/table/DeleteCell";
import Table from "../../../components/table/Table";
// import DeleteCell from "../../../components/table/DeleteCell";
import { useStores } from "../../../store";
import { PRODUCT_BASE_URL } from "../../../store/productStore";
import { get } from "../../../utils/api";
import { sentenceCase } from "../../../utils/string.utils";

const ProServices = () => {
  const { userStore, serviceProStore, uiStateStore } = useStores();
  const [show, setShow] = useState(false);

  const columns = [
    // { Header: "E-Service ID", accessor: "eservice_id" },
    // { Header: "P-Service ID", accessor: "pservice_id" },
    { Header: "Service", accessor: (data) => data?.pservice_name || data?.name },
    {
      Header: "Pay",
      accessor: (data) => data?.pservice_charge || data?.price,
      Cell: (e) => e.value || "0.00",
    },
    { Header: "Type", accessor: "type", Cell: (e) => sentenceCase(e.value) },
    {
      Header: "",
      accessor: "delete",
      Cell: (e) => (
        <DeleteCell
          action={() => userStore.currentUser.removeFullService(e.row.original)}
          disabled={!e.row.original?.editable}
          tooltip={
            !e.row.original?.editable
              ? "Please contact an administrator to remove this service"
              : ""
          }
        />
      ),
    },
  ];

  return (
    <>
      {/* TODO: Create an AddServicePopup component */}
      <AddProductPopup
        show={show}
        setShow={(e) => setShow(e)}
        search={"search[type]=^(BookingService).*$&limit=10"}
        rowClick={(cells) => {
          cells[0].row.toggleRowSelected();
        }}
        hasCheckbox
        buttons={[
          {
            text: "Add",
            onClick: async (_, __, rowIds) => {
              // HACK: React Table v7 does not sync selectedFlatRows with Row Ids
              // FIXME: Should probably implement custom select instead
              // Apparently fixed in v8, which is not ready for production yet
              Object.keys(rowIds)?.map(async (id, idx) => {
                const res = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
                userStore.currentUser.addFullService(res.products[0]);
                return null;
              });
              setShow(false);
            },
            style: { minWidth: "100px", marginLeft: "0px" },
          },
        ]}
      />
      <Table
        tableName={"Pro Services"}
        DATA={userStore.currentUser.fullServices}
        loading={uiStateStore.fetchingProServices}
        COLUMNS={columns}
        createText={"Add Service"}
        createButtonClick={() => setShow(true)}
        rowResults={25}
      />
    </>
  );
};

export default observer(ProServices);
