// import { makeObservable, observable } from "mobx";

import { makeAutoObservable } from "mobx";
import { AddressInfo, Job, LineItem, Raw, TimelineItem } from "./order-metadata";
import { Client } from "./client";

export class Order {
  client = new Client();
  createdAt;
  updatedAt;
  id = "";
  jobs = [];
  lineItems = [];
  raw = new Raw(); // TODO: temp solution
  shipping = new AddressInfo();
  status = "";
  store;
  timeline = [];
  _id = "";
  url = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
    this.raw = new Raw(this.raw);
    this.jobs.map((job) => new Job(job));
    this.lineItems.map((item) => new LineItem(item));
    this.shipping = new AddressInfo(this.shipping);
    this.timeline.map((item) => new TimelineItem(item));
  }
}
