import { makeAutoObservable } from "mobx";
import { AddressInfo } from "./addressInfo";
import { RawMetadata } from "./metadata";
import { LineItem } from "./lineItem";

export class RawLinks {
  collection = [{ href: "" }];
  self = [{ href: "" }];

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }
}

export class Raw {
  billing = new AddressInfo();
  cart_hash = "";
  cart_tax = "";
  coupon_lines = [];
  created_via = "";
  currency = "";
  currency_symbol = "";
  customer_id = 0;
  customer_ip_address = "";
  customer_note = "";
  customer_user_agent = "";
  date_completed = "";
  date_completed_gmt = "";
  date_created = "";
  date_created_gmt = "";
  date_modified = "";
  date_modified_gmt = "";
  date_paid = "";
  date_paid_gmt = "";
  discount_tax = "";
  discount_total = "";
  fee_lines = [];
  id = "";
  line_items = [];
  meta_data = [];
  number = "";
  order_key = "";
  parent_id = "";
  payment_method = "";
  payment_method_title = "";
  prices_include_tax = false;
  refunds = [];
  shipping = new AddressInfo();
  shipping_lines = [];
  shipping_tax = "";
  shipping_total = "";
  status = "";
  tax_lines = [];
  total = "";
  total_tax = "";
  transaction_id = "";
  _links = new RawLinks();

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
    this.billing = new AddressInfo(this.billing);
    this.shipping = new AddressInfo(this.shipping);
    this.meta_data.map((data) => new RawMetadata(data));
    this._links = new RawLinks(this._links);
    this.line_items.map((data) => new LineItem(data));
  }
}
