import { makeAutoObservable } from "mobx";
import { OrderMetadata } from "./metadata";

export class JobStep {
  at;
  messages = [];
  name = "";
  state = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }
}

export class Job {
  id = "";
  metadata = [];
  steps = [];
  service = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
    this.metadata.map((data) => new OrderMetadata(data));
    this.steps.map((step) => new JobStep(step));
  }
}
