import { action, computed, makeObservable, observable } from "mobx";
import { Notification } from "./domain/notification";

export class NotificationStore {
  notifications = [];
  displayed = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      notifications: observable,
      displayed: observable,
      createNotification: action,
      alertNotifications: computed,
      unreadNotifications: computed,
      addDisplayed: action,
      markAsRead: action,
      markAllAsRead: action,
    });
  }

  get alertNotifications() {
    const alerts = this.notifications.filter((n) => n.displayAlert);
    return alerts;
  }

  get unreadNotifications() {
    const alerts = this.notifications.filter((n) => !n.read);
    return alerts;
  }

  addDisplayed(uuid) {
    this.displayed.unshift(uuid);
  }

  createNotification(title, description, type = "info", error = undefined, displayAlert = true) {
    const notification = new Notification({
      title,
      description,
      type,
      displayAlert,
      stackTrace: error ? error?.message : undefined,
    });

    this.notifications.unshift(notification);

    return notification;
  }

  markAsRead(uuid) {
    const notification = this.notifications.find((n) => n.uuid === uuid);
    notification.read = true;
    return notification;
  }

  markAllAsRead() {
    this.notifications = this.notifications.map((n) => {
      n.read = true;
      return n;
    });
  }
}
