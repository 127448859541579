import { makeAutoObservable } from "mobx";
import { post, put } from "../../utils/api";
import { Ajaxable } from "./ajaxable";

export class Client {
  static service = "client";

  ajax = new Ajaxable("qts", "client");
  _id = "";
  firstName = "";
  lastName = "";
  phoneNumber = "";
  email = "";
  store = "";
  createdAt = "";
  storeName = "N/A";
  contentUrl = "";
  totalSpend = 0;
  type = "free";
  commitment = "";
  term = "";
  hasSigned = false;

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  setType(type) {
    this.type = type;
    this.ajax.update({ _id: this._id, type });
    // TODO: Notify on change
  }

  setCommitment(commitment, commit = false) {
    this.commitment = commitment;
    if (commit) {
      this.ajax.update({ _id: this._id, commitment });
    }
  }
  
  setTerm(term, commit = false) {
    this.term = term;
    if (commit) {
      this.ajax.update({ _id: this._id, term });
    }
  }
}
