import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Table from "../table/Table";
import { useStores } from "../../store";
import { addOnTableColumns } from "../add-on-table/addOnTableColumns";

const AddonPopup = ({
  show,
  title = "Add Addons",
  setShow,
  // search,
  filter,
  rowClick,
  hasCheckbox = false,
  buttons = [],
}) => {
  const { metadataStore } = useStores();

  const columns = addOnTableColumns.filter((col) => col.Header !== "SKU");

  useEffect(() => {
    metadataStore.loadAddons();
  }, []);

  if (!show) return null;

  return (
    <div className="modal-wrapper">
      <Table
        tableName={title}
        hasSearch
        COLUMNS={columns}
        rowResults={10}
        DATA={!filter ? metadataStore.addons : metadataStore.addons.filter((s) => filter(s))}
        onlyArrows
        rowClick={rowClick ? (cells) => rowClick(cells) : null}
        buttons={[
          {
            text: "Cancel",
            onClick: () => setShow(false),
            style: {
              borderColor: "grey",
              backgroundColor: "transparent",
              color: "#000",
              borderWidth: "1px",
              borderStyle: "solid",
            },
          },
          ...buttons,
        ]}
        buttonsBottom
        hasCheckboxColumn={hasCheckbox}
      />
    </div>
  );
};

export default observer(AddonPopup);
