import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import Table from "../table/Table";
import { productTableColumns } from "../product-table/productTableColumns";
import { useStores } from "../../store";
import ProductTableDropdown from "../product-table/ProductTableDropdown";

const LIMIT = 10;
// FIXME: WET Code
const AddProductPopup = ({
  show,
  title = "Add Products",
  setShow,
  search,
  rowClick,
  hasCheckbox = false,
  buttons = [],
  usePerPage = false,
}) => {
  const { productStore, uiStateStore } = useStores();

  const columns = productTableColumns.filter((col) => col.Header !== "SKU");

  const limit = useMemo(
    () => (usePerPage ? productStore.perPage : LIMIT),
    [usePerPage, productStore.perPage]
  );

  function nextPage(pageNumber) {
    productStore.setCurrentPage(pageNumber);
  }

  useEffect(() => {
    productStore.setCurrentPage(1);
  }, [uiStateStore.productTableFilterText]);

  useEffect(() => {
    const searchString = uiStateStore.productTableFilterText
      ? `&search[${uiStateStore.productTableFilterBy}]=${uiStateStore.productTableFilterText}`
      : "";
    productStore.loadProducts(
      "",
      search ? `${search}${searchString}` : `${searchString}&limit=${limit}`,
      uiStateStore.productTableSort
    );
  }, [
    productStore.currentPage,
    uiStateStore.productTableFilterBy,
    uiStateStore.productTableFilterText,
    search,
    uiStateStore.productTableSort,
  ]);

  if (!show) return null;

  return (
    <div className="modal-wrapper">
      <Table
        tableName={title}
        hasSearch
        COLUMNS={columns}
        rowResults={limit || LIMIT}
        DATA={productStore.products}
        pages={productStore.totalPages}
        loading={uiStateStore.fetchingProductContent}
        onlyArrows
        rowClick={rowClick ? (cells) => rowClick(cells) : null}
        currentPage={productStore.currentPage}
        nextPage={nextPage}
        buttons={[
          {
            text: "Cancel",
            onClick: () => setShow(false),
            style: {
              borderColor: "grey",
              backgroundColor: "transparent",
              color: "#000",
              borderWidth: "1px",
              borderStyle: "solid",
            },
          },
          ...buttons,
        ]}
        buttonsBottom
        hasCheckboxColumn={hasCheckbox}
        selectField={<ProductTableDropdown />}
        filter={uiStateStore.productTableFilterText}
        setFilter={(text) => uiStateStore.setProductTableFilterText(text)}
        disableTransition
        sort={uiStateStore.productTableSort || uiStateStore.productTableSort.sortBy}
        setSort={(sort) => uiStateStore.setProductTableSort(sort)}
      />
    </div>
  );
};

export default observer(AddProductPopup);
