import { makeAutoObservable, runInAction } from "mobx";
import { deleteAt, get, post, put } from "../utils/api";
import { Product } from "./domain/product";
import { createSkuFromName, titleCase } from "../utils/string.utils";
import { SORT_TYPE } from "../utils/select.utils";
import { useQuery } from "@tanstack/react-query";

export const PRODUCT_BASE_URL = "products/";
const TYPE_URL = `&search[type]=^`;
const PRODUCT_TYPE_URL = `product-type/`;

// TODO: Change to async/await in all stores
export class ProductStore {
  /**
   * Contains the products returned by the API, loaded with loadProducts
   */
  products = [];

  /**
   * The number of pages of data available based on the perPage request sent to the API.
   */
  totalPages = 0;

  /**
   * The current page of data that is to be fetched and stored in products array.
   */
  currentPage = 1;

  /**
   * The current type of products in the product array.
   */
  productType = "";

  perPage = 0;

  linkedSkus = [{ text: "Loading...", value: "" }];

  printProducts = [{ text: "Loading...", value: "" }]

  currentProduct = new Product();

  productSnapshot = new Product();

  typeList = [];

  totalProducts = 0;

  currentLinks = { editUrl: "", permalink: "" };

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  // TODO: Adjust function to take the rest of the API parameters
  /**
   * Fetch data from the API and load it into the products array upon completion.
   *
   * @param {string} type The type of product to fetch from the API.
   */
  async loadProducts(type, rawSearch, sort = SORT_TYPE) {
    this.products = [];
    this.totalPages = 0;
    this.perPage = 0;
    this.rootStore.uiStateStore.setFetchingProductContent(true);

    if (this.productType !== type) this.currentPage = 1;

    this.productType = type;

    const baseUrl =
      PRODUCT_BASE_URL +
      `?page=${this.currentPage}${sort.sortBy ? `&sort[${sort.sortBy}]=${sort.sortDirection}` : ""
      }`;
    const fetchUrl = rawSearch
      ? `${baseUrl}&${rawSearch}`
      : type === "All"
        ? baseUrl + "&limit=25"
        : baseUrl + TYPE_URL + this.productType + "$&limit=25";

    get(`${fetchUrl}`)
      .then((res) => {
        if (type !== this.productType) return;
        if (!this.rootStore.uiStateStore.fetchingProductContent) return; // if we aren't fetching, another req happened
        this.setProps(res);
        // console.log(`Total Pages`, this.totalPages);
        // console.log(res);
      })
      .catch((err) => {
        this.rootStore.uiStateStore.setFetchingProductContent(false);
        this.rootStore.notificationStore.createNotification(
          `ERROR`,
          "Could not load products!",
          "error"
        );
      });
  }

  // TODO: REVISIT HOW THESE ARE FETCHED
  async loadLinkedSkus() {
    this.rootStore.uiStateStore.setFetchingLinkedSkus(true);
    let products = [];
    let page = 0;
    while (true) {
      try {
        const res = await get(`${PRODUCT_BASE_URL}?search[type]=^Sina$&limit=100&page=${++page}`);
        if (res.products.length === 0) break;
        products = [ ...products, ...res.products ];
      } catch (e) {
        console.error(e);
      }
    }
    this.setLinkedSkus(products);
    // get(`${PRODUCT_BASE_URL}?search[type]=^Sina$&limit=100`)
    //   .then((res) => {
    //     if (!this.rootStore.uiStateStore.fetchingLinkedSkus) return;
    //     this.setLinkedSkus(res);
    //   })
    //   .catch(() => {
    //     // caca
    //   });
  }

  async loadPrintProducts() {
    this.rootStore.uiStateStore.setFetchingLinkedSkus(true);
    get(`${PRODUCT_BASE_URL}?search[type]=^(Sina|Repackage)$&limit=100`)
      .then((res) => {
        if (!this.rootStore.uiStateStore.fetchingLinkedSkus) return;
        this.setPrintProducts(res);
      })
      .catch(() => {
        // caca
      });
  }

  async loadProductTypes() {
    this.rootStore.uiStateStore.setFetchingProductTypes(true);
    get(`${PRODUCT_TYPE_URL}`)
      .then((res) => {
        console.log(res);
        this.setTypeList(res);
        this.rootStore.uiStateStore.setFetchingProductTypes(false);
      })
      .catch(() => {
        this.rootStore.uiStateStore.setFetchingProductTypes(false);
      });
  }

  async getProductType(type) {
    this.resetProducts();
    this.rootStore.uiStateStore.setFetchingCurrentProduct(true);
    try {
      const res = await get(`${PRODUCT_TYPE_URL}${type}`);
      console.log(res);
      this.currentProduct.fromObject(res);
      await this.rootStore.metadataStore.getProductDetails(this.currentProduct);
      await this.rootStore.metadataStore.getProductAddons(this.currentProduct);
      await this.getOtherProducts();
      this.productSnapshot.fromObject(this.currentProduct);
      this.rootStore.uiStateStore.setFetchingCurrentProduct(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingCurrentProduct(false);
      this.rootStore.routeStore.redirect(`/product-types`);
    }
  }

  setTypeList(res) {
    this.typeList = res;
  }

  /**
   * DO NOT USE IN COMPONENT CODE. Action to set the properties of the store based on the API's response.
   * @param {} res
   */
  setProps(res) {
    this.products = res.products;
    this.totalPages = res.totalPages;
    this.totalProducts = res.totalProducts;
    this.perPage = res.perPage;
    this.rootStore.uiStateStore.setFetchingProductContent(false);
  }

  async setLinkedSkus(products) {
    this.linkedSkus = products
      .filter((p, idx) => {
        return p.type === "Sina";
      })
      .map((p, idx) => {
        return { text: p.name, value: p.sku };
      });
  }

  async setPrintProducts(res) {
    this.printProducts = [
      { text: "None", value: "", placeholder: true },
      ...(res.products
        .map((p, idx) => { return { text: `(${p.type === "Sina" ? "Supplier" : "Repackaged"}) ${p.name}`, value: p.id } })
      )
    ];
  }

  /**
   * Set the current page of products to fetch.
   * @param {number} page
   */
  setCurrentPage(page) {
    this.currentPage = page;
  }

  resetProducts() {
    this.currentProduct = new Product();
    this.productSnapshot = new Product();
  }

  updateSnapshot() {
    this.productSnapshot.fromObject(this.currentProduct);
  }

  currentToSnapshot() {
    this.currentProduct = new Product(this.productSnapshot);
  }

  async getProduct(productName) {
    this.rootStore.uiStateStore.setFetchingCurrentProduct(true);
    this.resetProducts();
    try {
      const res = await get(`${PRODUCT_BASE_URL}${encodeURIComponent(productName)}`);
      console.log(`PRODUCT RES`, res);
      this.currentProduct.fromObject(res);
      await this.rootStore.metadataStore.getProductDetails(this.currentProduct);
      await this.rootStore.metadataStore.getProductAddons(this.currentProduct);
      this.productSnapshot.fromObject(this.currentProduct);
      console.log(`PRODUCT STATE`, this.currentProduct);
      this.rootStore.uiStateStore.setFetchingCurrentProduct(false);
    } catch (err) {
      this.rootStore.uiStateStore.setFetchingCurrentProduct(false);
      this.rootStore.routeStore.redirect(PRODUCT_BASE_URL);
      // this.rootStore.notificationStore.createNotification(
      //   "ERROR",
      //   `Could not find product ${productName}`,
      //   "error"
      // );
    }
  }

  async getOtherProducts() {
    try {
      const res = await Promise.all(
        this.currentProduct.otherProducts.map(async (id) => {
          const response = await get(`${PRODUCT_BASE_URL}?search[_id]=${id}`);
          console.log(`Got Other Product`, response);
          return response.products[0];
        })
      );
      runInAction(() => {
        this.currentProduct.otherProducts = res;
        this.productSnapshot.otherProducts = res;
        console.log(`Other Products`, this.currentProduct.otherProducts);
      });
    } catch (err) { }
  }

  async getProductLinks(name, store) {
    this.currentLinks = { editUrl: "", permalink: "" };
    this.rootStore.uiStateStore.setFetchingProductLinks(true);
    get(`${PRODUCT_BASE_URL}${name}/links/${store}`)
      .then((res) => {
        if (!this.rootStore.uiStateStore.fetchingProductLinks) return;
        if (store !== this.rootStore.shopStore.currentStore) return;
        this.setProductLinks(res);
      })
      .catch(() => {
        this.rootStore.uiStateStore.setFetchingProductLinks(false);
      });
  }

  resetLinks() {
    this.currentLinks = { editUrl: "", permalink: "" };
  }

  setProductLinks(res) {
    this.currentLinks = res;
    this.rootStore.uiStateStore.setFetchingProductLinks(false);
  }

  //FIXME: Kinda jank
  async getServicePros() {
    const name = this.productSnapshot.name;
    this.currentProduct.setServicePros([]);
    this.productSnapshot.setServicePros([]);
    this.rootStore.uiStateStore.setFetchingProductPros(true);
    get(`${PRODUCT_BASE_URL}${this.productSnapshot._id}/pros`)
      .then((res) => {
        console.log(res);
        this.rootStore.uiStateStore.setFetchingProductPros(false);
        if (this.productSnapshot.name === name) {
          this.currentProduct.setServicePros(res);
          this.productSnapshot.setServicePros(res);
        }
      })
      .catch(() => {
        //
        this.rootStore.uiStateStore.setFetchingProductPros(false);
      });
  }

  async updateProduct() {
    await this.rootStore.metadataStore.updateAllDetails(this.currentProduct);
    console.log(`UPDATING WITH: `, this.currentProduct.removeBadProps());
    let error = "";
    try {
      const res = await put(
        `${PRODUCT_BASE_URL}${encodeURIComponent(this.productSnapshot._id)}`,
        this.currentProduct.removeBadProps()
      );
      // TODO: This is kludge, instead, do a full update of currentProduct from the response
      this.currentProduct.seoSlug = res.seoSlug; 
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Updated product ${titleCase(this.currentProduct.name)}`,
        "success"
      );
      this.updateSnapshot();
      this.currentProduct.saveCounter++;
    } catch (err) {
      error = err;
    }
    return error;
  }

  async pushProduct() {
    let error = "";
    try {
      await put(
        `${PRODUCT_BASE_URL}${encodeURIComponent(this.productSnapshot._id)}/push`,
        {}
      );
      this.rootStore.notificationStore.createNotification(
        "(DEBUG) No errors",
        "Came back without any errors",
      )
    } catch (err) {
      error = err;
    }
    return error;
  }

  async updateProductType() {
    // Update details with put metadata store first
    await this.rootStore.metadataStore.updateAllDetails(this.currentProduct);
    // this.currentProduct.setAllDetails();
    const {
      _id,
      name,
      sku,
      linkedSku,
      linkedProduct,
      fullCloserLooks,
      fullDetails,
      fullFaq,
      fullReviews,
      markup,
      images,
      price,
      products,
      edits,
      saveCounter,
      variationCount,
      updatedAt,
      sinaIncomingIds,
      seoDescription,
      seoTitle,
      seoSlug,
      servicePros,
      status,
      currentAttrName,
      optionsHideable,
      newAttribute,
      bio,
      ...obj
    } = this.currentProduct;
    let error = "";
    console.log(`UPDATING ${obj.type}`, obj);
    try {
      await put(`${PRODUCT_TYPE_URL}${encodeURIComponent(this.productSnapshot.type)}`, obj);
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Updated type ${titleCase(this.currentProduct.type)}`,
        "success"
      );
      this.updateSnapshot();
    } catch (err) {
      error = err;
    }
    return error;
  }

  async deleteProduct() {
    deleteAt(`${PRODUCT_BASE_URL}${encodeURIComponent(this.productSnapshot._id)}`)
      .then(() => {
        this.rootStore.notificationStore.createNotification(
          "SUCCESS",
          `Product ${this.currentProduct.name} has been deleted`,
          "success"
        );
        // this.loadProducts(this.productType);
      })
      .catch(() => {
        // this.rootStore.notificationStore.createNotification(
        //   "ERROR",
        //   `Could not update product ${this.currentProduct.name}`,
        //   "error"
        // );
      });
  }

  async createProduct(obj) {
    obj.sku = createSkuFromName(obj.name);
    // console.log(obj);
    let error = "";
    try {
      error = await post(`${PRODUCT_BASE_URL}`, obj);
      this.rootStore.notificationStore.createNotification(
        "SUCCESS",
        `Product ${obj.name} has been created!`,
        "success"
      );
      this.loadProducts(this.productType);
    } catch (err) {
      error = err;
    }

    return error;
  }

  async getProductById(id) {
    const res = await get(`${PRODUCT_BASE_URL}/${id}`);
    return res;
  }
}
