import React from "react";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { TempAvatar } from "../../assets/images";
import { useStores } from "../../store";
import { titleCase } from "../../utils/string.utils";
import { observer } from "mobx-react-lite";

const Header = () => {
  const { accountStore, routeStore } = useStores();
  return (
    <div className="dashboard">
      <div className="dashboard-banner">
        <div className="dashboard-banner-header">
          <Breadcrumbs />
          <div className="header-content">
            <div
              className="banner-avatar-wrapper w-inline-block in-text-link"
              style={{ textDecoration: "none" }}
              onClick={() => routeStore.redirect(`/account`)}
            >
              <img src={accountStore.user.avatarUrl} loading="lazy" alt="" className="banner-avatar-image" />
              <div>
                <h5 className="banner-avatar-name">{titleCase(accountStore.user.name)}</h5>
                <div className="banner-avatar-caption">{titleCase(accountStore.user.type)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
