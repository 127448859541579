import { makeAutoObservable } from "mobx";

export class TimelineItem {
  comment = "";
  when = "";
  who = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }
}
