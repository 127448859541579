import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useStores } from "../../../store";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import TabbedContent from "../../../components/tabbed-content/TabbedContent";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import Information from "./Information";
import Webpage from "./Webpage";
import Products from "./Products";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import Button from "../../../components/button/Button";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import Addons from "./Addons";
import Attributes from "./Attributes";
import SelectField from "../../../components/select-field/SelectField";
import { createSkuFromName, sentenceCase } from "../../../utils/string.utils";
import ServicePros from "./ServicePros";
import StoreSelector from "../../../components/store-selector/StoreSelector";
import useTitle from "../../../hooks/useTitle";

const SHOW_PRODUCTS = ["Package", "PackageSet"];
const SHOW_SERVICE_PRO = ["AddonService", "BookingService", "DesignService"];
const RESTRICTED_TABS = [5, 6];

const ProductDetails = () => {
  const { name } = useParams();
  const [searchParams] = useSearchParams();
  const isDuplicate = useMemo(() => searchParams?.get("duplicate"), [searchParams]);
  const { productStore, uiStateStore, routeStore, notificationStore, shopStore, accountStore, metadataStore } =
    useStores();
  // const [tab, setTab] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // FIXME: useless field on all detail pages
  const [duplicating, setDuplicating] = useState(false);

  const loadingTitle = "Loading Product...";
  const loadingSubTitle = "Give us a second...";

  // Unsaved changes Hook
  const [Prompt, Banner, setDirty, setClean] = useUnsavedChanges(
    () => {
      productStore.currentToSnapshot();
      setClean();
    },
    async () => {
      if (isDuplicate) {
        const { siblingProduct, _id, servicePros, ...obj } = productStore.currentProduct;
        console.log(obj);
        notificationStore.createNotification(
          `Creating...`,
          `Product ${productStore.currentProduct.name} is being created...`,
          "info"
        );
        const error = await productStore.createProduct(obj);

        if (!error) {
          setIsSubmitted(true);
          productStore.resetProducts();
          setClean();
          routeStore.redirect(`/products`);
        }
      } else {
        notificationStore.createNotification(
          `Updating...`,
          `Update for ${productStore.currentProduct.name} pending (this may take up to 5 minutes)...`,
          "info"
        );
        const err = await productStore.updateProduct();

        if (!err) {
          setIsSubmitted(true);
          if (
            shopStore.currentStore !== "0" &&
            (!productStore.currentLinks.permalink || !productStore.currentLinks.editUrl)
          )
            productStore.getProductLinks(productStore.currentProduct._id, shopStore.currentStore);
          productStore.currentProduct.resetEdits();
          setClean();
        }
      }
    }
  );

  useEffect(() => {
    if (name !== productStore.currentProduct.name) {
      if (productStore.currentProduct.name) uiStateStore.setProductDetailTab(1);
      productStore.getProduct(name);
    }
    // metadataStore.loadDetails();
  }, [name]);

  useEffect(() => {
    if (
      !uiStateStore.fetchingCurrentProduct &&
      !SHOW_PRODUCTS.find((e) => e === productStore.currentProduct.type) &&
      !SHOW_SERVICE_PRO.find((e) => e === productStore.currentProduct.type) &&
      RESTRICTED_TABS.find((e) => e === uiStateStore.productDetailTab)
    )
      uiStateStore.setProductDetailTab(1);
  }, [productStore.currentProduct.type, uiStateStore.fetchingCurrentProduct]);

  useEffect(() => {
    if (productStore.currentProduct.edits > 0) {
      setDirty();
    }

    if (isSubmitted) {
      setClean();
      setIsSubmitted(false);
    }
  }, [productStore.currentProduct.edits]);

  const storeButtons = useMemo(() => {
    const btnArray = [];
    if (isDuplicate) return [];
    const duplicateBtn = {
      onClick: () => {
        if (uiStateStore.hasDirtyData) setDuplicating(true);
        else {
          // localStorage.setItem(`duplicateProduct`, JSON.stringify(productStore.currentProduct));
          routeStore.newTab(
            `${encodeURIComponent(productStore.currentProduct._id)}?duplicate=true`
          );
        }
      },
      text: "Duplicate",
    };

    const viewLiveBtn = {
      onClick: () => routeStore.newTab(productStore.currentLinks.permalink),
      text: "View Live",
      disabled: uiStateStore.fetchingProductLinks || !productStore.currentLinks.permalink,
      tooltip: !(uiStateStore.fetchingProductLinks || !productStore.currentLinks.permalink)
        ? ""
        : "Select a Store to View Live",
    };

    const wooBtn = {
      onClick: () => routeStore.newTab(productStore.currentLinks.editUrl),
      text: "View in Woo",
      disabled: uiStateStore.fetchingProductLinks || !productStore.currentLinks.editUrl,
      tooltip: !(uiStateStore.fetchingProductLinks || !productStore.currentLinks.editUrl)
        ? ""
        : "Select a Store to View in Woo",
    };

    const forceSaveBtn = {
      onClick: async () => {
        notificationStore.createNotification(
          `(DEBUG) Pushing product...`,
          `Pushing ${productStore.currentProduct.name} to all stores...`,
          "info"
        );

        await productStore.pushProduct();
      },
      text: "Force Push",
      disabled: accountStore.user.type !== "Developer" && !accountStore.user.type.endsWith("Administrator"),
      tooltip: "Push the product state as-is and force a store push"
    }

    if (!(productStore.currentProduct.type === "Sina")) btnArray.push(duplicateBtn);
    btnArray.push(viewLiveBtn);
    btnArray.push(wooBtn);
    btnArray.push(forceSaveBtn);

    return btnArray;
  }, [
    routeStore,
    productStore.currentProduct.name,
    productStore.currentProduct.type,
    uiStateStore.hasDirtyData,
    isDuplicate,
    productStore.currentLinks,
    uiStateStore.fetchingProductLinks,
  ]);

  // const allStoreButtons = useMemo(
  //   () => storeButtons.filter((s) => s.text === "Duplicate"),
  //   [storeButtons]
  // );

  useEffect(() => {
    if (!isDuplicate && productStore.currentProduct.name) {
      if (shopStore.currentStore !== "0")
        productStore.getProductLinks(productStore.currentProduct._id, shopStore.currentStore);
      else productStore.resetLinks();
    }
  }, [shopStore.currentStore, productStore.currentProduct.name, isDuplicate]);

  useEffect(() => {
    if (isDuplicate && !uiStateStore.fetchingCurrentProduct)
      productStore.currentProduct.setName(`Duplicate of ${productStore.currentProduct.name}`);
  }, [productStore.currentProduct, isDuplicate, uiStateStore.fetchingCurrentProduct]);

  useTitle(productStore.currentProduct?.name);

  return !uiStateStore.fetchingCurrentProduct ? (
    <>
      {Prompt}
      {Banner}
      {/* Confirm Delete Modal */}
      <ConfirmModal
        open={duplicating}
        title="Duplicate Product"
        message={`Open in a new tab and Duplicate without saving current changes?`}
        cancelText={"Cancel"}
        confirmText="Confirm"
        cancelAction={() => setDuplicating(false)}
        confirmAction={() => {
          routeStore.newTab(`products/${productStore.currentProduct._id}?duplicate=true`);
        }}
      />
      <ConfirmModal
        open={isDeleting}
        title="Delete Product"
        message={`Delete ${productStore.currentProduct.name}? This action cannot be undone.`}
        cancelText={"Cancel"}
        confirmText="Delete"
        cancelAction={() => setIsDeleting(false)}
        confirmAction={() => {
          productStore.deleteProduct();
          setIsDeleting(false);
          setClean();
          routeStore.redirect(`/products`);
        }}
      />
      <div className="dashboard-container-2">
        <div className="horizontal-wrapper header">
          <PageBackButton redirectTo={`/products`} backText={"Back"} />
          <StoreSelector />
        </div>
        <DetailHeader
          title={productStore.currentProduct.name}
          subtitle={`SKU: ${!isDuplicate
            ? productStore.currentProduct.sku
            : createSkuFromName(productStore.currentProduct.name)
            }`}
          tags={[
            {
              component: (
                <StatusText
                  color={determineColor(productStore.currentProduct.status)}
                  text={productStore.currentProduct.status}
                  classes="status-2 header"
                />
              ),
            },
            { text: sentenceCase(productStore.currentProduct.type) },
          ]}
          // buttons={shopStore.currentStore === "0" ? allStoreButtons : storeButtons}
          buttons={storeButtons}
        />
        {/* TODO: Use store instead of prop drilling */}
        <TabbedContent
          setTabIndex={(index) => uiStateStore.setProductDetailTab(index)}
          currentTab={uiStateStore.productDetailTab}
        >
          <Information
            label="Information"
            product={productStore.currentProduct}
            isDuplicate={isDuplicate}
          />
          <Webpage label="Webpage" product={productStore.currentProduct} />
          <Attributes
            label="Attributes"
            product={productStore.currentProduct}
            isDuplicate={isDuplicate}
          />
          <Addons label="Addons" product={productStore.currentProduct} />
          {SHOW_PRODUCTS.find((e) => e === productStore.currentProduct.type) && (
            <Products
              label={productStore.currentProduct.type === "PackageSet" ? "Packages" : "Products"}
              product={productStore.currentProduct}
            />
          )}
          {SHOW_SERVICE_PRO.find((e) => e === productStore.currentProduct.type) && (
            <ServicePros label="Service Pro" product={productStore.currentProduct} />
          )}
        </TabbedContent>
        {!isDuplicate && uiStateStore.productDetailTab === 1 && (
          <Button text={`Delete Product`} action={() => setIsDeleting(true)} />
        )}
      </div>
    </>
  ) : (
    <div className="dashboard-container-2">
      <DetailHeader title={loadingTitle} subtitle={loadingSubTitle} />
    </div>
  );
};

export default observer(ProductDetails);
