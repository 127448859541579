import React from "react";
import "./styles.css";

const ReplaceCell = ({ action, text = "Replace" }) => {
  return (
    <div className="div-block-60 replace-cell-wrapper">
      <div className="replace-cell w-button" onClick={() => action()}>
        {text}
      </div>
    </div>
  );
};

export default ReplaceCell;
