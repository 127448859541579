import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../store";
import { ORDER_SEARCH_FIELDS } from "../../utils/select.utils";
import SearchTableDropdown from "../table/SearchTableDropdown";

const OrderTableDropdown = () => {
  const { uiStateStore } = useStores();
  return (
    <SearchTableDropdown
      searchFields={ORDER_SEARCH_FIELDS}
      filterBy={uiStateStore.orderTableFilterBy}
      setFilterBy={(val) => uiStateStore.setOrderTableFilterBy(val)}
    />
  );
};

export default observer(OrderTableDropdown);
