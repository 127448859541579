import { makeAutoObservable } from "mobx";

export class ProductDetail {
  description = "";
  type = "";
  title = "";
  title2 = "";
  picture = "";
  editable = true;
  fresh = false;
  _id = "";

  constructor(obj) {
    makeAutoObservable(this);
    this.fromObject(obj);
  }

  fromObject(obj) {
    Object.assign(this, obj);
  }

  setDescription(desc) {
    this.description = desc;
  }

  setTitle(title) {
    this.title = title;
  }

  setTitle2(title2) {
    this.title2 = title2;
  }

  setPicture(url) {
    this.picture = url;
  }
}
