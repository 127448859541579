export const attributeTableColumns = [
  {
    Header: "ID",
    accessor: "id", // accessor is the "key" in the data
    Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
