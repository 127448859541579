import React, { useState } from "react";
import CheckboxField from "../checkbox-field/CheckboxField";
import StoreVisibility from "../store-visibility/StoreVisibility";
import Table from "../table/Table";
import ReplaceCell from "../table/ReplaceCell";
import DeleteCell from "../table/DeleteCell";
import FileUpload from "../file-upload-field/FileUpload";
import { getLastItemInUrl, isImage, NO_RESULTS_TEXT, NO_RESULTS_TEXT_INHERITS } from "../../utils/string.utils";
import { observer } from "mobx-react-lite";
import { LOAD_MORE_INCREMENT, LOAD_MORE_TABLE_RESULTS } from "../../utils/number.utils";
import { MediaNotFound } from "../../assets/images";
import { useStores } from "../../store";

const AddonMediaSetTable = ({ addon }) => {
  const { shopStore } = useStores();
  const [results, setResults] = useState(LOAD_MORE_TABLE_RESULTS);
  const [replace, setReplace] = useState(false);
  const [replaceUrl, setReplaceUrl] = useState("");
  const [add, setAdd] = useState(false);
  const filterFn = c => c.store === shopStore.currentStore;

  const mediaSetTableColumns = [
    {
      Header: "",
      accessor: "drag",
      disableSortBy: true,
    },
    {
      Header: "Media",
      accessor: (data) => {
        data = data.url;
        const image = isImage(data);
        return (
          <div className="div-block-57">
            {image && (
              <img
                loading="lazy"
                src={data}
                alt=""
                className="image-18"
                onError={(e) => (e.currentTarget.src = MediaNotFound)}
              />
            )}
            {!image && (
              <video
                src={data}
                className="image-18"
                controls
                onError={(e) => (e.currentTarget.poster = MediaNotFound)}
              >
                <h5 className="red small-text">Could not Load Media</h5>
              </video>
            )}
          </div>
        );
      },
      disableSortBy: true,
    },
    {
      Header: "Name",
      // TODO: Change this once image format is reshaped
      accessor: (data) => data.name ?? getLastItemInUrl(data.url),
    },
    // {
    //   Header: "Media Visibility",
    //   accessor: "visibility",
    //   Cell: (e) => (
    //     <div style={{ display: "inline-block" }}>
    //       <CheckboxField name="media-enabled" label={"Enabled"} labelOff={"Disabled"} />
    //     </div>
    //   ),
    // },
    {
      Header: "",
      accessor: "replace",
      disableSortBy: true,
      Cell: (e) => (
        <ReplaceCell
          action={() => {
            setReplace(true);
            setReplaceUrl(e.row.original);
          }}
        />
      ),
    },
    {
      Header: "",
      accessor: "delete",
      disableSortBy: true,
      Cell: (e) => {
        return <DeleteCell action={() => addon.removeImage(e.row.original)} />;
      },
    },
  ];

  return (
    <>
      <FileUpload
        name={"replace"}
        clicked={replace}
        resetClicked={() => setReplace(false)}
        handleUpload={(e) => addon.replaceImage(replaceUrl, e)}
      />
      <FileUpload
        name={"add"}
        clicked={add}
        resetClicked={() => setAdd(false)}
        handleUpload={(url) => addon.addImage(url)}
        multiple
      />
      <Table
        tableName={`Media Set $`}
        loadMore={() => setResults(results + LOAD_MORE_INCREMENT)}
        hasSearch={false}
        COLUMNS={mediaSetTableColumns}
        //includeComponent={<StoreVisibility stores={[]} updateStores={(bool, store) => null} />} // TODO: Update Visibility
        rowResults={results}
        DATA={addon.images.filter(filterFn)}
        noResultsText={shopStore.currentStore === "0" ? NO_RESULTS_TEXT : NO_RESULTS_TEXT_INHERITS}
        draggable
        setData={(d) => null}
        buttons={[{ text: "Add Media", onClick: () => setAdd(true) }]}
        noBottom={results >= addon.images.filter(filterFn).length}
      />
    </>
  );
};

export default observer(AddonMediaSetTable);
