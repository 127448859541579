import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Table from "../table/Table";
import { productTableColumns } from "../product-table/productTableColumns";
import { useStores } from "../../store";

const AddServiceProPopup = ({
  show,
  title = "Add Service Pros",
  setShow,
  search,
  rowClick,
  hasCheckbox = false,
  buttons = [],
}) => {
  const { serviceProStore, uiStateStore } = useStores();
  const [page, setPage] = useState(1);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "ID",
      accessor: "partner_id",
    },
  ];

  function nextPage(pageNumber) {
    setPage(pageNumber);
  }

  useEffect(() => {
    serviceProStore.loadPros();
  }, []);

  if (!show) return null;

  return (
    <div className="modal-wrapper">
      {/* FIXME: Will overflow on page without some pagination I think */}
      <Table
        tableName={title}
        hasSearch
        COLUMNS={columns}
        rowResults={10}
        DATA={serviceProStore.servicePros}
        // pages={1}
        loading={uiStateStore.fetchingProductContent}
        onlyArrows
        rowClick={rowClick ? (cells) => rowClick(cells) : null}
        currentPage={page}
        nextPage={nextPage}
        buttons={[
          {
            text: "Cancel",
            onClick: () => setShow(false),
            style: {
              borderColor: "grey",
              backgroundColor: "transparent",
              color: "#000",
              borderWidth: "1px",
              borderStyle: "solid",
            },
          },
          ...buttons,
        ]}
        buttonsBottom
        hasCheckboxColumn={hasCheckbox}
      />
    </div>
  );
};

export default observer(AddServiceProPopup);
