import React, { useCallback, useEffect, useState } from "react";
import SelectField from "../select-field/SelectField";
import { useStores } from "../../store";
import { PRODUCT_TYPES, PRODUCT_STATUSES, CUSTOMER_TYPES } from "../../utils/select.utils";
import { observer } from "mobx-react-lite";
import { SHOP_DETAILS } from "../../utils/shops.utils";

const CreateClient = ({ client, show, setShow }) => {
  const { clientStore, notificationStore, routeStore } = useStores();

  const [email, setEmail] = useState("");
  const [store, setStore] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [creating, setCreating] = useState(false);

  // useEffect(() => {
  //   if (client) {
  //     setEmail(client ? client.email : "");
  //     setStore(client ? client.store : "");
  //     setFirstName(client ? client.firstName : "");
  //     setLastName(client ? client.store : "");
  //   }
  // }, [show]);

  const handleDiscard = useCallback(() => {
    setEmail("");
    setStore("");
    setFirstName("");
    setLastName("");
    setShow(false);
  }, []);

  const handleCreate = useCallback(async () => {
    if (email && store) {
      setCreating(true);
      notificationStore.createNotification(
        `Creating...`,
        `Client is being registered...`,
        "info"
      );
      const result = await clientStore.createClient({
        email,
        store,
        firstName: firstName ?? "Unknown",
        lastName: lastName ?? "Customer",
      });
      setCreating(false);
      setShow(false);
      if (result._id) routeStore.redirect(`/customers/${result._id}`);
      // handleDiscard();
    }
  }, [email, store, firstName, lastName]);

  if (creating) {
    return (
      <div className="modal-wrapper">
        <div className="lds-hourglass"></div>
      </div>
    );
  }
  if (!show) return null;

  return (
    <div className="modal-wrapper">
      <div className="custom-modal">
        <div className="modal-title">
          <h1 className="title">Add Client</h1>
        </div>
        <div className="form-block">
          <div className="field-item">
            <label htmlFor="client-email">Client Email</label>
            <input
              type="text"
              className="field-input"
              name="client-email"
              placeholder="Enter client email..."
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!email && <h5 className="red small-text">Please enter this client's email!</h5>}
          </div>
          <div className="field-item">
            { /*TODO: Fetch live stores*/ }
            <SelectField
              name={"store"}
              label="Account Store"
              options={SHOP_DETAILS.filter(s => s.placeholder !== true).map(s => {
                return {
                  text: s.name,
                  value: s.id
                }
              })}
              selectClasses="field-input"
              setField={(e) => setStore(e)}
              value={store}
            />
            {!store && <h5 className="red small-text">Please select a store for this client's account!</h5>}
          </div>
          <h5 className="small-test">
            The following fields are optional, and are overriden when the customer makes an order.
          </h5>
          <div className="field-item">
            <label htmlFor="client-first-name">Client First Name</label>
            <input
              type="text"
              className="field-input"
              name="client-first-name"
              placeholder="Enter client first name..."
              defaultValue={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="field-item">
            <label htmlFor="client-last-name">Client Last Name</label>
            <input
              type="text"
              className="field-input"
              name="client-last-name"
              placeholder="Enter client last name..."
              defaultValue={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="button-wrapper padding">
            <div className="form-button w-button button-2" onClick={() => handleDiscard()}>
              Discard
            </div>
            <div className="form-button w-button button-2" onClick={() => handleCreate()}>
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CreateClient);
