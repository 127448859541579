import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import { useStores } from "../../../store";
import { removeKebab, sentenceCase } from "../../../utils/string.utils";
import TabbedContent from "../../../components/tabbed-content/TabbedContent";
import Items from "./Items";
import Jobs from "./Jobs";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import CustomerInformation from "../../../components/customer-information/CustomerInformation";

const OrderDetails = () => {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const storeId = useMemo(() => searchParams?.get("store"), [searchParams]);
  const { orderStore, shopStore, routeStore, uiStateStore } = useStores();

  useEffect(() => {
    shopStore.loadStores();
  }, []);

  useEffect(() => {
    orderStore.getOrder(storeId, orderId);
  }, [storeId, orderId]);

  useEffect(() => {
    if (orderStore.currentOrder?.client?._id)
      orderStore.loadOrders("", `limit=100&search[client]=${orderStore.currentOrder.client._id}`);
  }, [orderStore.currentOrder?.client]);

  return (
    <>
      <div className="dashboard-container-2">
        <div className="horizontal-wrapper header">
          <PageBackButton redirectTo={`/orders`} backText={"Back"} />
        </div>
        <DetailHeader
          title={`Order ${orderId}`}
          subtitle={format(new Date(orderStore.currentOrder.raw.date_created_gmt ? orderStore.currentOrder.raw.date_created_gmt + "Z" : null), "PPPPp")}
          betweenTitles={
            <div style={{ display: "flex" }}>
              {/* TODO: Customer number of orders */}
              <div className="large-text" style={{ paddingRight: "20px" }}>
                {`${orderStore.currentOrder.raw?.billing?.first_name} ${orderStore.currentOrder.raw?.billing?.last_name}` ||
                  ""}
              </div>
              <div className="large-text">{`${orderStore.totalOrders} order(s)`}</div>
            </div>
          }
          tags={[
            {
              component: (
                <span className="status-2 header product__text">
                  <strong>Store:&nbsp;</strong>
                  {`${shopStore.stores.find((e) => e.id === storeId?.toString())?.name || "N/A"}`}
                </span>
              ),
            },
            {
              component: (
                <StatusText
                  color={determineColor(orderStore.currentOrder.status)}
                  text={`${sentenceCase(removeKebab(orderStore.currentOrder.status))} Order`}
                  classes="status-2 header"
                />
              ),
            },
            {
              component: (
                <StatusText
                  color={determineColor(orderStore.currentOrder.raw.status)}
                  text={`${sentenceCase(removeKebab(orderStore.currentOrder.raw.status))} Payment`}
                  classes="status-2 header"
                />
              ),
            },
          ]}
          // TODO: Button Functions
          buttons={[
            { text: "Refund", onClick: () => null, disabled: true, tooltip: "Not Available" },
            { text: "Cancel Order", onClick: () => null, disabled: true, tooltip: "Not Available" },
            { 
              text: "View in Woo", 
              onClick: () => routeStore.newTab(orderStore.currentOrder.url), 
              disabled: orderStore.currentOrder.url === undefined, 
              tooltip: orderStore.currentOrder.url === undefined ? "Not Available" : ""
            },
          ]}
        />
        <TabbedContent
          currentTab={uiStateStore.orderDetailTab}
          setTabIndex={(t) => uiStateStore.setOrderDetailTab(t)}
        >
          <Items label="Items" order={orderStore.currentOrder} />
          <CustomerInformation label="Customer" info={orderStore.currentOrder} />
          <Jobs label="Jobs" order={orderStore.currentOrder} />
        </TabbedContent>
      </div>
    </>
  );
};

export default observer(OrderDetails);
