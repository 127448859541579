import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

const FilterTable = ({ filter, setFilter, tableName, dropdown }) => {
  const [search, setSearch] = useState(filter || "");

  useEffect(() => {
    const debounce = setTimeout(() => setFilter(search), 300);

    return () => clearTimeout(debounce);
  }, [search, setFilter]);

  return (
    <div className="searchbar_filters align-lright flex__space--betwen">
      <div className="searchbar_filters">
        <div className="searchbar width">
          <div className="customer__search w-form">
            <input
              className="search-input w-input"
              maxLength={256}
              name="query"
              value={search}
              placeholder={`Search ${tableName.toLowerCase()}…`}
              id="search"
              onChange={(e) => setSearch(e.target.value)}
              data-tip={
                dropdown
                  ? "You can search using regex! Characters to escape (e.g. \\?): *+?^${}()|[]"
                  : ""
              }
            />
            {dropdown && <ReactTooltip />}
            {/* <input type="submit" value="Search" className="search-button-3 w-button" /> */}
          </div>
        </div>
        {dropdown && <>{dropdown}</>}
      </div>
    </div>
  );
};

export default FilterTable;
