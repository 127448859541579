import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";

const quoteTableColumns = [
  {
    Header: "ID",
    accessor: "_id", // accessor is the "key" in the data
    Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Store",
    accessor: "store"
  },
  {
    Header: "Created",
    accessor: "createdAt",
    Cell: (e) => (new Date(e.value)).toLocaleString()
  }
];

const tabs = [
  { heading: "All", value: "" },
  { heading: "Incomplete", query: "search[completed]=false" },
  { heading: "Complete", query: "search[completed]=true" },
]

const QuoteTable = () => {
  const { quoteStore, routeStore, uiStateStore } = useStores();
  const rowResults = 25;

  function nextPage(pageNumber) {
    quoteStore.setCurrentPage(pageNumber);
  }

  useEffect(() => {
    const tabQuery = tabs.find((t, idx) => uiStateStore.quoteTableTab - 1 === idx)?.query;
    const query = [
      tabQuery,
      uiStateStore.quoteTableFilterText ? `search[${uiStateStore.quoteTableFilterBy}]=${uiStateStore.quoteTableFilterText}` : undefined,
    ].filter(v => v !== undefined).join("&");
    
    quoteStore.loadQuotes(
      query,
      uiStateStore.quoteTableSort
    );
  }, [
    uiStateStore.quoteTableTab,
    uiStateStore.quoteTableFilterBy,
    uiStateStore.quoteTableFilterText,
    quoteStore.currentPage,
    uiStateStore.quoteTableSort,
  ]);

  return (
    <div className="dashboard-container-2">
      <Table
        tabNames={tabs}
        tableName={"Quotes"}
        COLUMNS={quoteTableColumns}
        DATA={quoteStore.quotes}
        pages={quoteStore.totalPages}
        nextPage={nextPage}
        currentPage={quoteStore.currentPage}
        rowResults={rowResults}
        loading={uiStateStore.fetchingQuoteContent}
        currentTab={uiStateStore.quoteTableTab}
        setTabIndex={(index) => uiStateStore.setQuoteTableTab(index)}
        // createText={"Add New"}
        // createButtonClick={() => routeStore.redirect(`/quotes/${uuidv4()}?new=true`)}
        rowClick={(cells) => routeStore.redirect(`/quotes/${cells[0].value}`)}
        filter={uiStateStore.quoteTableFilterText}
        // setFilter={(text) => uiStateStore.setCollectionTableFilterText(text)}
        sort={uiStateStore.quoteTableSort}
        setSort={(sort) => uiStateStore.setQuoteTableSort(sort)}
      />
    </div>
  );
};

export default observer(QuoteTable);
