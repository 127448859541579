import React from "react";

const BoxedContent = ({ title, children }) => {
  return (
    <div className="border-block border-block--vertical">
      <h2 className="no-space-bottom-2" style={{ marginBottom: "8px" }}>
        {title}
      </h2>
      {React.Children.count(children) > 1 ? (
        children.map((child, index) => {
          return (
            <div key={index} className="div-block-38">
              {child}
            </div>
          );
        })
      ) : (
        <div className="div-block-38">{React.Children.only(children)}</div>
      )}
    </div>
  );
};

export default BoxedContent;
