import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useStores } from "../../../store";
import { observer } from "mobx-react-lite";
import TabbedContent from "../../../components/tabbed-content/TabbedContent";
import AddOnInformation from "./AddOnInformation";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";
import PageBackButton from "../../../components/page-back-button/PageBackButton";
import DetailHeader from "../../../components/detail-header/DetailHeader";
import StatusText, { determineColor } from "../../../components/status-text/StatusText";
import Button from "../../../components/button/Button";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import NotFound from "../../../components/not-found/NotFound";
import StoreSelector from "../../../components/store-selector/StoreSelector";

const AddOnDetails = () => {
  const { addon } = useParams();
  const [searchParams] = useSearchParams();
  const isDuplicate = useMemo(() => searchParams?.get("duplicate"), [searchParams]);
  const { metadataStore, routeStore, uiStateStore, notificationStore } = useStores();
  // const [tab, setTab] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [duplicating, setDuplicating] = useState(false);

  const [Prompt, Banner, setDirty, setClean] = useUnsavedChanges(
    () => {
      metadataStore.currentAddonToSnapshot();
      setClean();
    },
    async () => {
      if (isDuplicate || addon === "new") {
        notificationStore.createNotification(
          `Creating...`,
          `Addon ${metadataStore.currentAddOn.name} is being created...`,
          "info"
        );
        const err = await metadataStore.createAddon();
        console.log(err);
        if (!err) {
          console.log("CLEAN REQUEST");
          metadataStore.currentAddOn.resetEdits();
          setClean();
          metadataStore.resetAddons();
          routeStore.redirect(`/product-add-ons`);
        }
      } else {
        notificationStore.createNotification(
          `Updating...`,
          `Update for ${metadataStore.currentAddOn.name} pending...`,
          "info"
        );
        const err = await metadataStore.updateAddon();
        if (!err) {
          metadataStore.currentAddOn.resetEdits();
          setIsSubmitted(true);

          setClean();
        }
      }
    }
  );

  useEffect(() => {
    if (addon !== "new") metadataStore.getAddOn(addon);
    else {
      metadataStore.resetAddons();
    }
  }, [addon]);

  useEffect(() => {
    if (isDuplicate && !uiStateStore.fetchingCurrentAddon)
      metadataStore.currentAddOn.setName(`Duplicate of ${metadataStore.currentAddOn.name}`);
  }, [metadataStore.currentAddOn, isDuplicate, uiStateStore.fetchingCurrentAddon]);

  useEffect(() => {
    if (metadataStore.currentAddOn.edits > 0) {
      setDirty();
    }

    if (isSubmitted) {
      setClean();
      setIsSubmitted(false);
    }
  }, [metadataStore.currentAddOn.edits]);

  const storeButtons = useMemo(() => {
    const btnArray = [];
    const duplicateBtn = {
      onClick: () => {
        if (uiStateStore.hasDirtyData) setDuplicating(true);
        else {
          // localStorage.setItem(`duplicateProduct`, JSON.stringify(productStore.currentProduct));
          routeStore.newTab(`${metadataStore.currentAddOn._id}?duplicate=true`);
        }
      },
      text: "Duplicate",
    };

    const viewLiveBtn = {
      onClick: () => null,
      text: "View Live",
      disabled: true,
    };

    if (!isDuplicate && addon !== "new") {
      btnArray.push(duplicateBtn);
      btnArray.push(viewLiveBtn);
    }

    return btnArray;
  }, [routeStore, metadataStore.currentAddOn.name, uiStateStore.hasDirtyData, isDuplicate]);

  return (!uiStateStore.fetchingCurrentAddon && metadataStore.currentAddOn._id) ||
    addon === "new" ||
    isDuplicate ? (
    <div className="dashboard-container-2">
      {Prompt}
      {Banner}
      <ConfirmModal
        open={duplicating}
        title="Duplicate Addon"
        message={`Open in a new tab and Duplicate without saving current changes?`}
        cancelText={"Cancel"}
        confirmText="Confirm"
        cancelAction={() => setDuplicating(false)}
        confirmAction={() => {
          routeStore.newTab(
            `product-add-ons/${encodeURIComponent(metadataStore.currentAddOn.name)}?duplicate=true`
          );
        }}
      />
      <ConfirmModal
        open={isDeleting}
        title="Delete Add-on"
        message={`Delete ${metadataStore.currentAddOn.name}? This action cannot be undone.`}
        cancelText={"Cancel"}
        confirmText="Delete"
        cancelAction={() => setIsDeleting(false)}
        confirmAction={() => {
          metadataStore.deleteAddon();
          setIsDeleting(false);
          setClean();
          routeStore.redirect(`/product-add-ons`);
        }}
      />
      <div className="horizontal-wrapper header">
        <PageBackButton redirectTo={`/product-add-ons`} backText={"Back"} />
        <StoreSelector />
      </div>
      <DetailHeader
        title={metadataStore.currentAddOn.name || "New Add-On"}
        subtitle={`ID# ${metadataStore.currentAddOn._id || "Generated On Save"}`}
        tags={[
          {
            component: (
              <StatusText
                color={determineColor("Active")} // TODO: Proper Status
                text={"Active"}
                classes="status-2 header"
              />
            ),
          },
        ]}
        buttons={storeButtons}
      />
      <TabbedContent
        currentTab={uiStateStore.addonDetailTab}
        setTabIndex={(t) => uiStateStore.setAddonDetailTab(t)}
      >
        <AddOnInformation label="Information" />
        {/* <div label="Service Pros"></div> */}
      </TabbedContent>
      {(addon !== "new" || isDuplicate) && (
        <Button text={`Delete Add-on`} action={() => setIsDeleting(true)} />
      )}
    </div>
  ) : (
    <div className="dashboard-container-2">
      {uiStateStore.fetchingCurrentAddon ? (
        <DetailHeader title={"Loading Addon..."} subtitle={"Give us a second..."} />
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default observer(AddOnDetails);
