import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RealtyShopBlackSolid } from "../../assets/images";
import { useStores } from "../../store";
import { getSidebarData } from "./sidebarData";

import "./styles.css";

const SidebarLabel = ({ item }) => {
  return (
    <>
      <img src={item.icon} loading="lazy" width={24} height={24} alt="" className="nav-icon" />
      <span className={item.textcName}>{item.title}</span>
    </>
  );
};

const SidebarLink = ({ item }) => {
  const { routeStore } = useStores();

  return (
    <NavLink
      onClick={() => (item.onClick ? item.onClick() : null)}
      to={item.path}
      className={({ isActive }) => (isActive ? item.acName : item.cName)}
      // end
    >
      <SidebarLabel item={item} />
    </NavLink>
  );
};

const SubMenu = ({ item }) => {
  const [subNavOpen, setSubNavOpen] = useState(false);

  return (
    <>
      {item.hasDivider && <div className="nav-divider"></div>}
      {!item.subNav && <SidebarLink item={item} />}
      {item.subNav && (
        <div className="dropdown w-dropdown">
          <div
            className={item.cName}
            onClick={() => {
              setSubNavOpen(!subNavOpen);
            }}
          >
            <SidebarLabel item={item} />
            <img
              src={item.dropdownIcon}
              loading="lazy"
              alt=""
              className={!subNavOpen ? "nav-dropdown-icon rotate" : "nav-dropdown-icon rotate down"}
            />
          </div>
          <div className={!subNavOpen ? "ease" : "ease down"}>
            {subNavOpen &&
              item.subNav.map((subItem, index) => {
                return <SidebarLink key={`subNav ${subItem.title} ${index}`} item={subItem} />;
              })}
          </div>
        </div>
      )}
    </>
  );
};

const Sidebar = () => {
  const { routeStore } = useStores();

  return (
    <>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="dashboard-sidebar w-nav"
      >
        <div className="sidebar-content">
          <div className="sidebar-logo-wrapper">
            <NavLink onClick={() => routeStore.redirect("/")} to="/" className="w-nav-brand">
              <img src={RealtyShopBlackSolid} alt="" className="sidebar-logo" />
            </NavLink>
          </div>

          {getSidebarData().map((item, index) => {
            return <SubMenu key={`${item.title} ${index}`} item={item} />;
          })}
        </div>

        {/* Temporary Mobile Bar, not useful for now */}
        {/* <div className="mobile-bar">
          <div className="mobile-bar-menu">
            <a href="/src" className="mobile-bar-link w-inline-block">
              <img src={Home} loading="lazy" alt="" />
            </a>
            <a href="/" className="mobile-bar-link w-inline-block">
              <img src={ClipboardImg} loading="lazy" alt="" />
            </a>
            <a
              data-w-id="da3d71f2-0efd-7bc8-77ff-08fc09cd7904"
              href="/"
              className="mobile-menu-button w-inline-block"
            >
              <div
                data-w-id="da3d71f2-0efd-7bc8-77ff-08fc09cd7905"
                data-animation-type="lottie"
                data-src="documents/lf30_editor_dhxtffnc.json"
                data-loop="0"
                data-direction="1"
                data-autoplay="0"
                data-is-ix2-target="1"
                data-renderer="svg"
                data-default-duration="2"
                data-duration="0"
                data-ix2-initial-state="0"
                className="lottie-menu-icon"
              ></div>
            </a>
            <a href="/" className="mobile-bar-link w-inline-block">
              <img src={User} loading="lazy" alt="" />
            </a>
            <a href="/" className="mobile-bar-link w-inline-block">
              <img src={Sliders} loading="lazy" alt="" />
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Sidebar;
