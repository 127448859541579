import React, { useEffect, useState } from "react";
import BoxedContent from "../../../components/boxed-content/BoxedContent";
import TextBoxField from "../../../components/text-box-field/TextBoxField";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../store";
import StoreVisibility from "../../../components/store-visibility/StoreVisibility";
import Button from "../../../components/button/Button";
import AddonMediaSetTable from "../../../components/cover-media-table/AddonMediaSetTable";
import AddProductPopup from "../../../components/add-product-popup/AddProductPopup";
import {
  addonBioSchema,
  genericNameSchema,
  markupOrPriceSchema,
} from "../../../utils/validation.utils";
import AddonPopup from "../../../components/addon-popup/AddonPopup";

const AddOnInformation = () => {
  const { metadataStore, routeStore } = useStores();
  const [linked, setLinked] = useState(true);
  const [showProducts, setShowProducts] = useState(false);
  const [showAddons, setShowAddons] = useState(false);

  useEffect(() => {
    setLinked(!!metadataStore.currentAddOn.link?.sku);
  }, [metadataStore.currentAddOn]);

  return (
    <>
      <AddProductPopup
        show={showProducts}
        title="Select Linked Product"
        setShow={(s) => setShowProducts(s)}
        rowClick={(row) => {
          metadataStore.currentAddOn.setLink(row[0].row.original);
          setShowProducts(false);
        }}
        search={"search[type]=^(?!Package|PackageSet).*$&limit=10"}
      />
      <AddonPopup
        show={showAddons}
        title={`Add Addons`}
        rowClick={(row) => {
          metadataStore.currentAddOn.setProgressTo(row[0].row.original);
          setShowAddons(false);
        }}
        // search={`?search[_id]=^(?!${metadataStore.currentAddOn._id}).*$&limit=10`}
        filter={(s) => s._id !== metadataStore.currentAddOn._id}
        setShow={(s) => setShowAddons(s)}
      />
      <BoxedContent title={"Internal Notes"}>
        <TextBoxField
          field={metadataStore.currentAddOn.notes}
          setField={(e) => metadataStore.currentAddOn.setNotes(e)}
          placeholder={"Enter add-on internal notes..."}
        />
      </BoxedContent>

      <BoxedContent title={"Information"}>
        { /* TODO: Implement */ }
        {/* <StoreVisibility
          stores={metadataStore.currentAddOn.stores}
          updateStores={(bool, store) =>
            metadataStore.currentAddOn.updateStoreVisibility(bool, store)
          }
        /> */}
        <TextBoxField
          title={"Add-on Name"}
          field={metadataStore.currentAddOn.name}
          setField={(n) => metadataStore.currentAddOn.setName(n)}
          placeholder={"Enter add-on name..."}
          validationSchema={genericNameSchema}
        />
        <TextBoxField
          title={"Short Description"}
          field={metadataStore.currentAddOn.bio}
          setField={(b) => metadataStore.currentAddOn.setBio(b)}
          placeholder={"Enter a short description..."}
          validationSchema={addonBioSchema}
        />
      </BoxedContent>

      <BoxedContent title="Pricing, Linked Product &amp; Addon">
        {/* <div style={{ padding: "20px 10px 20px 0px" }}>
          <h5 className="heading-62">Enable Linked Product</h5>
          <h5 className="grey-sub-title">
            Tip: Linking a product will give the user the ability to select a product in the add-ons
            section of the site. The linked product options will be displayed automatically on the
            site.
          </h5>
          <CheckboxField
            name="hide-attribute"
            label={"Enabled"}
            labelOff={"Disabled"}
            isChecked={linked}
            setField={(e) => {
              setLinked(e);
              if (!e) metadataStore.currentAddOn.setLink({});
            }}
            useIsChecked
          />
        </div> */}
        {/* <> */}
        {/* {linked && ( */}
        <>
          <h5 className="heading-62">Linked Product</h5>
          <h5 className="grey-sub-title">
            Tip: Linking a product will give the user the ability to select a product in the add-ons
            section of the site. The linked product options will be displayed automatically on the
            site.
          </h5>
          <div>
            {!metadataStore.currentAddOn.link?.name && (
              <h5 className="red">Add-ons must have a linked product!</h5>
            )}
            <em
              className="in-text-link"
              onClick={() =>
                routeStore.newTab(
                  `/products/${encodeURIComponent(metadataStore.currentAddOn.link?._id)}`
                )
              }
            >
              {metadataStore.currentAddOn.link?.name}
            </em>
          </div>
          <Button
            text={metadataStore.currentAddOn.link?.sku ? "Change" : "Select"}
            style={{ marginBottom: "20px", backgroundColor: "#1d94d2" }}
            action={() => setShowProducts(true)}
          />
        </>
        {/* )} */}
        {/* </> */}
        <div className="with-border">
          {/* TODO: Does not send data in payload */}
          <h5 className="heading-62">Progresses To</h5>
          <h5 className="grey-sub-title">Tip: Link an add-on that depends on this add-on here.</h5>
          {metadataStore.currentAddOn.progressTo.name && (
            <em
              className="in-text-link"
              onClick={() =>
                routeStore.newTab(`/product-add-ons/${metadataStore.currentAddOn.progressTo._id}`)
              }
            >
              {metadataStore.currentAddOn.progressTo.name}
            </em>
          )}
          <Button
            text={"Select"}
            style={{ marginBottom: "20px", backgroundColor: "#1d94d2" }}
            action={() => setShowAddons(true)}
          />
          {/* <SelectField
            name="progressTo"
            options={metadataStore.addons
              .filter((addon) => {
                return addon._id !== metadataStore.currentAddOn._id;
              })
              .map((addon) => {
                return { text: addon.name, value: addon._id };
              })}
          /> */}
        </div>
        <div className="with-border">
          <TextBoxField
            title={"Retail Price ($)"}
            field={metadataStore.currentAddOn.price}
            setField={(p) => metadataStore.currentAddOn.setPrice(p)}
            placeholder="Enter retail price..."
            resize="none"
            style={{ width: "200px" }}
            validationSchema={markupOrPriceSchema}
          />
        </div>
      </BoxedContent>

      <AddonMediaSetTable addon={metadataStore.currentAddOn} />
    </>
  );
};

export default observer(AddOnInformation);
