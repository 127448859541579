import React, { useState } from "react";
import JobTable from "../../components/job-table/JobTable";
import ContentModule from "../../components/content-module/ContentModule";
import AlertModule from "../../components/alert-module/AlertModule";
import { DollarSign, DollarSignRed, TagPurple, ThumbsUp } from "../../assets/images";
import { observer } from "mobx-react-lite";
import { useStores } from "../../store";
const Jobs = () => {
  const { jobStore } = useStores();
  // const [show, setShow] = useState(true); // temporary
  return (
    <>
      <div className="dashboard-content-3">
        <div className="dashboard-container">
          <div className="w-layout-grid main-grid">
            {/* <AlertModule
              message={`URGENT: JOB IS LATE (${1})`}
              open={show}
              setOpen={(o) => setShow(o)}
            /> */}
            <div className="w-layout-grid _4-grid">
              <ContentModule image={DollarSign} caption={`Revenue`} value={`N/A`} />
              <ContentModule
                image={DollarSignRed}
                caption={`Next Payout`}
                extraStyles={`bg-primary-orange`}
                value={`N/A`}
              />
              <ContentModule
                image={TagPurple}
                caption={`Jobs`}
                extraStyles={`bg-primary-purple`}
                value={jobStore.totalJobs}
              />
              <ContentModule
                image={ThumbsUp}
                caption={`Rating`}
                extraStyles={`bg-primary-yellow`}
                value={`N/A`}
              />
            </div>
          </div>
        </div>
      </div>
      <JobTable />
    </>
  );
};

export default observer(Jobs);
