import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";

const StoreTable = () => {
  const { shopStore, uiStateStore } = useStores();

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Domain",
      accessor: "domain",
    },
  ];

  useEffect(() => {
    shopStore.loadStores();
  }, []);

  return (
    <div className="dashboard-container-2">
      <Table
        tableName={"Stores"}
        DATA={shopStore.stores}
        COLUMNS={columns}
        rowResults={100}
        loading={uiStateStore.fetchingStoreContent}
        loadMore={() => null}
        noBottom
      />
    </div>
  );
};

export default observer(StoreTable);
