import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../store";
import Table from "../table/Table";

const AnalyticsTable = () => {
  const { shopStore, uiStateStore, routeStore } = useStores();

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Store",
      accessor: "name",
    },
    {
      Header: "",
      accessor: "analytics",
      Cell: (e) => (
        <span className="in-text-link" onClick={() => routeStore.newTab(e.value)}>
          View Analytics
        </span>
      ),
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    shopStore.loadStores();
  }, []);

  return (
    <div className="dashboard-container-2">
      <Table
        tableName={"Analytics"}
        DATA={shopStore.stores}
        COLUMNS={columns}
        rowResults={100}
        loading={uiStateStore.fetchingStoreContent}
        noBottom
      />
    </div>
  );
};

export default observer(AnalyticsTable);
