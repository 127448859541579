import StatusText, { determineColor } from "../status-text/StatusText";

export const productTableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "SKU",
    accessor: "sku", // accessor is the "key" in the data
    // Cell: (e) => <strong>{e.value}</strong>,
  },
  {
    Header: "Type", // placeholder
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (cell) => (
      <StatusText
        color={determineColor(cell.value || "inactive")}
        text={cell.value || "Inactive"}
      />
    ),
  },
];
